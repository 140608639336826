import {Test} from 'lib/test'
import * as React from 'react'
import {CircularProgressBar} from "app/Components/ProgressBar";
import { Generic1FieldForm } from '.';

type PreviewProps = {
    test: Test
}

export class Generic1FieldRawData extends React.Component<PreviewProps> {
    public render = () => <>
        {this.props.test && this.props.test.content ?
            <Generic1FieldForm data={this.props.test.content.data} preview={true} /> : <CircularProgressBar/>
        }
    </>
}