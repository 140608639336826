import {crudReducer, CrudState, initialCrudState} from 'lib/crud'
import {AnyAction} from 'redux'

export const name = 'STUDENT_SUBJECTS'

export type StudentSubjectsState = {
    crud: CrudState,
}

const initialState = {
    crud: initialCrudState,
}

export function studentSubjectsReducer(state: StudentSubjectsState = initialState, action: AnyAction): StudentSubjectsState {
    switch (action.type) {
        default:
            return {
                ...state,
                crud: crudReducer(name, state.crud, action)
            }
    }
}
