import {configuration} from 'lib/client'
import {StudentFileApi} from 'client/apis'

const StudentFileApiClient = (): StudentFileApi => {
    return new StudentFileApi(configuration)
}

export const dao = {
    list: (payload: any) => {
        return StudentFileApiClient().getStudentFileCollection(payload)
    },
    get: (payload: any) => {
        return StudentFileApiClient().getStudentFileItem({id: payload.id})
    },
    create: (payload: any) => {
        return StudentFileApiClient().postStudentFileCollection(payload)
    },
    update: (payload: any) => {
        return StudentFileApiClient().confirmStudentFileItem({id: payload.id})
    },
    delete: (payload: any) => {
        return StudentFileApiClient().deleteStudentFileItem({id: payload.id})
    }
}