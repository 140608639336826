import {CrudAction, getRow, Type} from 'lib/crud'
import {State} from 'lib/store'
import * as React from 'react'
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux'
import {Form} from './Form'
import {CrudEditHeader} from 'app/Components'
import {Group, name} from "lib/groups";

type StateToProps = {
    readonly group: Group
}

type DispatchToProps = {
    readonly handleSubmit: (group: Group) => void,
}

type EditProps = StateToProps & DispatchToProps

class EditComponent extends React.Component<EditProps> {
    public render = () => (<>
        <CrudEditHeader actionName={name}/>
        <Form initialValues={this.props.group} handleSubmit={this.props.handleSubmit}/>
    </>)
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    group: getRow(state => state.groups)(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    handleSubmit: (group: Group) => {
        const groupId = group.id
        delete group.id
        dispatch(CrudAction.execute(name, Type.UPDATE, {
            id: groupId,
            data: group
        }))
    }
})

export const Edit = connect(mapStateToProps, mapDispatchToProps)(EditComponent)