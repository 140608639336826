import {BackButton} from 'app/Layout/Content'
import {CrudAction, RouteType, Type} from 'lib/crud'
import {Dictionary, DictionaryAction, name} from 'lib/dictionaries'
import {NavigationAction} from 'lib/navigation'
import {generateCrudPath, getCrudPathLabels} from 'lib/router'
import * as React from 'react'
import {connect, MapDispatchToProps} from 'react-redux'
import {RouteComponentProps, withRouter} from 'react-router-dom'
import {Form} from './Form'

type DispatchToProps = {
    readonly handleSubmit: (type: string) => (dictionary: Dictionary) => void,
    readonly setType: (type: string) => void,
    readonly setHeader: (actionName: string, type: RouteType) => void,
    readonly setAction: (action: JSX.Element) => void,
}

type Params = {
    type: string
}

type AddProps = DispatchToProps & RouteComponentProps<Params>

class AddComponent extends React.Component<AddProps> {
    public componentDidMount(): void {
        this.props.setHeader(this.props.match.params.type, RouteType.ADD)
        this.props.setAction(<BackButton url={generateCrudPath(this.props.match.params.type, RouteType.LIST)}/>)
        this.props.setType(this.props.match.params.type)
    }

    public render = () => <Form
        initialValues={{id: '', type: this.props.match.params.type, name: '', active: false} as Dictionary}
        handleSubmit={this.props.handleSubmit(this.props.match.params.type)}
    />
}

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    handleSubmit: (type: string) => (dictionary: Dictionary) => {
        dispatch(CrudAction.execute(name, Type.CREATE, {
            type: type,
            data: {
                name: dictionary.name,
                active: dictionary.active
            }
        }))
    },
    setType: (type: string) => {
        dispatch(DictionaryAction.setType(type))
    },
    setHeader: (actionName, type) => {
        dispatch(NavigationAction.setHeader(getCrudPathLabels(actionName, type)))
    },
    setAction: (action: JSX.Element) => {
        dispatch(NavigationAction.setAction(action))
    }
})

export const Add = withRouter(connect(null, mapDispatchToProps)(AddComponent))