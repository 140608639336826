import {GenericReflexSection, GenericReflexTestSection} from 'app/Views/Tests'
import * as React from 'react'

type PreviewProps = {
    data?: any
    preview: boolean
}

export class GenericReflexForm extends React.Component<PreviewProps> {
    public render = () => <>
        {this.props.data ? <GenericReflexSection
            section={this.props.data as GenericReflexTestSection[]}
            preview={this.props.preview}
        /> : ''}
    </>
}