import {
    CrudAction,
    dispatchAfterFailureEpic,
    dispatchAfterFilterEpic,
    dispatchAfterSuccessEpic,
    executeCrudEpic,
    redirectAfterSuccessEpic,
    RouteType,
    Type
} from 'lib/crud'
import {NotificationsAction} from 'lib/notifications'
import {name} from 'lib/groupChildren'
import {generateCrudPath} from 'lib/router'
import {State} from 'lib/store'
import {dao} from 'lib/students'
import {combineEpics} from 'redux-observable'

export const groupChildrenEpics = combineEpics(
    executeCrudEpic(name, Type.LIST)(dao.list),
    executeCrudEpic(name, Type.GET)(dao.get),
    executeCrudEpic(name, Type.UPDATE)(dao.update),

    redirectAfterSuccessEpic(name, Type.UPDATE, (action, state: State) => generateCrudPath(name, RouteType.LIST)),

    dispatchAfterSuccessEpic(name, Type.UPDATE, (action, state: State) => NotificationsAction.show({
        message: 'Dane ucznia zostały zaktualizowane',
        options: {variant: "success"}
    })),

    dispatchAfterFailureEpic(name, Type.UPDATE, (action, state: State) => NotificationsAction.show({
        message: 'Wystąpił błąd podczas aktualizacji ucznia',
        options: {variant: "error"}
    })),

    dispatchAfterFilterEpic(name, (action, state: State) => CrudAction.execute(name, Type.LIST, {
        orderName: state.children.crud.listOptions.sortDirection,
        page: state.children.crud.listOptions.page + 1,
        itemsPerPage: state.children.crud.listOptions.pageSize,
        ...state.children.crud.filters
    }))
)