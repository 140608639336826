import {Box, Grid, Table, TableCell, TableContainer, TableHead, TableRow, Typography} from '@material-ui/core'
import * as React from 'react'

type PreviewProps = {
    data: any
}

export class ClameisrAnalysisData extends React.Component<PreviewProps> {
    public renderSection = (header: string, data: any) => <Box p={4}>
        <Typography variant={'h4'} style={{paddingLeft: '16px', paddingTop: '20px'}}>{header}</Typography>
        <TableContainer>
            <Table className={'gray-striped'}>
                <TableHead>
                    {data.map((element: any, i: number) => <TableRow key={i}>
                        <TableCell>{element[0]}</TableCell>
                        <TableCell align={"right"}>{element[1]}%</TableCell>
                    </TableRow>)}
                </TableHead>
            </Table>
        </TableContainer>
    </Box>

    public render = () => {
        let data: any = {
            'Z': [],
            'N': [],
            'RS': [],
            'A': [],
            'P': [],
            'KM': [],
            'M': [],
            'S': [],
            '1': [],
            '2': [],
            '3': [],
        }

        this.props.data.forEach((section: any) => {
            Object.entries(section[1]).forEach((group: any) => {
                if (['a', 'b', 'c'].some(key => key == group[0])) {
                    Object.entries(group[1]).forEach((element: any) => {
                        const k = element[0] + ''
                        data[k].push([section[1].section, Math.round(element[1] * 100)])
                    })
                }
            })
        })

        return <>
            {this.renderSection('Zaangażowanie (Z)', data.Z)}
            {this.renderSection('Niezależność (N)', data.N)}
            {this.renderSection('Relacje społeczne (RS)', data.RS)}
            {this.renderSection('Adaptacyjny (A)', data.A)}
            {this.renderSection('Poznawczy (P)', data.P)}
            {this.renderSection('Komunikacja (KM)', data.KM)}
            {this.renderSection('Motoryczny (M)', data.M)}
            {this.renderSection('Społeczny (S)', data.S)}
            {this.renderSection('Pozytywne relacje społeczne', data['1'])}
            {this.renderSection('Nabywania i używanie wiedzy i umiejętności', data['2'])}
            {this.renderSection('Podejmowanie działań celem spełnienia potrzeb', data['3'])}
        </>
    }
}