import {QualificationApi} from 'client/apis'
import {configuration} from 'lib/client'

const QualificationApiClient = (): QualificationApi => {
    return new QualificationApi(configuration)
}

export const dao = {
    list: (payload: any) => QualificationApiClient().getQualificationCollection(payload),
    get: (payload: any) => QualificationApiClient().getQualificationItem({id: payload.id}),
    create: (payload: any) => QualificationApiClient().postQualificationCollection({qualificationWrite: payload}),
    update: (payload: any) => QualificationApiClient().putQualificationItem({id: payload.data.id, qualificationWrite: payload.data}),
    delete: (payload: any) => QualificationApiClient().deleteQualificationItem({id: payload.id})
}