import {Test} from 'lib/test'
import * as React from 'react'
import {CircularProgressBar} from "app/Components/ProgressBar";
import {KodwkmzForm} from "app/Views/Tests/KODWKMZ/KodwkmzForm";

type PreviewProps = {
    test: Test
}

export class KodwkmzRawData extends React.Component<PreviewProps> {
    public render = () => <>
        {this.props.test && this.props.test.content ?
            <KodwkmzForm data={this.props.test.content.data} preview={true} /> : <CircularProgressBar/>
        }
    </>
}