import {AnyAction} from 'redux'

export type EnumValue = {id: string, value: string}

export type EnumState = {
    [name: string]: EnumValue[]
}

const initialState = {}

export function enumReducer(state: EnumState = initialState, action: AnyAction): EnumState {
    if (action.type.startsWith('[ENUM] load success')) {
        return {
            ...state,
            [action.payload.name]: action.payload.response.items
        }
    }

    return state
}