import {Box, Table, TableBody, TableCell, TableHead, TableRow, Typography} from '@material-ui/core'
import Highcharts from 'highcharts'
import HighchartsReact from "highcharts-react-official";
import * as React from 'react'
import {GroupPreview} from "../../../Components";
import {Item, transformGroupsComparison, transformSchoolsComparison, transformStudentsComparison} from './transformer'

const getOptions = (categories: any, series: any, type: string = 'column') => {
    return {
        chart: {
            type: type
        },
        title: {
            text: ' '
        },
        xAxis: [{
            categories
        }],
        yAxis: [

            {
                tickInterval: 1,
                gridLineWidth: 1,
                title: {
                    text: ''
                },
                labels: {
                    format: '{value}%'
                },
                opposite: true
            }
        ],
        tooltip: {
            shared: true,
            valueSuffix: '%'
        },
        series
    }
}

const dataLabels = {
    enabled: true,
    rotation: -90,
    color: '#FFFFFF',
    align: 'right',
    format: '{point.y:.0f}%', // one decimal
    y: 10, // 10 pixels down from the top
    style: {
        fontSize: '13px',
        fontFamily: 'Verdana, sans-serif'
    }
}

const marker = {enabled: true, symbol: 'circle'}

export const schoolTable = (schools: any, labels: any, config: string, age: string) => table(transformSchoolsComparison(schools, age), labels)
export const groupTable = (groups: any, labels: any, age: string) => table(transformGroupsComparison(groups, age), labels)

export const studentAndGroupTable = (students: any, groups: any, groupsLabels: any, decimalResult: boolean) => tableForStudentAndGroup(transformStudentsComparison(students), transformGroupsComparison(groups, ''), groupsLabels, decimalResult)

const tableForStudentAndGroup = (items: Item[], groupItems: Item[], labels: any, decimalResult: boolean) => {
    if (items.length == 0) {
        return <Box mt={10} mb={6}><Typography variant={'h5'} align={'center'}>Brak danych spełnających kryteria wyszukiwania</Typography></Box>
    }

    const rows = items[0].data.flatMap(section => section.name)

    return <>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Kategoria</TableCell>
                    {items.map((item, i) => <TableCell key={i} align={'right'}>Dziecko</TableCell>)}
                    {groupItems.map((item, i) => <TableCell key={i} align={'right'}>
                        {getLabel(labels, item.id, item.age)}
                    </TableCell>)}
                </TableRow>
            </TableHead>
            <TableBody className={'gray-striped'}>
                {rows.map((row, i) => {
                    return <TableRow key={i}>
                        <TableCell>{i + 1}. {row}</TableCell>
                        <TableCell key={i} align={'right'}>{result(items[0].data[i].score, decimalResult)}</TableCell>
                        {groupItems.map(item => {
                            return <TableCell key={i} align={'right'}>{result(item.data[i].score, decimalResult)}</TableCell>
                        })}
                    </TableRow>
                })}
            </TableBody>
        </Table>
    </>
}

const table = (items: Item[], labels: any) => {
    if (items.length == 0) {
        return <Box mt={10} mb={6}><Typography variant={'h5'} align={'center'}>Brak danych spełnających kryteria wyszukiwania</Typography></Box>
    }

    const rows = items[0].data.flatMap(section => section.name)

    return <>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Kategoria</TableCell>
                    {items.map((item, i) => <TableCell key={i}
                                                       align={'right'}>{getLabel(labels, item.id, item.age)}</TableCell>)}
                </TableRow>
            </TableHead>
            <TableBody className={'gray-striped'}>
                {rows.map((row, i) => {
                    return <TableRow key={i}>
                        <TableCell>{i + 1}. {row}</TableCell>
                        {items.map((item, j) => <TableCell key={j} align={'right'}>{item.data[i].score}%</TableCell>)}
                    </TableRow>
                })}
            </TableBody>
        </Table>
    </>
}

type Evaluation = {
    testId: string,
    testConfigId: string,
    evaluation: {
        age: string,
        sections: {
            name: string,
            score: number
        }[]
    },
    publishedAt: string
}

export const studentChart = (evaluations: Evaluation[]) => {
    const categories = evaluations.map(evaluation => (new Date(evaluation.publishedAt)).toLocaleDateString())
    const sections = evaluations[0].evaluation.sections.map(section => section.name)

    const data = sections.map((section, i) => {
        return {
            name: section,
            data: evaluations.map(evaluation => evaluation.evaluation.sections[i].score)
        }
    })

    return <HighchartsReact highcharts={Highcharts} options={getOptions(categories, data, 'spline')}/>
}

export const studentTable = (evaluations: Evaluation[], decimalResult: boolean) => {
    const categories = evaluations.map(evaluation => (new Date(evaluation.publishedAt)).toLocaleDateString())

    return <>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Kategoria</TableCell>
                    {categories.map((item, i) => <TableCell key={i} align={'right'}>{item}</TableCell>)}
                </TableRow>
            </TableHead>
            <TableBody className={'gray-striped'}>
                {evaluations[0].evaluation.sections.map((row, i) => {
                    return <TableRow key={i}>
                        <TableCell>{i + 1}. {row.name}</TableCell>
                        {evaluations.map((evaluation, j) => {
                            return <TableCell key={j} align={'right'}>{result(evaluation.evaluation.sections[i].score, decimalResult)}</TableCell>
                        })}
                    </TableRow>
                })}
            </TableBody>
        </Table>
    </>
}

const getLabel = (labels: { value: string, label: string }[], id: string, age: string): string => {
    const found = labels.find(label => label.value === id)
    const ageLabel = age == '' ? '' : ' (wiek: ' + age + ')'

    return found ? found.label + ageLabel : '-'
}

const result = (value: number, decimalResult: boolean): string => {
    return decimalResult ? (value / 100) + '' : value + '%'
}