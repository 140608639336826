import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Card,
    CardContent, Link,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {Filter, FilterType, TestConfigPreview} from 'app/Components'
import {Tabs} from 'app/Views/Students/Tabs/Tabs'
import {CrudAction, getRow, listRows, RouteType, Type} from 'lib/crud'
import {NavigationAction} from 'lib/navigation'
import {generateCrudPath, getCrudPathLabels} from 'lib/router'
import {Source} from 'lib/select'
import {State} from 'lib/store'
import {name as studentsName, Student} from 'lib/students'
import {studentSummaryName, SummaryRow} from 'lib/studentSummary'
import {Test, testName} from 'lib/test'
import * as React from 'react'
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux'
import {withRouter} from 'react-router'
import {NavLink, RouteComponentProps} from 'react-router-dom'

type SummarySectionProps = {
    header: string
    categories: string[]
    summary: [],
    studentId: string
}

export class SummarySection extends React.Component<SummarySectionProps> {
    private getCategory = (name: string): string => {
        switch (name) {
            case 'F':
                return 'Fizyczna (F)';
            case 'FO':
                return 'Fizyczna wynik ogólny (FO)';
            case 'FMD':
                return 'Motoryka duża (FMD)';
            case 'FMM':
                return 'Motoryka mała (FMM)';
            case 'FKP':
                return 'Koordynacja przestrzenna (FKP)';
            case 'FL':
                return 'Lateralizacja (FL)';
            case 'FS':
                return 'Samoobsługa (FS)';
            case 'FZ':
                return 'Zmysły (FZ)';

            case 'ES':
                return 'Emocjonalno-społeczny (ES)';
            case 'ESE':
                return 'Emocje (ESE)';
            case 'ESZ':
                return 'Zaangażowanie (ESZ)';
            case 'ESM':
                return 'Motywacja (ESM)';

            case 'K':
                return 'Komunikacja (K)';
            case 'KC':
                return 'Czynna (KC)';
            case 'KB':
                return 'Bierna (KB)';
            case 'KD':
                return 'Dźwiękonaśladownictwo (KD)';

            case 'P':
                return 'Poznawcze (P)';
            case 'PN':
                return 'Naśladownictwo (PN)';
            case 'PPW':
                return 'Percepcja wzrokowa (PPW)';
            case 'PPS':
                return 'Percepcja słuchowa (PPS)';
            case 'PP':
                return 'Pamięć (PP)';
            case 'PKSR':
                return 'Koordynacja słuchowo-ruchowa (PKSR)';
            case 'PKWR':
                return 'Koordynacja wzrokowo-ruchowa (PKWR)';
            case 'PW':
                return 'Werbalny (PW)*';
            case 'PNW':
                return 'Niewerbalny (PNW)*';

            case 'Z':
                return 'Zaangażowanie (Z)';
            case 'ZO':
                return 'Zaangażowanie ogólne (ZO)';

            case 'ZB':
                return 'Zaburzenia (ZB)';
            case 'ZBS':
                return 'Zaburzenia ze spektrum (ZBS)';

            case 'U':
                return 'Umiejętności szkolne (U)';
            case 'UO':
                return 'Umiejętności szkolne ogólne (UO)';
        }

        return name
    }

    private monthDiff = (dateFrom: Date, dateTo: Date) => {
        return dateTo.getMonth() - dateFrom.getMonth() +
            (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
    }

    public render = () => {
        let isEmptyCategory = true
        const section = <Accordion style={{marginBottom: '10px'}}>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                <Typography variant={'h5'}>{this.getCategory(this.props.header)}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box width={'100%'}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{width: '15%'}}>Kategoria</TableCell>
                                <TableCell style={{width: '15%'}}>Test</TableCell>
                                <TableCell style={{width: '10%'}}>Wynik</TableCell>
                                <TableCell>Opis</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className={'gray-striped'}>
                            {this.props.summary
                                .filter(row => this.props.categories.includes(row[0]))
                                .map((row, r) => (row[1] as SummaryRow[]).map((test, t) => {
                                        isEmptyCategory = false
                                        const monthDiff = this.monthDiff(new Date(test.publishedAt), new Date())

                                        return <TableRow key={'r' + r + t}>
                                            <TableCell>
                                                {this.getCategory(row[0])}
                                            </TableCell>
                                            <TableCell>
                                                <Link
                                                    component={NavLink}
                                                    to={generateCrudPath(testName, RouteType.SHOW, {
                                                        parentId: this.props.studentId,
                                                        id: test.testId
                                                    })}
                                                >
                                                    <TestConfigPreview id={test.testConfigId}/>
                                                </Link>
                                            </TableCell>
                                            <TableCell>
                                                <Typography style={monthDiff > 6 ? {color: 'rgb(220,0,78)'} : {}}>
                                                {Math.round(parseFloat(test.score + '') * 100)}%
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <ul>
                                                    {test.items.map(
                                                        (item, i) =>
                                                            item != ''
                                                                ? <li key={'i' + i}>{item}</li>
                                                                : null)}
                                                </ul>
                                            </TableCell>
                                        </TableRow>
                                    })
                                )}
                        </TableBody>
                    </Table>
                </Box>

            </AccordionDetails>
        </Accordion>

        return isEmptyCategory ? null : section
    }
}

type StateToProps = {
    readonly student: Student
    readonly summary: []
}

type DispatchToProps = {
    readonly setHeader: (actionName: string, type: RouteType) => void,
    readonly setAction: (action: JSX.Element) => void,
    readonly load: (actionName: string, id: string) => void,
}

type Params = {
    parentId: string
}

class SummaryComponent extends React.Component<StateToProps & DispatchToProps & RouteComponentProps<Params>> {
    public componentDidMount(): void {
        this.props.setHeader(studentSummaryName, RouteType.LIST)
        this.props.setAction(<></>)
        if (this.props.match.params.parentId) {
            this.props.load(studentsName, this.props.match.params.parentId)
            this.props.load(studentSummaryName, this.props.match.params.parentId)
        }
    }

    public render = () => this.props.student.id !== undefined ? <>
        <Tabs id={this.props.student.id || ' '}/>
        <Card variant="outlined">
            <CardContent>
                <Filter
                    actionName={studentSummaryName}
                    selector={(state: State) => state.studentSummary}
                    initialValues={{
                        config: [],
                        id: this.props.student.id || ' '
                    }}
                    fields={[
                        {name: 'config', label: 'Test', type: FilterType.MULTIPLE_SELECT, source: Source.TEST_CONFIG}
                    ]}
                />

                <SummarySection
                    header={'F'}
                    categories={['F', 'FO', 'FMD', 'FMM', 'FKP', 'FL', 'FS', 'FZ']}
                    summary={this.props.summary}
                    studentId={this.props.student.id}
                />

                <SummarySection
                    header={'K'}
                    categories={['K', 'KC', 'KB', 'KD']}
                    summary={this.props.summary}
                    studentId={this.props.student.id}
                />

                <SummarySection
                    header={'ES'}
                    categories={['ES', 'ESE', 'ESZ', 'ESM']}
                    summary={this.props.summary}
                    studentId={this.props.student.id}
                />

                <SummarySection
                    header={'P'}
                    categories={['P', 'PPW', 'PPS', 'PP', 'PKWR', 'PKSR', 'PW', 'PNW']}
                    summary={this.props.summary}
                    studentId={this.props.student.id}
                />

                <SummarySection
                    header={'Z'}
                    categories={['Z', 'ZO']}
                    summary={this.props.summary}
                    studentId={this.props.student.id}
                />

                <SummarySection
                    header={'ZB'}
                    categories={['ZB', 'ZBS']}
                    summary={this.props.summary}
                    studentId={this.props.student.id}
                />

                <SummarySection
                    header={'U'}
                    categories={['U', 'UO']}
                    summary={this.props.summary}
                    studentId={this.props.student.id}
                />

            </CardContent>
        </Card>
    </> : null
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    student: getRow(state => state.students)(state),
    summary: listRows(state => state.studentSummary)(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    setHeader: (actionName, type) => {
        dispatch(NavigationAction.setHeader(getCrudPathLabels(actionName, type)))
    },
    setAction: (action: JSX.Element) => {
        dispatch(NavigationAction.setAction(action))
    },
    load: (actionName: string, id: string) => {
        dispatch(CrudAction.execute(actionName, Type.GET, {id}))
    },
})

export const List = withRouter(connect(mapStateToProps, mapDispatchToProps)(SummaryComponent))