import {
    Avatar,
    Collapse,
    Drawer as MuiDrawer,
    Grid,
    IconButton,
    List as MuiList,
    ListItem,
    Typography
} from '@material-ui/core'
import {PowerSettingsNew as LogoutIcon} from "@material-ui/icons";
import DashboardIcon from '@material-ui/icons/BarChartOutlined'
import GroupIcon from '@material-ui/icons/EmojiPeopleOutlined'
import StudentIcon from '@material-ui/icons/FaceOutlined'
import UsersIcon from '@material-ui/icons/GroupOutlined'
import SubjectIcon from '@material-ui/icons/LibraryBooksOutlined'
import SchoolIcon from '@material-ui/icons/SchoolOutlined'
import DictionaryIcon from '@material-ui/icons/SubjectOutlined'
import TestConfigIcon from '@material-ui/icons/WebAssetOutlined'
import AnalysisIcon from '@material-ui/icons/TrendingUpOutlined'
import 'external-lib/perfect-scrollbar/perfect-scrollbar.css'
import {AuthAction, isGranted, me, rules} from 'lib/auth'
import {RouteType} from 'lib/crud'
import {name as groupsName} from 'lib/groups'
import {generateRouterCrudPath, ProtectedRoutes} from 'lib/router'
import {name as schoolsName} from 'lib/schools'
import {State} from 'lib/store'
import {name as studentsName} from 'lib/students'
import {name as subjectsName} from 'lib/subjects'
import {testConfigName} from "lib/testConfig"
import {selectTheme, Theme} from 'lib/theme'
import {name as usersName, User} from 'lib/users'
import * as React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux'
import {Route} from "react-router";
import styled from 'styled-components'
import {Category} from './Category'
import {Link} from "./Link"

const Drawer = styled(MuiDrawer)<UnknownProps & ThemeProps>`
  border-right: 0;

  > div {
    border-right: 0;
  }
`

const Brand = styled(ListItem)<UnknownProps & ThemeProps>`
  font-size: ${props => props.theme.typography.h5.fontSize};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  color: ${props => props.theme.sidebar.header.color};
  background-color: ${props => props.theme.sidebar.header.background};
  font-family: ${props => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${props => props.theme.spacing(6)}px;
  padding-right: ${props => props.theme.spacing(6)}px;

  ${props => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }
`

const SidebarFooter = styled.div`
  background-color: ${props => props.theme.sidebar.footer.background} !important;
  padding: ${props => props.theme.spacing(3)}px ${props => props.theme.spacing(4)}px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const SidebarFooterText = styled(Typography)`
  color: ${props => props.theme.sidebar.footer.color};
`;

const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${props => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`

const List = styled(MuiList)`
  background-color: ${props => props.theme.sidebar.background};
`

const Items = styled.div`
  padding-top: ${props => props.theme.spacing(2.5)}px;
  padding-bottom: ${props => props.theme.spacing(2.5)}px;
`

type UnknownProps = { [key: string]: unknown }

type ThemeProps = { theme: Theme }

type StateToProps = {
    readonly theme: Theme
    readonly me: User
}

type DispatchToProps = {
    readonly logout: () => void,
}

type SidebarProps = StateToProps & DispatchToProps & {
    theme: Theme
    PaperProps: any
    variant: string
    open?: boolean
    onClose?: () => void
}

type SidebarState = {
    categoryOpened: boolean[]
}

class SidebarComponent extends React.Component<SidebarProps, SidebarState> {
    state: SidebarState = {
        categoryOpened: [true, true]
    }

    public render() {
        const {PaperProps, variant, open, onClose} = this.props

        return (
            <Drawer PaperProps={PaperProps} variant={variant} open={open || false} onClose={onClose}>
                <Brand>Słoneczna Kraina</Brand>

                <Scrollbar>
                    <List disablePadding>
                        <Items>
                            <Route path={'/'}>
                                <Category
                                    name={"Start"}
                                    icon={<DashboardIcon/>}
                                    isCollapsible={false}
                                    to={ProtectedRoutes.Start}
                                    isVisible={isGranted(rules.start.list, this.props.me)}
                                />
                                <Category
                                    name={"Użytkownicy"}
                                    icon={<UsersIcon/>}
                                    isCollapsible={false}
                                    to={generateRouterCrudPath(usersName, RouteType.LIST)}
                                    isVisible={isGranted(rules.user.list, this.props.me)}
                                />
                                <Category
                                    name={"Placówki"}
                                    icon={<SchoolIcon/>}
                                    isCollapsible={false}
                                    to={generateRouterCrudPath(schoolsName, RouteType.LIST)}
                                    isVisible={isGranted(rules.school.list, this.props.me)}
                                />
                                <Category
                                    name={"Dzieci"}
                                    icon={<StudentIcon/>}
                                    isCollapsible={false}
                                    to={generateRouterCrudPath(studentsName, RouteType.LIST)}
                                />
                                <Category
                                    name={"Katalog testów"}
                                    icon={<TestConfigIcon/>}
                                    isCollapsible={false}
                                    to={generateRouterCrudPath(testConfigName, RouteType.LIST)}
                                    isVisible={isGranted(rules.testConfig.list, this.props.me)}
                                />
                                <Category
                                    name={"Analizy"}
                                    icon={<AnalysisIcon/>}
                                    isCollapsible={true}
                                    isOpen={this.state.categoryOpened[0]}
                                    onClickFn={this.toggleCategoryOpen(0)}
                                    isVisible={isGranted(rules.analysis.list, this.props.me)}
                                />
                                {isGranted(rules.analysis.list, this.props.me) ?
                                    <Collapse
                                        in={this.state.categoryOpened[0]}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <Link
                                            name={"Placówki"}
                                            to={ProtectedRoutes.TestCompareSchools}
                                            isVisible={isGranted(rules.analysis.school, this.props.me)}
                                        />
                                        <Link
                                            name={"Grupy"}
                                            to={ProtectedRoutes.TestCompareGroups}
                                            isVisible={isGranted(rules.analysis.list, this.props.me)}
                                        />
                                        <Link
                                            name={"Dziecko"}
                                            to={ProtectedRoutes.TestCompareChildren}
                                            isVisible={isGranted(rules.analysis.list, this.props.me)}
                                        />
                                    </Collapse> : null}
                                <Category
                                    name={"Grupy"}
                                    icon={<GroupIcon/>}
                                    isCollapsible={false}
                                    to={generateRouterCrudPath(groupsName, RouteType.LIST)}
                                    isVisible={isGranted(rules.group.list, this.props.me)}
                                />
                                <Category
                                    name={"Katalog zajęć"}
                                    icon={<SubjectIcon/>}
                                    isCollapsible={false}
                                    to={generateRouterCrudPath(subjectsName, RouteType.LIST)}
                                    isVisible={isGranted(rules.subject.list, this.props.me)}
                                />
                                <Category
                                    name={"Słowniki"}
                                    icon={<DictionaryIcon/>}
                                    isCollapsible={true}
                                    isOpen={this.state.categoryOpened[1]}
                                    onClickFn={this.toggleCategoryOpen(1)}
                                    isVisible={isGranted(rules.dictionary.list, this.props.me)}
                                />
                                <Collapse
                                    in={this.state.categoryOpened[1]}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <Link
                                        name={"Specjalizacje"}
                                        to={ProtectedRoutes.DictionarySpecialization}
                                        isVisible={isGranted(rules.dictionary.list, this.props.me)}
                                    />
                                    <Link
                                        name={"Typy niepełnosprawności"}
                                        to={ProtectedRoutes.DictionaryDisability}
                                        isVisible={isGranted(rules.dictionary.list, this.props.me)}
                                    />
                                    <Link
                                        name={"Uprawnienia"}
                                        to={ProtectedRoutes.DictionaryQualification}
                                        isVisible={isGranted(rules.dictionary.list, this.props.me)}
                                    />
                                    <Link
                                        name={"Zdarzenia"}
                                        to={ProtectedRoutes.DictionaryEvent}
                                        isVisible={isGranted(rules.dictionary.list, this.props.me)}
                                    />
                                </Collapse>
                            </Route>
                        </Items>
                    </List>
                </Scrollbar>
                <SidebarFooter>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <Avatar style={{backgroundColor: this.props.theme.header.indicator.background}}>
                                {this.props.me.lastName.substr(0, 1)}{this.props.me.firstName.substr(0, 1)}
                            </Avatar>
                        </Grid>
                        <Grid item xs>
                            <SidebarFooterText variant="body2">
                                {this.props.me.lastName} {this.props.me.firstName}
                            </SidebarFooterText>
                        </Grid>
                        <Grid>
                            <IconButton onClick={this.props.logout}>
                                <LogoutIcon style={{color: '#ffffff'}}/>
                            </IconButton>
                        </Grid>
                    </Grid>
                </SidebarFooter>

            </Drawer>
        )
    }

    private toggleCategoryOpen = (index: number) => () => {
        this.setState(prevState => {
            let prevCategoryOpened = prevState.categoryOpened
            prevCategoryOpened[index] = !prevCategoryOpened[index]

            return {...prevState, categoryOpened: prevCategoryOpened}
        })
    }
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    theme: selectTheme(state),
    me: me(state)!,
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    logout: () => {
        dispatch(AuthAction.logout())
    },
})

export const Sidebar = connect(mapStateToProps, mapDispatchToProps)(SidebarComponent)
