import {Link} from '@material-ui/core'
import {Preview} from 'app/Components'
import {RouteType} from 'lib/crud'
import {generateCrudPath} from 'lib/router'
import {name as usersName} from 'lib/users'
import {Source} from 'lib/select'
import {User} from 'lib/users'
import * as React from 'react'
import {connect} from 'react-redux'
import {NavLink} from "react-router-dom"

type UsersPreviewProps = {
    ids: string[]
}

class UsersPreviewComponent extends React.Component<UsersPreviewProps> {
    public render = () => <Preview
        value={this.props.ids}
        source={Source.USERS}
        template={(user: User) => <Link component={NavLink} to={generateCrudPath(usersName, RouteType.SHOW, {id: user.id})}>{user.lastName} {user.firstName}</Link>}
    />
}

export const UsersPreview = connect()(UsersPreviewComponent)