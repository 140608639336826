import {createSelector} from 'reselect'
import {State} from 'lib/store'
import {AuthState} from 'lib/auth/reducer'

const selectAuthFeature = (state: State): AuthState => state.auth

export const isInitialized = createSelector(selectAuthFeature, auth => auth.isInitialized)
export const isAuthenticated = createSelector(selectAuthFeature, auth => auth.isAuthenticated)
export const isSubmitting = createSelector(selectAuthFeature, auth => auth.isSubmitting)
export const isTokenValidating = createSelector(selectAuthFeature, auth => auth.isTokenValidating)
export const isTokenValidated = createSelector(selectAuthFeature, auth => auth.isTokenValidated)
export const me = createSelector(selectAuthFeature, auth => auth.user)