import {Button, Card, CardContent as MuiCardContent, FormControl, Grid, TextField} from '@material-ui/core'
import {KeyboardDatePicker} from "@material-ui/pickers";
import {spacing} from '@material-ui/system'
import {Autocomplete, CircularProgressBar} from 'app/Components'
import {format} from "date-fns";
import {onKeyDown} from 'external-lib/utils/helpers'
import {Formik} from 'formik'
import {isLoading, isProcessing} from 'lib/progress'
import {Source} from 'lib/select'
import {State} from 'lib/store'
import React from 'react'
import {connect, MapStateToProps} from 'react-redux'
import styled from 'styled-components'
import * as Yup from 'yup'
import {HistoryEntry} from 'lib/historyEntry'

const CardContent = styled(MuiCardContent)(spacing)

type StateToProps = {
    readonly isLoading: boolean
    readonly isProcessing: boolean
}

type FormControlProps = {
    initialValues: HistoryEntry
    handleSubmit: (historyEntry: HistoryEntry) => void
}

const Schema = Yup.object().shape({
    event: Yup.string()
        .required('Pole jest wymagane'),
    description: Yup.string()
        .required('Pole jest wymagane'),
    occurredAt: Yup.date()
        .required('Pole jest wymagane'),
})

class FormComponent extends React.Component<FormControlProps & StateToProps> {
    public render = () =>
        <>
            {
                this.props.isLoading
                    ? <CircularProgressBar/>
                    : <Formik
                        initialValues={this.props.initialValues}
                        validationSchema={Schema}
                        validateOnChange={false}
                        onSubmit={values => this.props.handleSubmit(values)}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                              setFieldValue
                          }) => (
                            <form onSubmit={handleSubmit} onKeyDown={onKeyDown}>

                                <Card variant="outlined">
                                    <CardContent>

                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl margin="dense" fullWidth>
                                                    <Autocomplete
                                                        label={"Wydarzenie *"}
                                                        field={'event'}
                                                        source={Source.EVENTS}
                                                        value={values.event}
                                                        error={Boolean(errors.event && touched.event)}
                                                        setFieldValue={setFieldValue}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl margin="dense" fullWidth>
                                                    <TextField
                                                        error={Boolean(errors.description && touched.description)}
                                                        label="Opis *"
                                                        id="description"
                                                        name="description"
                                                        variant="outlined"
                                                        value={values.description}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        helperText={errors.description}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl margin="dense" fullWidth>
                                                    <KeyboardDatePicker
                                                        error={Boolean(errors.occurredAt && touched.occurredAt)}
                                                        format="dd-MM-yyyy"
                                                        maxDate={format(new Date(), "dd-MM-yyyy")}
                                                        helperText={errors.occurredAt}
                                                        id="occurredAt"
                                                        label="Data wystąpienia (dd-mm-rrrr)*"
                                                        value={values.occurredAt || null}
                                                        onChange={val => {
                                                            setFieldValue("occurredAt", val);
                                                        }}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'zmień datę',
                                                        }}
                                                        autoOk
                                                        cancelLabel="Anuluj"
                                                        inputVariant="outlined"
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleSubmit()}
                                                    disabled={this.props.isProcessing}
                                                >
                                                    Zapisz
                                                </Button>
                                            </Grid>
                                        </Grid>

                                    </CardContent>
                                </Card>
                            </form>
                        )}
                    </Formik>
            }
        </>
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    isLoading: isLoading(state),
    isProcessing: isProcessing(state),
})

export const Form = connect(mapStateToProps)(FormComponent)