import TestResultIcon from '@material-ui/icons/EmojiEventsOutlined';
import TestSummaryIcon from '@material-ui/icons/EqualizerOutlined';
import TestDataIcon from '@material-ui/icons/ReorderOutlined';
import TestAnalysisIcon from '@material-ui/icons/TimelineOutlined';
import {Bookmarks} from 'app/Components'
import {RouteType} from 'lib/crud'
import {generateCrudPath} from 'lib/router'
import {testAnalysisName, testResultName, testName, testSummaryName, Test} from 'lib/test'
import * as React from 'react'
import {selectConfig} from "lib/testConfig";

type TabsProps = {
    studentId: string
    test: Test
}

export class Tabs extends React.Component<TabsProps> {

    public render = () => {
        const testConfig = selectConfig(this.props.test.config)

        let bookmarks = [{
            label: 'Dane',
            icon: <TestDataIcon/>,
            to: generateCrudPath(testName, RouteType.SHOW, {parentId: this.props.studentId, id: this.props.test.id})
        }]

        if (testConfig.tabs.results != null) {
            bookmarks.push({
                label: 'Wyniki',
                icon: <TestResultIcon/>,
                to: generateCrudPath(testResultName, RouteType.SHOW, {parentId: this.props.studentId, id: this.props.test.id})
            })
        }

        if (testConfig.tabs.summary != null) {
            bookmarks.push({
                label: 'Podsumowanie',
                icon: <TestSummaryIcon/>,
                to: generateCrudPath(testSummaryName, RouteType.SHOW, {parentId: this.props.studentId, id: this.props.test.id})
            })
        }

        if (testConfig.tabs.analysis != null) {
            bookmarks.push({
                label: 'Analiza',
                icon: <TestAnalysisIcon/>,
                to: generateCrudPath(testAnalysisName, RouteType.SHOW, {parentId: this.props.studentId, id: this.props.test.id})
            })
        }

        return (<Bookmarks bookmarks={bookmarks} />)
   }
}