import {Box} from '@material-ui/core'
import * as React from 'react'
import {Q1, Q10, Q11, Q12, Q16, Q2, Q20, Q3, Q4, QYesNo, QYesNoExample, QYesNoExampleHardToDefine} from './Questions'

type SectionProps = {
    index: number
    section: LFWTestSection
    preview?: boolean
    prev: null|number
    next: null|number
}

type SectionState = {
    section: LFWTestSection
    editableActivity: boolean
}

export type LFWTestSection = {
    groups: string[]
    question: string,
    required: number,
    response: any
}

export class LFWSection extends React.Component<SectionProps, SectionState> {
    state: SectionState = {
        section: this.props.section,
        editableActivity: this.props.index > 5
    }

    public render = () => {
        const isPreview = this.props.preview || false

        return <Box style={isPreview ? {margin: '0px 0px 50px 0px'} : {}}>
            {(this.props.index + 1) + '. ' + this.state.section.question}

            {this.props.index == 0 ? <Q1 section={this.state.section} preview={isPreview}/> : null}
            {this.props.index == 1 ? <Q2 section={this.state.section} preview={isPreview}/> : null}
            {this.props.index == 2 ? <Q3 section={this.state.section} preview={isPreview}/> : null}
            {this.props.index == 3 ? <Q4 section={this.state.section} preview={isPreview}/> : null}
            {this.props.index == 4 ? <QYesNo section={this.state.section} preview={isPreview}/> : null}
            {this.props.index == 5 ? <QYesNoExample section={this.state.section} preview={isPreview}/> : null}
            {this.props.index == 6 ? <QYesNoExample section={this.state.section} preview={isPreview}/> : null}
            {this.props.index == 7 ? <QYesNoExample section={this.state.section} preview={isPreview}/> : null}
            {this.props.index == 8 ? <QYesNoExample section={this.state.section} preview={isPreview}/> : null}
            {this.props.index == 9 ? <Q10 section={this.state.section} preview={isPreview}/> : null}
            {this.props.index == 10 ? <Q11 section={this.state.section} preview={isPreview}/> : null}
            {this.props.index == 11 ? <Q12 section={this.state.section} preview={isPreview}/> : null}
            {this.props.index == 12 ? <QYesNoExample section={this.state.section} preview={isPreview}/> : null}
            {this.props.index == 13 ? <QYesNoExampleHardToDefine section={this.state.section} preview={isPreview}/> : null}
            {this.props.index == 14 ? <QYesNoExampleHardToDefine section={this.state.section} preview={isPreview}/> : null}
            {this.props.index == 15 ? <Q16 section={this.state.section} preview={isPreview}/> : null}
            {this.props.index == 16 ? <QYesNoExampleHardToDefine section={this.state.section} preview={isPreview}/> : null}
            {this.props.index == 17 ? <QYesNoExampleHardToDefine section={this.state.section} preview={isPreview}/> : null}
            {this.props.index == 18 ? <QYesNoExampleHardToDefine section={this.state.section} preview={isPreview}/> : null}
            {this.props.index == 19 ? <Q20 section={this.state.section} preview={isPreview}/> : null}
        </Box>
    }
}