import {Configuration, HttpHeaders, Middleware, RequestArgs, ResponseArgs} from 'client'
import {isDevelopment} from "../env";

const requestPrintMiddleware: Middleware = {
    pre(request: RequestArgs): RequestArgs {
        if (isDevelopment) {
            console.log(request)
        }

        return request
    }
}

const responsePrintMiddleware: Middleware = {
    post(response: ResponseArgs): ResponseArgs {
        if (isDevelopment && response.response) {
            console.log(response.response)
        }

        return response
    }
}

export const configuration = new Configuration({
    basePath: process.env.REACT_APP_BASE_PATH,
    middleware: [
        // requestPrintMiddleware,
        // responsePrintMiddleware
    ],
})