import {Card, CardContent, Table, TableBody, TableCell, TableContainer, TableRow} from '@material-ui/core'
import {CrudShowHeader, SchoolPreview, Status} from 'app/Components'
import {isGranted, me, rules} from 'lib/auth'
import {getRow} from 'lib/crud'
import {Group, name} from 'lib/groups'
import {isLoading} from 'lib/progress'
import {State} from 'lib/store'
import {User} from 'lib/users'
import * as React from 'react'
import {connect, MapStateToProps} from 'react-redux'
import {Tabs} from './Tabs'

type StateToProps = {
    readonly isLoading: boolean
    readonly group: Group
    readonly me: User
}

type ShowProps = StateToProps

class ShowComponent extends React.Component<ShowProps> {
    public render = () => (<>
        <CrudShowHeader actionName={name}/>

        <Tabs id={this.props.group.id || ' '} />
        <Card variant="outlined">
            <CardContent>
                <TableContainer>
                    <Table>
                        <TableBody className={'gray-striped'}>
                            <TableRow>
                                <TableCell component="td" style={{width: '20%'}}>Nazwa</TableCell>
                                <TableCell component="td">
                                    {this.props.isLoading
                                        ? ''
                                        : this.props.group.name
                                    }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="td">Szkoła</TableCell>
                                <TableCell component="td">
                                    {this.props.isLoading
                                        ? ''
                                        : <SchoolPreview id={this.props.group.school} />
                                    }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="td">Podstawa programowa realizowana dla dzieci w wieku</TableCell>
                                <TableCell component="td">
                                    {this.props.isLoading
                                        ? ''
                                        : this.props.group.studentAge > 0 ? this.props.group.studentAge + ' lat.' : 'Brak'
                                    }
                                </TableCell>
                            </TableRow>
                            {isGranted(rules.group.status, this.props.me) ? <TableRow>
                                <TableCell component="td">Status</TableCell>
                                <TableCell component="td">
                                    {this.props.isLoading
                                        ? ''
                                        : <Status status={this.props.group.active} activeLabel={'Aktywna'} inactiveLabel={'Nieaktywna'}/>
                                    }
                                </TableCell>
                            </TableRow> : null}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    </>)
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    isLoading: isLoading(state),
    group: getRow(state => state.groups)(state),
    me: me(state)!,
})

export const Show = connect(mapStateToProps)(ShowComponent)