import {AnyAction} from 'redux'
import {ProgressActionType} from 'lib/progress'

export type ProgressState = {
    loading: boolean
    processing: boolean
}

export const initialProgressState = {
    loading: false,
    processing: false,
}

export function progressReducer(state: ProgressState = initialProgressState, action: AnyAction): ProgressState {
    switch (action.type) {
        case ProgressActionType.setLoading():
            return {
                ...state,
                loading: action.payload,
            }
        case ProgressActionType.setProcessing():
            return {
                ...state,
                processing: action.payload,
            }
        default:
            return state
    }
}
