export type Item = {
    id: string
    age: string
    data: Section[]
}

type Student = {
    student: string
    evaluation: Evaluation[]
}

type School = {
    id: string
    evaluation: Evaluation[]
}

type Group = {
    id: string
    evaluation: Evaluation[]
}

type Evaluation = {
    age: string
    sections: Section[]
}

type Section = {
    name: string
    score: number
}

const filterAge = (evaluation: Evaluation, age: string) => {
    return evaluation.age == age || age == ''
}

export const transformStudentsComparison = (students: Student[]): Item[] => {
    return students.flatMap((student: Student) => {
        return student.evaluation.map(evaluation => {
            return <Item>{
                id: student.student,
                age: evaluation.age,
                data: evaluation.sections
            }
        })
    })
}

export const transformSchoolsComparison = (schools: School[], age: string): Item[] => {
    return schools.flatMap((school: School) => {
        return school.evaluation.filter(evaluation => filterAge(evaluation, age)).map(evaluation => {
            return <Item>{
                id: school.id,
                age: evaluation.age,
                data: evaluation.sections
            }
        })
    })
}

export const transformGroupsComparison = (groups: Group[], age: string): Item[] => {
    return groups.flatMap((group: Group) => {
        return group.evaluation.filter(evaluation => filterAge(evaluation, age)).map(evaluation => {
            return <Item>{
                id: group.id,
                age: evaluation.age,
                data: evaluation.sections
            }
        })
    })
}