import {ListItemIcon, MenuItem} from '@material-ui/core'
import ShowIcon from '@material-ui/icons/VisibilityOutlined'
import * as React from 'react'
import {NavLink} from 'react-router-dom'

type ShowMenuItemProps = {
    to: string
}

export class ShowMenuItem extends React.Component<ShowMenuItemProps> {
    public render = () =>
        <MenuItem
            selected={false}
            component={NavLink}
            to={this.props.to}
        >
            <ListItemIcon>
                <ShowIcon/>
            </ListItemIcon>
            Podgląd
        </MenuItem>
}
