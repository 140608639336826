import {CrudEditHeader} from 'app/Components/Crud'
import {me, Role} from 'lib/auth'
import {CrudAction, getRow, Type} from 'lib/crud'
import {State} from 'lib/store'
import {name, newProfile, Profile, User} from 'lib/users'
import * as React from 'react'
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux'
import {Form, ProfileForm} from './Form'

type StateToProps = {
    readonly user: User
    readonly me: User
}

type DispatchToProps = {
    readonly handleUserSubmit: (user: User) => void,
    readonly handleProfileSubmit: (profile: Profile) => void,
}

type EditProps = StateToProps & DispatchToProps

class EditComponent extends React.Component<EditProps> {
    public isAdmin = (): boolean =>
        this.props.me.roles.includes(Role.ROLE_ADMIN)
        || this.props.me.roles.includes(Role.ROLE_SCHOOL_ADMIN)

    public render = () => (<>
        <CrudEditHeader actionName={name}/>
        {this.isAdmin()
            ? <Form initialValues={this.props.user} handleSubmit={this.props.handleUserSubmit}/>
            : <ProfileForm initialValues={newProfile(this.props.user)} handleSubmit={this.props.handleProfileSubmit}/>}
    </>)
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    user: getRow(state => state.users)(state),
    me: me(state)!
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    handleUserSubmit: (user: User) => {
        dispatch(CrudAction.execute(name, Type.UPDATE, user))
    },
    handleProfileSubmit: (profile: Profile) => {
        dispatch(CrudAction.execute(name, Type.UPDATE, profile))
    }
})

export const Edit = connect(mapStateToProps, mapDispatchToProps)(EditComponent)