import {minutes} from 'lib/time'
import {AuthApi, PasswordResetApi, UserApi} from 'client/apis'
import {configuration} from 'lib/client'

export const refreshTokenInterval = minutes(3)

export const AuthApiClient = (): AuthApi => {
    return new AuthApi(configuration)
}

export const PasswordResetClient = (): PasswordResetApi => {
    return new PasswordResetApi(configuration)
}

export const UserApiClient = (): UserApi => {
    return new UserApi(configuration)
}