import {CrudAction, getRow, Type} from 'lib/crud'
import {State} from 'lib/store'
import * as React from 'react'
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux'
import {Form} from './Form'
import {testConfigName, TestConfig} from 'lib/testConfig'
import {CrudEditHeader} from 'app/Components'

type StateToProps = {
    readonly testConfig: TestConfig
}

type DispatchToProps = {
    readonly handleSubmit: (testConfig: TestConfig) => void,
}

type EditProps = StateToProps & DispatchToProps

class EditComponent extends React.Component<EditProps> {
    public render = () => (<>
        <CrudEditHeader actionName={testConfigName}/>
        <Form initialValues={this.props.testConfig} handleSubmit={this.props.handleSubmit}/>
    </>)
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    testConfig: getRow(state => state.testConfig)(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    handleSubmit: (testConfig: TestConfig) => {
        const testConfigId = testConfig.id
        delete testConfig.id

        // TODO: do it properly
        if (testConfig.specialties === null) {
            testConfig.specialties = []
        }

        if (testConfig.roles === null) {
            testConfig.roles = []
        }

        if (testConfig.qualifications === null) {
            testConfig.qualifications = []
        }

        dispatch(CrudAction.execute(testConfigName, Type.UPDATE, {
            id: testConfigId,
            data: testConfig
        }))
    }
})

export const Edit = connect(mapStateToProps, mapDispatchToProps)(EditComponent)