import {
    Button,
    Card,
    CardContent as MuiCardContent,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    TextField
} from '@material-ui/core'
import {spacing} from '@material-ui/system'
import {Autocomplete, CircularProgressBar} from 'app/Components'
import {onKeyDown} from 'external-lib/utils/helpers'
import {Formik} from 'formik'
import {isLoading, isProcessing} from 'lib/progress'
import {School} from 'lib/schools'
import {Source} from 'lib/select'
import {State} from 'lib/store'
import React from 'react'
import {connect, MapStateToProps} from 'react-redux'
import styled from 'styled-components'
import * as Yup from 'yup'

const CardContent = styled(MuiCardContent)(spacing)

type StateToProps = {
    readonly isLoading: boolean
    readonly isProcessing: boolean
}

type FormControlProps = {
    initialValues: School
    handleSubmit: (school: School) => void
}

const Schema = Yup.object().shape({
    name: Yup.string()
        .required('Pole jest wymagane'),
    street: Yup.string()
        .required('Pole jest wymagane'),
    houseNumber: Yup.string()
        .required('Pole jest wymagane'),
    zipCode: Yup.string()
        .required('Pole jest wymagane'),
    city: Yup.string()
        .required('Pole jest wymagane'),
    type: Yup.string()
        .required('Pole jest wymagane'),
})

class FormComponent extends React.Component<FormControlProps & StateToProps> {
    public render = () =>
        <>
            {
                this.props.isLoading
                    ? <CircularProgressBar/>
                    : <Formik
                        initialValues={this.props.initialValues}
                        validationSchema={Schema}
                        onSubmit={values => this.props.handleSubmit(values)}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                              setFieldValue
                          }) => (
                            <form onSubmit={handleSubmit} onKeyDown={onKeyDown}>

                                <Card variant="outlined">
                                    <CardContent>

                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl margin="dense" fullWidth>
                                                    <TextField
                                                        error={Boolean(errors.name && touched.name)}
                                                        label="Nazwa *"
                                                        id="name"
                                                        name="name"
                                                        variant="outlined"
                                                        inputProps={{
                                                            maxLength: 100,
                                                        }}
                                                        value={values.name}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        helperText={errors.name}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <FormControl margin="dense" fullWidth>
                                                    <TextField
                                                        error={Boolean(errors.street && touched.street)}
                                                        label="Ulica *"
                                                        id="street"
                                                        name="street"
                                                        variant="outlined"
                                                        inputProps={{
                                                            maxLength: 80,
                                                        }}
                                                        value={values.street}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        helperText={errors.street}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                                <FormControl margin="dense" fullWidth>
                                                    <TextField
                                                        error={Boolean(errors.houseNumber && touched.houseNumber)}
                                                        label="Nr domu *"
                                                        id="houseNumber"
                                                        name="houseNumber"
                                                        variant="outlined"
                                                        inputProps={{
                                                            maxLength: 10,
                                                        }}
                                                        value={values.houseNumber}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        helperText={errors.houseNumber}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                                <FormControl margin="dense" fullWidth>
                                                    <TextField
                                                        error={Boolean(errors.flatNumber && touched.flatNumber)}
                                                        label="Nr mieszkania"
                                                        id="flatNumber"
                                                        name="flatNumber"
                                                        variant="outlined"
                                                        inputProps={{
                                                            maxLength: 10,
                                                        }}
                                                        value={values.flatNumber}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        helperText={errors.flatNumber}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={2} lg={2}>
                                                <FormControl margin="dense" fullWidth>
                                                    <TextField
                                                        error={Boolean(errors.zipCode && touched.zipCode)}
                                                        label="Kod pocztowy *"
                                                        id="zipCode"
                                                        name="zipCode"
                                                        variant="outlined"
                                                        inputProps={{
                                                            maxLength: 6,
                                                        }}
                                                        value={values.zipCode}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        helperText={errors.zipCode}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={10} lg={10}>
                                                <FormControl margin="dense" fullWidth>
                                                    <TextField
                                                        error={Boolean(errors.city && touched.city)}
                                                        label="Miasto *"
                                                        id="city"
                                                        name="city"
                                                        variant="outlined"
                                                        inputProps={{
                                                            maxLength: 50,
                                                        }}
                                                        value={values.city}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        helperText={errors.city}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl margin="dense" fullWidth>
                                                    <TextField
                                                        error={Boolean(errors.phone && touched.phone)}
                                                        label="Telefon"
                                                        id="phone"
                                                        name="phone"
                                                        variant="outlined"
                                                        inputProps={{
                                                            maxLength: 20,
                                                        }}
                                                        value={values.phone}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        helperText={errors.phone}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl margin="dense" fullWidth>
                                                    <Autocomplete
                                                        label={"Dyrektorzy"}
                                                        field={'principals'}
                                                        source={Source.USERS}
                                                        value={values.principals}
                                                        error={false}
                                                        setFieldValue={setFieldValue}
                                                        requestOptions={{'school': this.props.initialValues.id}}
                                                        multiple
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl margin="dense" fullWidth>
                                                    <Autocomplete
                                                        label={"Typ *"}
                                                        field={'type'}
                                                        source={Source.SCHOOL_TYPE}
                                                        value={values.type}
                                                        error={Boolean(errors.type && touched.type)}
                                                        setFieldValue={setFieldValue}
                                                        helperText={errors.type as string}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControlLabel
                                                    control={<Checkbox id="licensed" checked={values.licensed}
                                                                       onChange={handleChange}
                                                                       color="primary"/>}
                                                    label="Licencja"
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControlLabel
                                                    control={<Checkbox id="active" checked={values.active}
                                                                       onChange={handleChange}
                                                                       color="primary"/>}
                                                    label="Aktywna"
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleSubmit()}
                                                    disabled={this.props.isProcessing}
                                                >
                                                    Zapisz
                                                </Button>
                                            </Grid>
                                        </Grid>

                                    </CardContent>
                                </Card>
                            </form>
                        )}
                    </Formik>
            }
        </>
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    isLoading: isLoading(state),
    isProcessing: isProcessing(state),
})

export const Form = connect(mapStateToProps)(FormComponent)