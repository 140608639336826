import {configuration} from 'lib/client'
import {GroupApi} from "client/apis";

const GroupApiClient = (): GroupApi => {
    return new GroupApi(configuration)
}

export const dao = {
    list: (payload: any) => {
        return GroupApiClient().getGroupCollection(payload)
    },
    get: (payload: any) => {
        return GroupApiClient().getGroupItem({id: payload.id})
    },
    create: (payload: any) => {
        return GroupApiClient().postGroupCollection({groupWrite: payload})
    },
    update: (payload: any) => {
        return GroupApiClient().putGroupItem({id: payload.id, groupWrite: payload.data})
    },
    delete: (payload: any) => {
        return GroupApiClient().deleteGroupItem({id: payload.id})
    }
}