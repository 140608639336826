import React from 'react'
import styled from 'styled-components'
import {Box as MuiBox, LinearProgress as MuiLinearProgress} from '@material-ui/core'

const Box = styled(MuiBox)`
  position: relative;
`

const LinearProgress = styled(MuiLinearProgress)`
  position: absolute;
  width: 100%;
`

type LinearProgressBarProps = {
    isVisible: boolean
}

export class LinearProgressBar extends React.Component<LinearProgressBarProps> {
    public render() {
        return (
            <Box>
                {this.props.isVisible ? <LinearProgress/> : ""}
            </Box>
        )
    }
}