import {crudReducer, CrudState, initialCrudState} from 'lib/crud'
import {AnyAction} from 'redux'

export const name = 'TEACHERS'

export type GroupTeachersState = {
    crud: CrudState,
}

const initialState = {
    crud: initialCrudState,
}

export function groupTeachersReducer(state: GroupTeachersState = initialState, action: AnyAction): GroupTeachersState {
    switch (action.type) {
        default:
            return {
                ...state,
                crud: crudReducer(name, state.crud, action)
            }
    }
}
