import {ActionsUnion, createAction} from 'external-lib/redux-utils'

export const TestActionType = {
    filter: (name: string): string => `[CRUD] ${name} filter`,

    execute: (name: string, type: string): string => `[CRUD] ${name} ${type} start`,
    executeResponseFailure: (name: string, type: string): string => `[CRUD] ${name} ${type} failure`,
    executeResponseSuccess: (name: string, type: string): string => `[CRUD] ${name} ${type} success`,

    compare: '[TEST] Compare start',
    compareFailure: '[TEST] Compare failure',
    compareSuccess: '[TEST] Compare success',

    determineIfCorrect: (): string => `[DATA] determine if test preview correct`,
    setInvalidSection: (): string => `[DATA] Set invalid section`,

    analyseReset: '[TEST] Analysis reset',
}

export const TestAction = {
    filter: (name: string, filters: any) => createAction(TestActionType.filter(name), filters),

    execute: <R>(name: string, type: string, request: R) => createAction(TestActionType.execute(name, type), request),
    executeResponseFailure: (name: string, type: string, error: any) => createAction(TestActionType.executeResponseFailure(name, type), error),
    executeResponseSuccess: (name: string, type: string, response: any) => createAction(TestActionType.executeResponseSuccess(name, type), response),

    compare: <R>(request: R) => createAction(TestActionType.compare, request),
    compareFailure: (error: any) => createAction(TestActionType.compareFailure, error),
    compareSuccess: (response: any) => createAction(TestActionType.compareSuccess, response),

    determineIfCorrect: (isCorrect: boolean) => createAction(TestActionType.determineIfCorrect(), isCorrect),
    setInvalidSection: (sectionNumber: number) => createAction(TestActionType.setInvalidSection(), sectionNumber),

    analyseReset: <R>(request: R) => createAction(TestActionType.analyseReset, request),
}

export type TestAction = ActionsUnion<typeof TestAction>