export type HistoryEntry = {
    id: string
    student: string
    description: string
    event: string
    occurredAt: string
}

export const newHistoryEntry = (student: string): HistoryEntry => ({
    id: '',
    student: student,
    description: '',
    event: '',
    occurredAt: '',
})
