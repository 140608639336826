import {CrudList, FormattedDate, StudentFileLabel, StudentFileStatus, UserPreview} from 'app/Components'
import {Tabs} from 'app/Views/Students/Tabs/Tabs'
import {isGranted, me, rules} from 'lib/auth'
import {CrudAction, emptyFilterId, getRow, Type} from 'lib/crud'
import {State} from 'lib/store'
import {name, StudentFile} from 'lib/studentFiles'
import {name as studentsName, Student} from 'lib/students'
import {User} from 'lib/users'
import * as React from 'react'
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux'
import {withRouter} from 'react-router'
import {RouteComponentProps} from 'react-router-dom'

type StateToProps = {
    readonly student: Student
    readonly user: User
}

type DispatchToProps = {
    readonly load: (actionName: string, id: string) => void,
}

type Params = {
    parentId: string
}

const cols = [
    {
        id: "type",
        numeric: false,
        label: "Typ",
        sort: false,
        width: '100px',
        template: (file: StudentFile) => file.type
    },
    {
        id: "createdAt",
        numeric: false,
        label: "Data",
        sort: false,
        template: (file: StudentFile) => <FormattedDate date={file.createdAt} />
    },
    {
        id: "name",
        numeric: false,
        label: "Nazwa",
        sort: false,
        template: (file: StudentFile) => <StudentFileLabel file={file} />
    },
    {
        id: "author",
        numeric: false,
        label: "Autor",
        sort: false,
        width: '150px',
        template: (file: StudentFile) => <UserPreview id={file.createdBy} />
    },
    {
        id: "status",
        numeric: false,
        label: "Status",
        sort: false,
        width: '100px',
        template: (file: StudentFile) => <StudentFileStatus file={file} />
    },
]

class ParentsComponent extends React.Component<StateToProps & DispatchToProps & RouteComponentProps<Params>> {
    public componentDidMount(): void {
        if (this.props.match.params.parentId) {
            this.props.load(studentsName, this.props.match.params.parentId)
        }
    }

    public render = () => (<>
        {this.props.student.id !== undefined ? <CrudList
            actionName={name}
            parentActionName={studentsName}
            cols={cols}
            deleteDialogText={"Usunąć dokument?"}
            selector={(state: State) => state.studentFiles}
            rules={rules.studentFiles}
            filter={{
                initialValues: {
                    student: this.props.match.params.parentId,
                },
                fields: []
            }}
            tabs={<Tabs id={this.props.student.id || ' '} />}
        /> : null}
    </>
    )
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    student: getRow(state => state.students)(state),
    user: me(state)!,
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    load: (actionName: string, id: string) => {
        dispatch(CrudAction.execute(actionName, Type.GET, {id}))
    },
})

export const List = withRouter(connect(mapStateToProps, mapDispatchToProps)(ParentsComponent))