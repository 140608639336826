import {
    Box,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core'
import * as React from 'react'

type PreviewProps = {
    data: Section[]
}

interface Category {
    name: string
    result: number
    score: number
    max: number
}

interface Section {
    section: string
    categories: Category[]
}

export class WodResults extends React.Component<PreviewProps> {
    public render = () => {
        return <TableContainer>
            <Table className={'gray-striped'}>
                <TableHead>
                    <TableRow>
                        <TableCell>Kategoria</TableCell>
                        <TableCell>Poziom rozwoju funkcji</TableCell>
                        <TableCell>Poziom rozwoju funkcji w %</TableCell>
                        <TableCell>Liczba punktów zdobytych</TableCell>
                        <TableCell>Liczba punktów do zdobycia</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.props.data.map((section: Section, i: number) =>
                        <>
                            <TableRow>
                                <TableCell colSpan={5}><Typography variant={"h6"}>{section.section}</Typography></TableCell>
                            </TableRow>
                            {section.categories.map((category, index) =>
                                <TableRow>
                                    <TableCell>{category.name}</TableCell>
                                    <TableCell><Typography variant="subtitle2">{this.getLabelBasedOnResult(category.result)}</Typography></TableCell>
                                    <TableCell><Typography variant="subtitle2">{Math.round(category.result * 100)}%</Typography></TableCell>
                                    <TableCell>{category.score}</TableCell>
                                    <TableCell>{category.max}</TableCell>
                                </TableRow>
                            )}
                        </>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    }

    private getLabelBasedOnResult(result: number): string {
        if (result <= 0.2) {
            return "Bardzo niski"
        }

        if (result <= 0.35) {
            return "Niski"
        }

        if (result <= 0.65) {
            return "Przeciętny"
        }

        if (result <= 0.79) {
            return "Wysoki"
        }

        return "Bardzo wysoki";
    }
}