import {Metadata} from 'lib/dto'

export type Student = {
    id: string
    no: number
    identifier?: string
    firstName: string
    lastName: string
    birthDate: string
    age: number
    educationStartDate: string
    hasPharmacotherapy: boolean
    pharmacotherapyDescription: string
    comorbidities: string
    hasDiet: boolean
    dietDescription: string
    hasSiblings: boolean
    status: string
    category: string
    school: string
    disabilities: string[]
    siblingsDisabilities: string[]
    groups: string[]
    individualTeachers: string[]
    individualClasses: string[]
    parents: string[]
    metadata?: Metadata
}

export const newStudent = (): Student => ({
    id: '',
    no: 0,
    firstName: '',
    lastName: '',
    birthDate: '',
    age: 0,
    educationStartDate: '',
    hasPharmacotherapy: false,
    pharmacotherapyDescription: '',
    comorbidities: '',
    hasDiet: false,
    dietDescription: '',
    hasSiblings: false,
    status: 'ONGOING',
    category: '',
    school: '',
    disabilities: [],
    siblingsDisabilities: [],
    groups: [],
    individualTeachers: [],
    individualClasses: [],
    parents: [],
})
