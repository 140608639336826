import {Box, Table, TableBody, TableCell, TableHead, TableRow, TextField} from "@material-ui/core";
import * as React from 'react'

type SectionProps = {
    section: KorpTestSection[]
    preview?: boolean
}

type SectionState = {
    section: KorpTestSection[]
}

export type KorpTestSection = {
    score: string
    groups: string[]
    category: string
    required: boolean
    description: string
}

export class Generic3FieldSection extends React.Component<SectionProps, SectionState> {
    state: SectionState = {
        section: this.props.section
    }

    public handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let keys = event.target.name.split('|')

        switch (keys[1]) {
            case "score":
                this.state.section[parseInt(keys[0])].score = event.target.value.replace(',', '.')
                break
            case "description":
                this.state.section[parseInt(keys[0])].description = event.target.value
                break
        }

        this.setState(prevState => {
            return {...prevState, section: this.state.section}
        })
    }

    public render = () => <Table>
        <TableHead>
            <TableRow>
                <TableCell style={{minWidth: '250px'}}>Kategoria</TableCell>
                <TableCell style={{minWidth: '150px'}}>Wynik</TableCell>
                <TableCell style={{width: '100%'}}>Opis</TableCell>
            </TableRow>
        </TableHead>
        <TableBody className={'gray-striped'}>
            {this.state.section.map(
                (item, index) =>
                    <TableRow key={index}>
                        <TableCell>
                            {this.props.preview && item.required && item.score == '0' ?
                                <Box style={{color: 'red'}}>{item.category}</Box> : item.category}
                        </TableCell>
                        <TableCell>
                            <TextField name={index + "|score"}
                                       disabled={this.props.preview || false}
                                       value={item.score} onChange={this.handleChange}
                                       variant="outlined"
                                       inputProps={{ inputMode: 'numeric' }}
                            />
                        </TableCell>
                        <TableCell>
                            <TextField name={index + "|description"} disabled={this.props.preview || false}
                                       value={item.description} onChange={this.handleChange} multiline
                                       variant="outlined" style={{width: '100%'}}/>
                        </TableCell>
                    </TableRow>,
                this
            )}
        </TableBody>
    </Table>
}