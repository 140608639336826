import {Enum, Preview} from 'app/Components'
import {Source} from 'lib/select'
import {TestConfig} from 'lib/testConfig'
import {name} from 'lib/testType'
import * as React from 'react'

type TestConfigPreviewProps = {
    id: string,
}

export class TestConfigPreview extends React.Component<TestConfigPreviewProps> {
    public render = () => <Preview
        value={this.props.id}
        source={Source.TEST_CONFIG}
        template={(config: TestConfig) => config.name}
    />
}
