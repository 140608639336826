import {Box} from '@material-ui/core'
import {CrudList, FilterType, RolesEnum, SchoolPreview, Status, UserLabel} from 'app/Components'
import {rules} from 'lib/auth'
import {Dictionaries} from 'lib/dictionaries'
import {Source} from 'lib/select'
import {State} from 'lib/store'
import {name, User} from 'lib/users'
import * as React from 'react'

const cols = [
    {
        id: "user",
        numeric: false,
        label: "Użytkownik",
        sort: false,
        template: (user: User) => <UserLabel user={user} />
    },
    {
        id: "contact",
        numeric: false,
        label: "Kontakt",
        sort: false,
        width: '30%',
        template: (user: User) => (<>
            <Box>e-mail: {user.email}</Box>
            {user.phone ? <Box>tel: {user.phone}</Box> : null}
        </>)
    },
    {
        id: "school",
        numeric: false,
        label: "Placówka",
        sort: false,
        width: '30%',
        template: (user: User) => <SchoolPreview id={user.school} />
    },
    {
        id: "roles",
        numeric: false,
        label: "Rola",
        sort: false,
        width: '70px',
        template: (user: User) => <RolesEnum ids={user.roles} />
    },
    {
        id: "active",
        numeric: false,
        label: "Status",
        sort: false,
        width: '50px',
        template: (user: User) => <Status status={user.active} activeLabel={'Aktywny'} inactiveLabel={'Nieaktywny'}/>,
        rule: rules.user.status
    },
]

export class List extends React.Component {
    public render = () => (
        <CrudList
            actionName={name}
            cols={cols}
            selector={(state: State) => state.users}
            deleteDialogText="Usunąć użytkownika?"
            rules={rules.user}
            filter={{
                initialValues: {search: '', school: [], roles: [], specialties: [], qualifications: [], active: '1'},
                fields: [
                    {name: 'search', label: 'Imię / nazwisko / e-mail', type: FilterType.TEXT},
                    {name: 'school', label: 'Placówki', type: FilterType.MULTIPLE_SELECT, source: Source.SCHOOLS},
                    {name: 'specialties', label: 'Specjalizacje', type: FilterType.MULTIPLE_SELECT, source: Source.DICTIONARIES, requestOptions: {type: Dictionaries.Specialty} },
                    {name: 'qualifications', label: 'Kwalifikacje', type: FilterType.MULTIPLE_SELECT, source: Source.DICTIONARIES, requestOptions: {type: Dictionaries.Qualification} },
                    {name: 'roles', label: 'Rola', type: FilterType.MULTIPLE_SELECT, source: Source.ROLES},
                    {
                        name: 'active',
                        label: 'Status',
                        type: FilterType.SELECT,
                        source: Source.STATUS,
                        requestOptions: {type: Source.USERS},
                        rule: rules.user.status
                    }
                ]
            }}
        />
    )
}
