import {configuration} from 'lib/client'
import {SubjectApi} from "client/apis";

const SubjectApiClient = (): SubjectApi => {
    return new SubjectApi(configuration)
}

export const dao = {
    list: (payload: any) => {
        return SubjectApiClient().getSubjectCollection(payload)
    },
    get: (payload: any) => {
        return SubjectApiClient().getSubjectItem({id: payload.id})
    },
    create: (payload: any) => {
        return SubjectApiClient().postSubjectCollection({subjectWrite: payload})
    },
    update: (payload: any) => {
        return SubjectApiClient().putSubjectItem({id: payload.id, subjectWrite: payload.data})
    },
    delete: (payload: any) => {
        return SubjectApiClient().deleteSubjectItem({id: payload.id})
    }
}