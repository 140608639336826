import * as React from 'react'
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux'
import {Message, NotificationsAction} from 'lib/notifications'
import {State} from 'lib/store'
import {getMessage, isOpen} from 'lib/notifications'
import {OptionsObject, SnackbarMessage, withSnackbar} from 'notistack'

type StateToProps = {
    readonly open: boolean
    readonly message: Message
}

type DispatchToProps = {
    readonly close: () => void,
}

type NotificationProps = StateToProps & DispatchToProps & {
    enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => void
}

class NotificationComponent extends React.Component<NotificationProps> {
    componentDidUpdate(prevProps: Readonly<NotificationProps>, prevState: Readonly<{}>, snapshot?: any) {
        if (!prevProps.open && this.props.open) {
            this.props.enqueueSnackbar(this.props.message.message, this.props.message.options)
            this.props.close()
        }
    }

    public render() {
        return <></>
    }
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    open: isOpen(state),
    message: getMessage(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    close: () => {
        dispatch(NotificationsAction.hide())
    }
})

export const Notification = withSnackbar(connect(mapStateToProps, mapDispatchToProps)(NotificationComponent))