import {Metadata} from 'lib/dto'

export type User = {
    id: string
    identifier?: string
    firstName: string
    lastName: string
    login: string
    phone: string
    email: string
    school: string
    active: boolean
    roles: string[]
    specialties: string[]
    qualifications: string[]
    groups: string[]
    children: string[],
    lastSeenAt: string | null
    password: string
    worksSince: string
    metadata?: Metadata
}

export const newUser = (): User => ({
    id: '',
    firstName: '',
    lastName: '',
    login: '',
    phone: '',
    email: '',
    school: '',
    active: false,
    roles: [],
    specialties: [],
    qualifications: [],
    groups: [],
    children: [],
    lastSeenAt: null,
    worksSince: '',
    password: ''
})

export type Profile = {
    id: string
    firstName: string
    lastName: string
    login: string
    phone: string
    email: string
}

export const newProfile = (user: User): Profile => ({
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    login: user.login,
    phone: user.phone,
    email: user.email
})