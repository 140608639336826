import DateFnsUtils from '@date-io/date-fns'
import {CssBaseline} from '@material-ui/core'
import {StylesProvider, ThemeProvider as MuiThemeProvider} from '@material-ui/core/styles'
import {MuiPickersUtilsProvider} from '@material-ui/pickers'
import {CircularProgressBar} from 'app/Components'
import {Layout} from 'app/Layout'
import {Layout as AuthLayout} from 'app/Views/Auth/Layout'
import {ConnectedRouter} from 'connected-react-router'
import {pl} from 'date-fns/locale'
import {AuthAction, isAuthenticated, isInitialized, me} from 'lib/auth'
import {history} from 'lib/router'
import {State} from 'lib/store'
import {selectTheme, Theme} from 'lib/theme'
import {User} from 'lib/users'
import {SnackbarProvider} from 'notistack'
import React from 'react'
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux'
import styled, {createGlobalStyle, ThemeProvider} from 'styled-components'

type ThemeProps = {
    readonly theme: Theme,
}

const GlobalStyle = createGlobalStyle<ThemeProps>`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${props => props.theme.body.background};
  }
  
  thead > tr > th {
    border-bottom-width: 2px !important;
  }
  
  .gray-striped tr:nth-of-type(even) {
    background: rgba(250,250,250,1);
  }
  .gray-striped tr:nth-of-type(even)&:hover {
    background: rgba(245,245,245,1);
  }
  .gray-striped tr:nth-of-type(odd)&:hover {
    background: rgba(245,245,245,1);
  }
`

const Container = styled.div`
  display: flex;
  min-height: 100vh;
`

type StateToProps = {
    readonly theme: Theme
    readonly isInitialized: boolean
    readonly isAuthenticated: boolean
    readonly me: User | null
}

type DispatchToProps = {
    readonly initialize: () => void,
}

type RootProps = StateToProps & DispatchToProps

type RootState = {
    mobileOpen: boolean
}

class RootComponent extends React.Component<RootProps, RootState> {
    state: RootState = {
        mobileOpen: false
    }

    public componentDidMount(): void {
        if (!this.props.isInitialized) {
            this.props.initialize()
        }
    }

    public render() {
        return (
            <Container>
                <StylesProvider injectFirst>
                    <MuiPickersUtilsProvider locale={pl} utils={DateFnsUtils}>
                        <MuiThemeProvider theme={this.props.theme}>
                            <ThemeProvider theme={this.props.theme}>
                                <CssBaseline/>
                                <GlobalStyle theme={this.props.theme}/>
                                <ConnectedRouter history={history}>
                                    <SnackbarProvider maxSnack={3} preventDuplicate={true} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                                        {this.props.isInitialized
                                            ? (this.props.isAuthenticated
                                                ? (this.props.me ? <Layout /> : <CircularProgressBar />)
                                                : <AuthLayout />)
                                            : <CircularProgressBar />}
                                    </SnackbarProvider>
                                </ConnectedRouter>
                            </ThemeProvider>
                        </MuiThemeProvider>
                    </MuiPickersUtilsProvider>
                </StylesProvider>
            </Container>
        )
    }
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    theme: selectTheme(state),
    isInitialized: isInitialized(state),
    isAuthenticated: isAuthenticated(state),
    me: me(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    initialize: () => {
         dispatch(AuthAction.initialize())
    },
})

export const Root = connect(mapStateToProps, mapDispatchToProps)(RootComponent)

