import {SummaryRow} from "./dto";

type TestSummary = {
    testId: string,
    testConfigId: string,
    publishedAt: string,
    summary: {
        data: { [key: string]: any }
    }
}

export const transformSummary = (data: any) => {
    let rows: { [key: string]: SummaryRow[] } = {}
    data.map((t: TestSummary) => {
        Object.entries(t.summary.data || {}).map((section) => {
            if (rows[section[0]] == undefined) {
                rows[section[0]] = []
            }

            if (section[1].score !== undefined) {
                rows[section[0]].push({
                    testId: t.testId,
                    testConfigId: t.testConfigId,
                    publishedAt: t.publishedAt,
                    score: section[1].score,
                    items: section[1].items,
                })
            } else if(typeof section[1][0] == 'string') {
                let items: string[] = section[1].filter((item: string) => item != '')
                if (items.length) {
                    rows[section[0]].push({
                        testId: t.testId,
                        testConfigId: t.testConfigId,
                        publishedAt: t.publishedAt,
                        score: 0,
                        items: items,
                    })
                }
            } else if(section[1][0] && typeof section[1][0].score !== undefined) {
                section[1].map((s: {score: string, description: string}) => {
                    if (s.description != '' && s.description != undefined) {
                        rows[section[0]].push({
                            testId: t.testId,
                            testConfigId: t.testConfigId,
                            publishedAt: t.publishedAt,
                            score: s.score,
                            items: [s.description],
                        })
                    }
                })
            } else {
                console.log(section[1], (typeof section[1][0]))
            }
        })
    })

    return Object.entries(rows);
}