import {Test} from 'lib/test'
import * as React from 'react'
import {CircularProgressBar} from "app/Components/ProgressBar";
import {KbmaForm} from "app/Views/Tests/KBMA/KbmaForm";

type PreviewProps = {
    test: Test
}

export class KbmaRawData extends React.Component<PreviewProps> {
    public render = () => <>
        {this.props.test && this.props.test.content ?
            <KbmaForm data={this.props.test.content.data} preview={true} /> : <CircularProgressBar/>
        }
    </>
}