import {Role as RoleEnum} from 'lib/auth'
import {ClassDuration} from 'lib/classDuration'
import {Dictionary} from 'lib/dictionaries'
import {EducationStatus} from 'lib/educationStatus'
import {Event} from 'lib/events'
import {Group} from 'lib/groups'
import {Role} from 'lib/roles'
import {School} from 'lib/schools'
import {SchoolType} from 'lib/schoolType'
import {Status} from 'lib/status'
import {StudentCategory} from 'lib/studentCategory'
import {StudentFileType} from 'lib/studentFileTypes'
import {Student} from 'lib/students'
import {Subject} from 'lib/subjects'
import {SubjectCategory} from 'lib/subjectsCategory'
import {User} from 'lib/users'
import {Age} from "../age";
import {Option, Source} from './dto'
import {TestConfig} from "lib/testConfig";
import {PER_PAGE} from "./vars";
export const transformPayload = (source: string, payload: any): any => {
    const request = {
        page: 1,
        itemsPerPage: PER_PAGE
    }

    switch(source) {
        case Source.PARENTS:
            return transformParentsPayload(request, payload)
        case Source.TEACHERS:
            return transformTeachersPayload(request, payload)
        case Source.SPECIALISTS:
            return transformSpecialistsPayload(request, payload)
        case Source.SCHOOLS:
            return transformSchoolPayload(request, payload)
        case Source.DICTIONARIES:
            return transformDictionaryPayload(request, payload)
        case Source.STATUS:
            return transformStatusPayload(request, payload)
        case Source.USERS:
            return transformUsersPayload(request, payload)
        default:
            return defaultTransformation(request, payload)
    }
}

export const transformResponse = (source: string, response: any): Option[] => {
    switch(source) {
        case Source.SCHOOLS:
            return response.items.map((school: School) => (<Option>{value: school.id, label: school.name}))
        case Source.SUBJECTS_CATEGORY:
            return response.items.map((subjectCategory: SubjectCategory) => (<Option>{value: subjectCategory.id, label: subjectCategory.value}))
        case Source.ROLES:
            return response.items.map((role: Role) => (<Option>{value: role.id, label: role.value}))
        case Source.DICTIONARIES:
            return response.items.map((dictionary: Dictionary) => (<Option>{value: dictionary.id, label: dictionary.name}))
        case Source.USERS:
        case Source.PARENTS:
        case Source.TEACHERS:
        case Source.SPECIALISTS:
            return response.items.map((user: User) => (<Option>{value: user.id, label: '(' + user.identifier + ') ' + user.lastName + ' ' + user.firstName}))
        case Source.GROUPS:
            return response.items.map((group: Group) => (<Option>{value: group.id, label: group.name}))
        case Source.STUDENTS_CATEGORY:
            return response.items.map((studentCategory: StudentCategory) => (<Option>{value: studentCategory.id, label: studentCategory.value}))
        case Source.EDUCATION_STATUS:
            return response.items.map((schoolType: SchoolType) => (<Option>{value: schoolType.id, label: schoolType.value}))
        case Source.SCHOOL_TYPE:
            return response.items.map((educationStatus: EducationStatus) => (<Option>{value: educationStatus.id, label: educationStatus.value}))
        case Source.EVENTS:
            return response.items.map((event: Event) => (<Option>{value: event.id, label: event.name}))
        case Source.STATUS:
            return response.map((status: Status) => (<Option>{value: status.id, label: status.value}))
        case Source.AGE:
            return response.map((age: Age) => (<Option>{value: age.value, label: age.label}))
        case Source.STUDENT_FILE_TYPES:
            return response.items.map((studentFileType: StudentFileType) => (<Option>{value: studentFileType.id, label: studentFileType.value}))
        case Source.SUBJECTS:
            const categories: {[key: string]: string} = {
                "PSYCHO_PED_AID": "Pomoc psychologiczno pedagogiczna",
                "WWR" : "Zajęcia na WWR",
                "REVALIDATION": "Zajęcia rewalidacyjne",
            }

            return response.items.map((subject: Subject) => (<Option>{value: subject.id, label: subject.name + ' (' + categories[subject.category] + ')'}))
        case Source.CLASS_DURATION:
            return response.items.map((classDuration: ClassDuration) => (<Option>{value: classDuration.id, label: classDuration.value}))
        case Source.STUDENTS:
            return response.items.map((student: Student) => (<Option>{value: student.id, label: '(' + student.identifier + ') ' + student.lastName + ' ' + student.firstName + ' (wiek: ' + student.age + ')'}))
        case Source.TEST_CONFIG:
            return response.items.map((testConfig: TestConfig) => (<Option>{value: testConfig.id, label: testConfig.name}))
        case Source.TEST_FOR_ANALYSIS:
            return response.map((testConfig: TestConfig) => (<Option>{value: testConfig.id, label: testConfig.name}))
    }

    return []
}

const transformParentsPayload = (request: any, payload: any) => {
    return {
        ...request,
        roles: [RoleEnum.ROLE_PARENT],
        active: true,
        orderLastName: 'asc',
    }
}

const transformTeachersPayload = (request: any, payload: any) => {
    return {
        ...request,
        roles: [RoleEnum.ROLE_TEACHER, RoleEnum.ROLE_SPECIALIST],
        active: true,
        orderLastName: 'asc',
    }
}

const transformSpecialistsPayload = (request: any, payload: any) => {
    return {
        ...request,
        roles: [RoleEnum.ROLE_SPECIALIST],
        active: true,
        orderLastName: 'asc',
    }
}

const transformSchoolPayload = (request: any, payload: any) => {
    return defaultTransformation(request, payload)
}

const transformDictionaryPayload = (request: any, payload: any) => {
    request = {...request, orderPosition: 'asc', active: true}

    if ('ids' in payload) {
        return {data: {...request, id: payload.ids}, type: payload.type}
    }
    if ('query' in payload) {
        return {data: {...request, name: payload.query}, type: payload.type}
    }

    return request
}

const transformStatusPayload = (request: any, payload: any) => {
    return {type: payload.type}
}

const transformUsersPayload = (request: any, payload: any) => {
    if (payload.school != '') {
        return {...defaultTransformation(request, payload), school: payload.school}
    }

    return defaultTransformation(request, payload)
}

const defaultTransformation = (request: any, payload: any) => {
    request = {...request, orderName: 'asc', active: true}

    if ('ids' in payload) {
        return {...request, id: payload.ids}
    }
    if ('query' in payload) {
        return {...request, name: payload.query}
    }
    if ('page' in payload) {
        return {...request, page: payload.page}
    }

    return request
}
