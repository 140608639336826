import * as React from 'react'
import {Box, Checkbox, FormControlLabel, Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core'

type PreviewProps = {
    data: Data
    preview: boolean
    sectionIndex?: number
}

type SectionState = {
    data: Data
}

interface General {
    groups: string[];
    result?: any;
    category: string;
    required: boolean;
}

interface Item {
    groups: string[];
    result?: any;
    category: string;
    required: boolean;
}

interface AgeRelated {
    age: string;
    items: Item[];
}

interface Data {
    general: General[];
    ageRelated: AgeRelated[];
}

export class KodwkmzForm extends React.Component<PreviewProps, SectionState> {
    state: SectionState = {
        data: this.props.data
    }

    public handleGeneralItemChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const key = parseInt(event.target.name);
        this.state.data.general[key].result = event.target.checked;
        this.setState(prevState => {
            return {...prevState, data: this.state.data}
        })
    }

    public handleAgeRelatedItemChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const keys = event.target.name.split('|')
        const ageKey = parseInt(keys[0]);
        const ageItemKey = parseInt(keys[1]);
        this.state.data.ageRelated[ageKey].items[ageItemKey].result = event.target.checked;
        this.setState(prevState => {
            return {...prevState, data: this.state.data}
        })
    }

    public render = () => <>
        {this.props.data ?
            <>
                {this.props.sectionIndex == 0 || this.props.sectionIndex == undefined ? <>
                        <Table style={{marginBottom: "50px"}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{whiteSpace: "nowrap"}}>Kategoria</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className={'gray-striped'}>
                                {this.props.data.general.map(
                                    (generalItem, index) =>
                                        <TableRow key={index}>
                                            <TableCell style={this.props.preview && generalItem.required && generalItem.result == null ? {color: 'red'} : {}}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            name={index.toString()}
                                                            checked={generalItem.result == true}
                                                            onChange={this.handleGeneralItemChange}
                                                            disabled={this.props.preview || false}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={generalItem.category}
                                                />
                                            </TableCell>
                                        </TableRow>,
                                )}
                            </TableBody>
                        </Table>
                    </>
                    : ''}
                {this.props.sectionIndex == 1 || this.props.sectionIndex == undefined ? <>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{whiteSpace: "nowrap"}}>Miesiąc życia</TableCell>
                                    <TableCell style={{whiteSpace: "nowrap"}}>Oczekiwane osiągnięcia dziecka w kolejnych miesiącach życia</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className={'gray-striped'}>
                                {this.props.data.ageRelated.map(
                                    (ageRelatedItem, month) =>
                                        <TableRow key={month}>
                                            <TableCell style={{whiteSpace: "nowrap"}}>
                                                {ageRelatedItem.age}
                                            </TableCell>
                                            <TableCell style={{whiteSpace: "nowrap"}}>
                                                {ageRelatedItem.items.map(
                                                    (item, index) => <Box
                                                        key={index}
                                                        style={this.props.preview && item.required && item.result == null ? {color: 'red'} : {}}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    name={month + '|' + index}
                                                                    checked={item.result == true}
                                                                    onChange={this.handleAgeRelatedItemChange}
                                                                    disabled={this.props.preview || false}
                                                                    color="primary"
                                                                />
                                                            }
                                                            label={item.category}
                                                        />
                                                    </Box>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </>
                    : ''}
            </>
            : ''}
    </>
}