import {Box} from '@material-ui/core'
import Link from '@material-ui/core/Link'
import {Enum} from 'app/Components'
import {RouteType} from 'lib/crud'
import {generateCrudPath} from 'lib/router'
import {name, School} from 'lib/schools'
import * as React from 'react'
import {NavLink} from 'react-router-dom'
import {name as schoolTypeName} from 'lib/schoolType'

type SchoolLabelProps = {
    school: School
}

export class SchoolLabel extends React.Component<SchoolLabelProps> {
    public render = () => (<>
        <Link component={NavLink} to={generateCrudPath(name, RouteType.SHOW, {id: this.props.school.id})}>
            {this.props.school.name}
        </Link>
        <Box>({this.props.school.identifier})</Box>
        <Enum name={schoolTypeName} id={this.props.school.type} />
    </>)
}