import {of} from 'rxjs'
import {Age} from './dto'

export const dao = {
    list: (payload: any) => {
        return of(<Age[]>[
            {value: '', label: 'Dowolny'},
            {value: '3', label: '3'},
            {value: '4', label: '4'},
            {value: '5+', label: '5+'}
        ])
    },
}