import * as React from 'react'
import {OdfSurveySection, OdfTestSurveySection, OdfDescriptionSection} from ".";

type PreviewProps = {
    data: any
    preview: boolean
    sectionIndex?: number
}

export class OdfForm extends React.Component<PreviewProps> {
    public render = () => <>
        {this.props.data ? this.props.data.map(
            (section: OdfTestSurveySection, index: number) => {
                if ((index == this.props.sectionIndex && this.props.sectionIndex != this.props.data.length - 1) || (this.props.sectionIndex == undefined && index != this.props.data.length - 1))
                    return <OdfSurveySection
                        section={section}
                        key={index}
                        index={index}
                        preview={this.props.preview}
                        prev={index == 0 ? null : index - 1}
                        next={index == this.props.data.length - 1 ? null : index + 1}
                    />

                if ((index == this.props.sectionIndex && this.props.sectionIndex == this.props.data.length - 1) || (this.props.sectionIndex == undefined && index == this.props.data.length - 1))
                    return <OdfDescriptionSection
                        section={section}
                        key={index}
                        index={index}
                        preview={this.props.preview}
                        prev={index == 0 ? null : index - 1}
                        next={index == this.props.data.length - 1 ? null : index + 1}
                    />

                return null
            }
            ,
            this
        ) : ''}
    </>
}