import {Preview, StudentLabel} from 'app/Components'
import {Source} from 'lib/select'
import {Student} from 'lib/students'
import * as React from 'react'
import {connect} from 'react-redux'

type StudentPreviewProps = {
    id: string,
}

class StudentPreviewComponent extends React.Component<StudentPreviewProps> {
    public render = () =>
        <Preview
            value={this.props.id}
            source={Source.STUDENTS}
            template={(student: Student) => <StudentLabel student={student}/>}
        />
}

export const StudentPreview = connect()(StudentPreviewComponent)