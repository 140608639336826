import {User} from 'lib/users'

export const isGranted = (rule: AccessRule, me: User): boolean => {
    return me!.roles.filter(a => rule.includes(a)).length > 0
}

export enum Role {
    ROLE_ADMIN = 'ROLE_ADMIN',
    ROLE_SCHOOL_ADMIN = 'ROLE_SCHOOL_ADMIN',
    ROLE_TEACHER = 'ROLE_TEACHER',
    ROLE_PSYCHOLOGIST = 'ROLE_PSYCHOLOGIST',
    ROLE_SPECIALIST = 'ROLE_SPECIALIST',
    ROLE_PARENT = 'ROLE_PARENT'
}

export type AccessRule = string[]

export const rules = {
    start: {
        list: [Role.ROLE_ADMIN, Role.ROLE_SCHOOL_ADMIN, Role.ROLE_PSYCHOLOGIST, Role.ROLE_SPECIALIST, Role.ROLE_TEACHER],
    },
    user: {
        list: [Role.ROLE_ADMIN, Role.ROLE_SCHOOL_ADMIN],
        status: [Role.ROLE_ADMIN, Role.ROLE_SCHOOL_ADMIN],
        add: [Role.ROLE_ADMIN, Role.ROLE_SCHOOL_ADMIN],
        lastSeenAt: [Role.ROLE_ADMIN, Role.ROLE_SCHOOL_ADMIN],
    },
    school: {
        list: [Role.ROLE_ADMIN, Role.ROLE_SCHOOL_ADMIN, Role.ROLE_PSYCHOLOGIST, Role.ROLE_SPECIALIST, Role.ROLE_TEACHER],
        status: [Role.ROLE_ADMIN],
        add: [Role.ROLE_ADMIN],
    },
    student: {
        status: [Role.ROLE_ADMIN, Role.ROLE_SCHOOL_ADMIN],
        add: [Role.ROLE_ADMIN, Role.ROLE_SCHOOL_ADMIN],
    },
    studentParents: {
        show: [],
        add: [Role.ROLE_ADMIN, Role.ROLE_SCHOOL_ADMIN],
    },
    historyEntries: {
        show: [],
    },
    studentFiles: {
        show: [],
        deleteOnly: [Role.ROLE_ADMIN, Role.ROLE_SCHOOL_ADMIN, Role.ROLE_TEACHER, Role.ROLE_SPECIALIST, Role.ROLE_PSYCHOLOGIST],
        needConfirmationToDownload: [Role.ROLE_PARENT],
        canDownload: [Role.ROLE_ADMIN, Role.ROLE_SCHOOL_ADMIN, Role.ROLE_TEACHER, Role.ROLE_SPECIALIST, Role.ROLE_PSYCHOLOGIST],
        add: [Role.ROLE_ADMIN, Role.ROLE_SCHOOL_ADMIN, Role.ROLE_PSYCHOLOGIST, Role.ROLE_SPECIALIST, Role.ROLE_TEACHER],
    },
    group: {
        list: [Role.ROLE_ADMIN, Role.ROLE_SCHOOL_ADMIN, Role.ROLE_TEACHER, Role.ROLE_PSYCHOLOGIST, Role.ROLE_SPECIALIST],
        status: [Role.ROLE_ADMIN, Role.ROLE_SCHOOL_ADMIN],
        add: [Role.ROLE_ADMIN, Role.ROLE_SCHOOL_ADMIN],
    },
    groupClasses: {
        show: [],
    },
    groupChildren: {
        show: [],
    },
    groupTeachers: {
        show: [],
    },
    subject: {
        list: [Role.ROLE_ADMIN, Role.ROLE_SCHOOL_ADMIN],
        status: [Role.ROLE_ADMIN, Role.ROLE_SCHOOL_ADMIN],
        add: [Role.ROLE_ADMIN, Role.ROLE_SCHOOL_ADMIN],
        show: []
    },
    dictionary: {
        list: [Role.ROLE_ADMIN, Role.ROLE_SCHOOL_ADMIN],
        show: [Role.ROLE_ADMIN, Role.ROLE_SCHOOL_ADMIN],
    },
    testConfig: {
        list: [Role.ROLE_ADMIN, Role.ROLE_SCHOOL_ADMIN],
        status: [Role.ROLE_ADMIN],
        add: [],
        show: [],
    },
    analysis: {
        list: [Role.ROLE_ADMIN],
        school: [Role.ROLE_ADMIN],
    }
}