import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core'
import * as React from 'react'

type PreviewProps = {
    data: any
}

export class ClameisrResults extends React.Component<PreviewProps> {
    public render = () => <TableContainer>
        <Table className={'gray-striped'}>
            <TableHead>
                <TableRow>
                    <TableCell>Rutyna</TableCell>
                    <TableCell align={"right"}>% pozycji zgodny z wiekiem</TableCell>
                    <TableCell align={"right"}>% pozycji dla danej rutyny</TableCell>
                    <TableCell align={"right"}>Liczba pozycji z 3 pkt.</TableCell>
                    <TableCell align={"right"}>Liczba pozycji dla wieku dziecka</TableCell>
                    <TableCell align={"right"}>Liczba pozycji dla danej rutyny</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {Object.entries(this.props.data).map((section: any, i: number) => <TableRow key={i}>
                    <TableCell style={i == 13 ? {'fontWeight': 'bold'} : {}}>
                        {i == 13 ? '' : (i + 1) + '. '}{section[1].section}
                    </TableCell>
                    <TableCell align={"right"} style={{fontWeight: 'bold'}}>{Math.round(section[1].scoreWithAge * 100)}</TableCell>
                    <TableCell style={i == 13 ? {'fontWeight': 'bold'} : {}} align={"right"}>{Math.round(section[1].scoreWithoutAge * 100)}</TableCell>
                    <TableCell style={i == 13 ? {'fontWeight': 'bold'} : {}} align={"right"}>{section[1].threes}</TableCell>
                    <TableCell style={i == 13 ? {'fontWeight': 'bold'} : {}} align={"right"}>{section[1].positionsWithAge}</TableCell>
                    <TableCell style={i == 13 ? {'fontWeight': 'bold'} : {}} align={"right"}>{section[1].positionsWithoutAge}</TableCell>
                </TableRow>)}
            </TableBody>
        </Table>
    </TableContainer>
}