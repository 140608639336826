import {Box, FormControl, FormHelperText} from '@material-ui/core'
import {getOptions, getSelected, isLoading, Option, SelectAction} from 'lib/select'
import {State} from 'lib/store'
import * as React from "react";
import {CSSProperties} from "react";
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux'
import Select from 'react-select'
import 'external-lib/react-select/style.css'
import styled from "styled-components";
import {ValueContainer} from "react-select/animated";

type StateToProps = {
    options: Option[]
    selected: Option[]
    loading: boolean
}

type DispatchToProps = {
    add: () => void;
    remove: () => void;
    loadOptions: (query: string) => void,
    loadSelected: (ids: string[]) => void,
}

type AutocompleteProps = {
    label: string
    field: string
    source: string
    value: string | string[]
    setFieldValue: (field: string, value: any) => void
    error: boolean
    helperText?: string
    multiple?: boolean
    requestOptions?: any
    size?: 'small' | 'normal'
    isClearable?: boolean
}

type AutocompleteState = {
    selected: Option | Option[] | null
    options: Option[]
}

class AutocompleteComponent extends React.Component<AutocompleteProps & StateToProps & DispatchToProps, AutocompleteState> {
    state = {
        selected: this.props.multiple ? [] : null,
        options: this.props.options
    }

    public componentDidMount(): void {
        this.props.add();

        if (this.props.value === undefined) {
            return
        }

        this.props.loadOptions("")
        if (typeof this.props.value === 'string' && this.props.value != '') {
            this.props.loadSelected([this.props.value as string])
        }
        if (Array.isArray(this.props.value) && this.props.value.length > 0) {
            this.props.loadSelected(this.props.value as string[])
        }
    }

    public componentDidUpdate(prevProps: Readonly<AutocompleteProps & StateToProps & DispatchToProps>, prevState: Readonly<AutocompleteState>, snapshot?: any) {
        if (!this.props.loading && prevProps.loading) {
            this.setState(prevState => {
                return {...prevState, selected: this.getValue()}
            })
        }

        if (this.props.value !== prevProps.value && (this.props.value === null || this.props.value == '' || this.props.value == [])) {
            this.setState(prevState => {
                return {...prevState, selected: []}
            })
        }
    }

    public componentWillUnmount() {
        this.props.remove();
    }

    public onChange = (newValue: any) => {
        this.setState(prevState => {
            return {...prevState, selected: newValue}
        })

        if (newValue === null) {
            this.props.setFieldValue(this.props.field, null)
            return
        }

        this.props.setFieldValue(this.props.field, this.props.multiple ? (newValue as Option[]).map((option) => option.value) : newValue.value)
    }

    private getValue = () => {
        const filtered = this.props.selected.filter(option => this.props.value.includes(option.value))

        return this.props.multiple ? filtered : (filtered.pop() || null)
    }

    public render() {
        const customStyles = {
            control: () => ({
                display: "flex",
                alignItems: "center",
                border: 0,
                height: "auto",
                background: "transparent",
                padding: this.props.size == 'small' ? '0px 0px 0px 8px' : '8px'
            }),
            container: () => ({
                borderRadius: '4px',
                // border: 'solid 1px rgba(0, 0, 0, 0.23)',
                background: 'white',
                '&:hover': {
                    // borderColor: 'rgba(0, 0, 0, 0.87); !important',
                },
                "&:focus": {
                    // outline: '-webkit-focus-ring-color auto 1px'
                }
            }),
            valueContainer: (base: CSSProperties) => ({
                ...base,
                overflow: 'visible',
            }),
            indicatorsContainer: (base: CSSProperties) => ({
                ...base,
                padding: '0px'
            }),
            multiValue: (base: CSSProperties) => ({
                ...base,
                padding: this.props.size == 'small' ? '0px' : '5px',
                fontSize: '16px',
                borderRadius: '20px',
                margin: '0px',
                marginRight: '2px'
            }),
            menu: (base: CSSProperties) => ({
                ...base,
            //     backgroundColor: "white",
            //     boxShadow: "1px 2px 6px #888888", // should be changed as material-ui
            //     position: "absolute",
            //     left: 0,
            //     top: `calc(100% + 1px)`,
            //     width: "100%",
                zIndex: 2,
            }),
        }

        const CustomValueContainer = (props: any) => {
            return (
                <>
                    <Label isFloating={props.isFocused || props.hasValue} isSmall={this.props.size === 'small'}>{this.props.label}</Label>
                    <ValueContainer {...props} />
                </>
            );
        };

        const Label = styled.label<{ isFloating?: boolean, isSmall?: boolean}>`
          left: 0px;
          top: 0px;
          pointer-events: none;
          position: absolute;
          transition: 0.2s ease all;
          -moz-transition: 0.2s ease all;
          -webkit-transition: 0.2s ease all;
          z-index: 1;
          font-size: 14px;
          line-height: 1;
          transform-origin: top left;
          color: rgba(0, 0, 0, 0.54);
          background-color: white;
          padding-left: 4px;
          padding-right: 4px;
        
          transform: ${(props) => (props.isFloating ? `translate(14px,-8px) scale(0.75)` : `translate(14px,` + (props.isSmall ? `1em` : `1.5em`) + `) scale(1)`)}; 
       `;

        return (
                <Box className={this.props.error ? 'autocomplete__error' : 'autocomplete'}>
                    {this.state.options === [] ? null :
                        <Select
                            noOptionsMessage={() => 'Brak elementów do wyboru'}
                            isClearable={this.props.isClearable}
                            components={{ ValueContainer: CustomValueContainer }}
                            placeholder={""}
                            value={this.state.selected}
                            onChange={this.onChange}
                            options={this.props.options}
                            className={'MuiOutlinedInput'}
                            isMulti={this.props.multiple}
                            isLoading={this.props.loading}
                            styles={customStyles}
                            classNamePrefix={this.props.error ? 'error' : ''}
                        />}
                    {this.props.helperText ?
                        <FormHelperText variant="outlined">{this.props.helperText}</FormHelperText> : ''}
                </Box>
        )
    }
}

const mapStateToProps: MapStateToProps<StateToProps, AutocompleteProps, State> = (state, ownProps) => ({
    options: getOptions(ownProps.field)(state),
    selected: getSelected(ownProps.field)(state),
    loading: isLoading(ownProps.field)(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, AutocompleteProps> = (dispatch, props) => ({
    add: () => dispatch(SelectAction.add(props.field)),
    remove: () => dispatch(SelectAction.remove(props.field)),
    loadOptions: (query: string) => dispatch(SelectAction.loadOptions(props.field, props.source, query, props.requestOptions)),
    loadSelected: (ids: string[]) => dispatch(SelectAction.loadSelected(props.field, props.source, ids, props.requestOptions)),
})

export const Autocomplete = connect(mapStateToProps, mapDispatchToProps)(AutocompleteComponent)