import {Preview} from 'app/Components'
import {Dictionary} from 'lib/dictionaries'
import {Source} from 'lib/select'
import * as React from 'react'
import {connect} from 'react-redux'

type DisabilitiesPreviewProps = {
    ids: string[]
}

class DisabilitiesPreviewComponent extends React.Component<DisabilitiesPreviewProps> {
    public render = () => <Preview
        value={this.props.ids}
        source={Source.DISABILITIES}
        template={(dictionary: Dictionary) => <>{dictionary.name}</>}
    />
}

export const DisabilitiesPreview = connect()(DisabilitiesPreviewComponent)