import {
    CrudAction, dispatchAfterFailureEpic, dispatchAfterFilterEpic,
    dispatchAfterSuccessEpic,
    executeCrudEpic,
    redirectAfterSuccessEpic,
    RouteType,
    Type
} from 'lib/crud'
import {NotificationsAction} from 'lib/notifications'
import {generateCrudPath} from 'lib/router'
import {State} from 'lib/store'
import {dao, name} from 'lib/studentFiles'
import {combineEpics} from 'redux-observable'

export const studentFilesEpics = combineEpics(
    executeCrudEpic(name, Type.LIST)(dao.list),
    executeCrudEpic(name, Type.GET)(dao.get),
    executeCrudEpic(name, Type.CREATE)(dao.create),
    executeCrudEpic(name, Type.UPDATE)(dao.update),
    executeCrudEpic(name, Type.DELETE)(dao.delete),

    redirectAfterSuccessEpic(name, Type.CREATE, (action, state: State) => generateCrudPath(name, RouteType.LIST, {parentId: state.students.crud.row.id})),
    // redirectAfterSuccessEpic(name, Type.UPDATE, (action, state: State) => generateRouterCrudPath(name, RouteType.LIST)),

    dispatchAfterSuccessEpic(name, Type.CREATE, (action, state: State) => NotificationsAction.show({
        message: 'Dodano nowy dokument',
        options: {variant: "success"}
    })),

    dispatchAfterFailureEpic(name, Type.CREATE, (action, state: State) => NotificationsAction.show({
        message: 'Wystąpił błąd podczas dodawania nowego dokumentu',
        options: {variant: "error"}
    })),

    dispatchAfterSuccessEpic(name, Type.UPDATE, (action, state: State) => NotificationsAction.show({
        message: 'Dokument został odebrany',
        options: {variant: "success"}
    })),

    dispatchAfterFailureEpic(name, Type.UPDATE, (action, state: State) => NotificationsAction.show({
        message: 'Wystąpił błąd podczas potwierdzania odbioru dokumentu',
        options: {variant: "error"}
    })),

    dispatchAfterSuccessEpic(name, Type.DELETE, (action, state: State) => NotificationsAction.show({
        message: 'Dokument został usunięty',
        options: {variant: "success"}
    })),

    dispatchAfterFailureEpic(name, Type.DELETE, (action, state: State) => NotificationsAction.show({
        message: 'Wystąpił błąd podczas usuwania dokumentu',
        options: {variant: "error"}
    })),

    dispatchAfterSuccessEpic(name, Type.DELETE, (action, state: State) => CrudAction.execute(name, Type.LIST, {
        orderName: state.studentFiles.crud.listOptions.sortDirection,
        page: state.studentFiles.crud.listOptions.page + 1,
        itemsPerPage: state.studentFiles.crud.listOptions.pageSize,
        ...state.studentFiles.crud.filters,
    })),

    dispatchAfterSuccessEpic(name, Type.UPDATE, (action, state: State) => CrudAction.execute(name, Type.LIST, {
        orderName: state.studentFiles.crud.listOptions.sortDirection,
        page: state.studentFiles.crud.listOptions.page + 1,
        itemsPerPage: state.studentFiles.crud.listOptions.pageSize,
        ...state.studentFiles.crud.filters,
    })),

    dispatchAfterFilterEpic(name, (action, state: State) => CrudAction.execute(name, Type.LIST, {
        orderName: state.studentFiles.crud.listOptions.sortDirection,
        page: state.studentFiles.crud.listOptions.page + 1,
        itemsPerPage: state.studentFiles.crud.listOptions.pageSize,
        ...state.studentFiles.crud.filters,
    }))
)