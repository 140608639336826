import * as React from 'react'
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux'
import {Box, Button, FormControl, FormHelperText, TextField, Typography} from '@material-ui/core'
import {Link as RouterLink} from 'react-router-dom'
import {AuthAction, isSubmitting} from 'lib/auth'
import {Formik} from 'formik'
import * as Yup from 'yup'
import {LinearProgressBar} from "app/Components/ProgressBar";
import {State} from "lib/store";
import {Wrapper} from "app/Views/Auth/Wrapper";
import {Routes} from "lib/router";

type StateToProps = {
    isSubmitting: boolean
}

type DispatchToProps = {
    readonly handleLogin: (email: string, password: string) => void,
}

type SignInProps = StateToProps & DispatchToProps

const Schema = Yup.object().shape({
    email: Yup.string()
        .required('E-mail lub login jest wymagany do zalogowania'),
})

type SignInState = {
    authErrorMessage: string,
}

class SignInComponent extends React.Component<SignInProps, SignInState> {
    state: SignInState = {
        authErrorMessage: ''
    }

    componentDidUpdate(prevProps: Readonly<SignInProps>, prevState: Readonly<{}>, snapshot?: any) {
        if (prevProps.isSubmitting && !this.props.isSubmitting) {
            this.setState(prevState => ({...prevState, authErrorMessage: 'Nieprawidłowy login lub hasło. Spróbuj jeszcze raz lub kliknij „Przypomnij hasło”, by je zresetować.'}))
        }
    }

    public render() {
        return (
            <>
                <Box mb={2} mt={3}>
                    <LinearProgressBar isVisible={this.props.isSubmitting}/>
                    <Wrapper variant="outlined">
                        <Typography component="h1" variant="h4" align="center" gutterBottom>
                            Logowanie
                        </Typography>
                        <Formik
                            initialValues={{email: '', password: '', rememberMe: false}}
                            validationSchema={Schema}
                            validateOnChange={false}
                            onSubmit={(values) => {
                                this.props.handleLogin(values.email, values.password)
                            }}
                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleBlur,
                                  handleSubmit,
                                  /* and other goodies */
                              }) => (
                                <form onSubmit={handleSubmit}>
                                    <FormControl margin="normal" required fullWidth>
                                        <TextField
                                            error={Boolean((errors.email && touched.email) || this.state.authErrorMessage !== '')}
                                            label="E-mail / Login *"
                                            id="email"
                                            name="email"
                                            inputProps={{
                                                maxLength: 100,
                                            }}
                                            autoComplete="email"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                            variant="outlined"
                                            helperText={errors.email}
                                        />
                                    </FormControl>
                                    <FormControl margin="normal" required fullWidth>
                                        <TextField
                                            error={Boolean((errors.password && touched.password) || this.state.authErrorMessage !== '')}
                                            label="Hasło *"
                                            name="password"
                                            type="password"
                                            id="password"
                                            inputProps={{
                                                maxLength: 30,
                                            }}
                                            autoComplete="current-password"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.password}
                                            variant="outlined"
                                            helperText={errors.password}
                                        />
                                        {this.state.authErrorMessage !== '' ? <FormHelperText variant="outlined" error>{this.state.authErrorMessage}</FormHelperText> : ''}
                                    </FormControl>

                                    <Box mb={2} marginTop={3}>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            disabled={this.props.isSubmitting}
                                        >
                                            Zaloguj
                                        </Button>
                                    </Box>
                                    <Button
                                        fullWidth
                                        color="primary"
                                        component={RouterLink}
                                        to={Routes.AuthResetPassword}
                                    >
                                        Przypomnij hasło
                                    </Button>
                                </form>
                            )}
                        </Formik>
                    </Wrapper>
                </Box>
            </>
        )
    }
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    isSubmitting: isSubmitting(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    handleLogin: (email: string, password: string) => {
        dispatch(AuthAction.login({credentials: {login: email, password: password, remember: false}}))
    }
})


export const SignIn = connect(mapStateToProps, mapDispatchToProps)(SignInComponent)
