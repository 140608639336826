export const evaluationsTransformer = (payload: any): any => {
    return payload.map((test: any) => {
        if (test.evaluation.data['ZO']) {
            return {
                ...test,
                evaluation: {
                    age: '',
                    sections: [
                        {name: 'Ogólny poziom', score: Math.floor(test.evaluation.data['ZO'].general * 100)},
                        {name: 'Z dorosłym', score: Math.floor(test.evaluation.data['ZO'].adults * 100)},
                        {name: 'Z rzeczami', score: Math.floor(test.evaluation.data['ZO'].objects * 100)},
                        {name: 'Z rówieśnikami', score: Math.floor(test.evaluation.data['ZO'].peers * 100)},
                        {name: 'Jakość', score: Math.floor(test.evaluation.data['ZO'].quality * 100)},
                    ]
                }
            }
        }

        if (test.evaluation.data[0].categories) {
            return {
                ...test,
                evaluation: {
                    age: '',
                    sections: test.evaluation.data.flatMap((data: any) => {
                        return data.categories.map((category: any) => {
                            return {
                                name: category.name,
                                score: Math.floor(category.result * 100)
                            }
                        })
                    })
                }
            }
        }

        return {
            ...test,
            evaluation: {
                age: '',
                sections: test.evaluation.data.map((data: any) => {
                    return {
                        name: data.section,
                        score: Math.floor(data.scoreWithAge * 100),
                    }
                })
            }
        }
    })
}

export const comparisonTransformer = (payload: any): any => {
    return {
        students: payload.students.map((student: any) => mapStudent(student)),
        groups: payload.groups.map((group: any) => mapGroup(group)),
        schools: payload.schools.map((school: any) => mapSchool(school)),
    }
}

const mapStudent = (element: any): any => {
    return {
        id: element.student,
        evaluation: mapEvaluation(element)
    }
}

const mapGroup = (element: any): any => {
    return {
        id: element.group,
        evaluation: mapEvaluation(element)
    }
}

const mapSchool = (element: any): any => {
    return {
        id: element.school,
        evaluation: mapEvaluation(element)
    }
}

const mapEvaluation = (element: any): any => {
    if (element.evaluation && element.evaluation[0] && element.evaluation[0].age) {
        return element.evaluation.map((evaluation: { age: string | number, sections: { section: string, scoreWithAge: { avg: number } }[] }) => {
            return {
                age: evaluation.age + '',
                sections: evaluation.sections.map(section => {
                    return {
                        name: section.section,
                        score: Math.floor(section.scoreWithAge.avg * 100),
                    }
                })
            }
        })
    }

    if (element.evaluation && element.evaluation[0] && element.evaluation[0].activity) {
        return [{
            age: '',
            sections: element.evaluation.map((evaluation: { activity: string, score: { avg: number } }) => {
                return {
                    name: ((activity: string) => {
                        switch (activity) {
                            case 'general': return 'Ogólny poziom'
                            case 'adults': return 'Z dorosłym'
                            case 'peers': return 'Z rówieśnikami'
                            case 'objects': return 'Z rzeczami'
                            case 'quality': return 'Jakość'
                        }

                        return activity
                    })(evaluation.activity),
                    score: Math.floor(evaluation.score.avg * 100),
                }
            })
        }]
    }

    if (element.evaluation && element.evaluation[0] && element.evaluation[0].categories) {
        return [{
            age: '',
            sections: element.evaluation.flatMap((evaluation: { section: string, categories: { name: string, result: { avg: number } }[] }) => {
                return evaluation.categories.map(category => {
                    return {
                        name: category.name,
                        score: Math.floor(category.result.avg * 100)
                    }
                })
            })
        }]
    }

    return []
}