import {AnyAction} from 'redux'
import {NavigationActionType} from 'lib/navigation'

export type NavigationState = {
    header: string
    subheader: string
    action?: JSX.Element
}

export const initialNavigationState = {
    header: '',
    subheader: '',
}

export function navigationReducer(state: NavigationState = initialNavigationState, action: AnyAction): NavigationState {
    switch (action.type) {
        case NavigationActionType.setHeader():
            return {
                ...state,
                header: action.payload,
                subheader: ''
            }
        case NavigationActionType.setSubheader():
            return {
                ...state,
                subheader: action.payload,
            }
        case NavigationActionType.setAction():
            return {
                ...state,
                action: action.payload,
            }
        default:
            return state
    }
}
