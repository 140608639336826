import {Box} from '@material-ui/core'
import {CrudList, FilterType, SchoolLabel, Status, UsersPreview} from 'app/Components'
import {rules} from 'lib/auth'
import {name, School} from 'lib/schools'
import {Source} from 'lib/select'
import {State} from 'lib/store'
import * as React from 'react'

const cols = [
    {
        id: "name",
        numeric: false,
        label: "Placówka",
        sort: false,
        template: (school: School) => <SchoolLabel school={school} />
    },
    {
        id: "contact",
        numeric: false,
        label: "Dane kontaktowe",
        sort: false,
        width: '20%',
        template: (school: School) => (<>
            <Box>{school.street} {school.houseNumber}{school.flatNumber !== '' ? '/' + school.flatNumber : ''}</Box>
            <Box>{school.zipCode} {school.city}</Box>
            {school.phone !== '' ? 'tel: ' + school.phone : ''}
        </>)
    },
    {
        id: "principals",
        numeric: false,
        label: "Dyrektorzy",
        sort: false,
        width: '20%',
        template: (school: School) => <UsersPreview ids={school.principals} />
    },
    {
        id: "licenced",
        numeric: false,
        label: "Licencja",
        sort: false,
        width: '50px',
        template: (school: School) => <Status status={school.licensed} activeLabel={'Z licencją'} inactiveLabel={'Bez licencji'}/>
    },
    {
        id: "active",
        numeric: false,
        label: "Status",
        sort: false,
        width: '50px',
        template: (school: School) => <Status status={school.active} activeLabel={'Aktywna'} inactiveLabel={'Nieaktywna'}/>,
        rule: rules.school.status
    },
]

export class List extends React.Component {
    public render = () => (
        <CrudList
            actionName={name}
            cols={cols}
            selector={(state: State) => state.schools}
            deleteDialogText="Usunąć placówkę?"
            rules={rules.school}
            filter={{
                initialValues: {search: '', type: [], active: '1'},
                fields: [
                    {name: 'search', label: 'Ident. / adres / telefon / nazwa', type: FilterType.TEXT},
                    {name: 'type', label: 'Typ', type: FilterType.MULTIPLE_SELECT, source: Source.SCHOOL_TYPE},
                    {
                        name: 'active',
                        label: 'Status',
                        type: FilterType.SELECT,
                        source: Source.STATUS,
                        requestOptions: {type: Source.SCHOOLS},
                        rule: rules.school.status
                    }
                ]
            }}
        />
    )
}
