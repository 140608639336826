import {SchoolApi} from 'client/apis'
import {configuration} from 'lib/client'

const SchoolApiClient = (): SchoolApi => {
    return new SchoolApi(configuration)
}

export const dao = {
    list: (payload: any) => {
        return SchoolApiClient().getSchoolCollection(payload)
    },
    get: (payload: any) => {
        return SchoolApiClient().getSchoolItem({id: payload.id})
    },
    create: (payload: any) => {
        return SchoolApiClient().postSchoolCollection({schoolWrite: payload})
    },
    update: (payload: any) => {
        return SchoolApiClient().putSchoolItem({id: payload.id, schoolWrite: payload.data})
    },
    delete: (payload: any) => {
        return SchoolApiClient().deleteSchoolItem({id: payload.id})
    }
}