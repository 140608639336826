import * as React from 'react'
import {connect, MapStateToProps} from 'react-redux'
import {Source} from 'lib/select'
import {Group, name as groupsName} from 'lib/groups'
import {Link} from '@material-ui/core'
import {NavLink} from 'react-router-dom'
import {generateCrudPath} from 'lib/router'
import {RouteType} from 'lib/crud'
import {Preview} from 'app/Components/Preview/Preview'
import {User} from 'lib/users'
import {me, Role} from 'lib/auth'
import {State} from 'lib/store'

type StateToProps = {
    readonly me: User
}

type GroupPreviewProps = {
    ids: string[],
}

class GroupPreviewComponent extends React.Component<StateToProps & GroupPreviewProps> {
    public render = () =>
        <Preview
            value={this.props.ids}
            source={Source.GROUPS}
            template={(group: Group) => (this.props.me.roles.includes(Role.ROLE_PARENT)
                    ? <>{group.name}</>
                    : <Link component={NavLink}
                            to={generateCrudPath(groupsName, RouteType.SHOW, {id: group.id})}>{group.name}</Link>
            )}
        />
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    me: me(state)!
})

export const GroupPreview = connect(mapStateToProps)(GroupPreviewComponent)