import {RoleApi} from 'client/apis'
import {configuration} from 'lib/client'

const RoleApiClient = (): RoleApi => {
    return new RoleApi(configuration)
}

export const dao = {
    list: (payload: any) => {
        return RoleApiClient().getRoleCollection(payload)
    },
    get: (payload: any) => {
        return RoleApiClient().getRoleItem({id: payload.id})
    },
}