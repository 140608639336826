export type Option = {
    value: string
    label: string
}

export enum Source {
    SCHOOLS = 'schools',
    SCHOOL_TYPE = 'school_type',
    SUBJECTS_CATEGORY = 'subjects_category',
    ROLES = 'roles',

    DICTIONARIES = 'dictionaries',
    QUALIFICATIONS = 'qualifications',
    SPECIALTIES = 'specialties',
    DISABILITIES = 'disabilities',
    EVENTS = 'events',

    USERS = 'users',
    PARENTS = 'parents',
    TEACHERS = 'teachers',
    SPECIALISTS = 'specialists',

    GROUPS = 'groups',
    GROUP_CLASSES = 'group_classes',
    INDIVIDUAL_CLASSES = 'individual_classes',
    STUDENTS_CATEGORY = 'students_category',
    EDUCATION_STATUS = 'education_status',
    STUDENTS = 'students',
    STUDENT_FILE_TYPES = 'student_file_types',
    SUBJECTS = 'subjects',
    STATUS = 'status',
    AGE = 'age',
    CLASS_DURATION = 'class_duration',

    TEST_CONFIG = 'test_config',
    TEST_FOR_ANALYSIS = 'test_for_analysis',

    TEST_PUBLISHED = 'test_published',
}