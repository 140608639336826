import {TableCell, TableHead as MuiTableHead, TableRow, TableSortLabel, Tooltip} from '@material-ui/core'
import {isGranted, me} from 'lib/auth'
import {getFilters, getListOptions} from 'lib/crud'
import {ListOptions} from 'lib/dto'
import {State} from 'lib/store'
import {User} from 'lib/users'
import React from 'react'
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux'
import {AnyAction} from 'redux'

type StateToProps = {
    listOptions: ListOptions
    filters: { [paramName: string]: string | number | boolean | undefined }
    user: User
}

type DispatchToProps = {
    readonly reload: (options: ListOptions, filters: { [paramName: string]: string | number | boolean | undefined }) => void,
}

type HeadProps = {
    cols: { [key: string]: any }[],
    selector: (state: State) => any,
    getReloadAction: (listOptions: ListOptions, filters: { [paramName: string]: string | number | boolean | undefined }) => AnyAction
    actions?: any,
}

export class HeadComponent extends React.Component<HeadProps & StateToProps & DispatchToProps> {
    public render() {
        const {cols, actions} = this.props

        return (
            <MuiTableHead>
                <TableRow>
                    {cols.map(
                        (col, index) => (col.rule == undefined || isGranted(col.rule, this.props.user) ?
                            <TableCell
                                key={index}
                                align={col.numeric ? "right" : "left"}
                                padding={col.disablePadding ? "none" : "default"}
                                sortDirection={this.props.listOptions.sortField === col.id ? this.props.listOptions.sortDirection : false}
                                style={col.width !== undefined ? {width: col.width} : {}}
                            >
                                {col.sort
                                    ? <Tooltip
                                        title="Sortuj"
                                        placement={col.numeric ? "bottom-end" : "bottom-start"}
                                        enterDelay={300}
                                    >
                                        <TableSortLabel
                                            active={this.props.listOptions.sortField === col.id}
                                            direction={this.props.listOptions.sortDirection}
                                            onClick={this.sortHandler(col.id, this.props.listOptions.sortDirection)}
                                        >
                                            {col.label}
                                        </TableSortLabel>
                                    </Tooltip>
                                    : col.label
                                }
                            </TableCell> : null
                        ),
                        this
                    )}
                    {actions ? <TableCell align="right" style={{width: '50px'}}/> : <></>}
                </TableRow>
            </MuiTableHead>
        )
    }

    private sortHandler = (sortField: any, prevDirection: any) => (event: React.MouseEvent<unknown>) => {
        if (sortField === this.props.listOptions.sortField) {
            this.props.listOptions.sortDirection = prevDirection === 'asc' ? 'desc' : 'asc'
            this.props.reload(this.props.listOptions, this.props.filters)

            return
        }

        this.props.listOptions.sortField = sortField
        this.props.listOptions.sortDirection = 'asc'
        this.props.reload(this.props.listOptions, this.props.filters)
    }
}

const mapStateToProps: MapStateToProps<StateToProps, HeadProps, State> = (state, props) => ({
    listOptions: getListOptions(props.selector)(state),
    filters: getFilters(props.selector)(state),
    user: me(state)!
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, HeadProps> = (dispatch, props) => ({
    reload: (options: ListOptions, filters: { [paramName: string]: string | number | boolean | undefined }) => {
        dispatch(props.getReloadAction(options, filters))
    },
})

export const TableHead = connect(mapStateToProps, mapDispatchToProps)(HeadComponent)