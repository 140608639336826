import {
    ClameisrAnalysisData,
    ClameisrForm,
    ClameisrRawData,
    ClameisrResults,
    ClameisrSummaryData,
    ClameisrTestSection,
    Generic1FieldForm,
    Generic1FieldRawData,
    Generic2FieldForm,
    Generic2FieldRawData,
    Generic3FieldForm,
    Generic3FieldRawData,
    GenericReflexForm,
    GenericReflexRawData,
    ISPForm, ISPRawData, ISPSection, ISPTestSection,
    KodwkmzForm,
    KodwkmzRawData,
    KsForm,
    KsRawData,
    KsResults,
    KsSection,
    LFWForm,
    LFWRawData,
    LFWTestSection,
    STAREForm,
    STARERawData,
    STAREResults,
    STARETestSection,
    WodForm,
    WodRawData,
    WodResults,
    WodSection,
    OdfRawData,
    OdfForm,
    OdfSurveySection, KbmaRawData, KbmaForm, KbmaSection,
} from 'app/Views/Tests'
import {ISSSForm, ISSSRawData, ISSSTestSection} from "../../app/Views/Tests/ISSS";
import {Test} from "../test";

export function selectConfig(uid: string) {
    return TestConfiguration[uid]
}

export const TestConfiguration: { [key: string]: any } = {
    "532028fb-af1b-4916-bf33-b3cff6cc3fc0": { // ClaMEISR
        tabs: {
            results: ClameisrResults,
            summary: ClameisrSummaryData,
            analysis: ClameisrAnalysisData,
            raw: ClameisrRawData,
        },
        form: ClameisrForm,
        menu: {
            data: (test: Test) => test.content!.data as ClameisrTestSection[],
            label: (section: any) => section.section
        }
    },
    "425e5e83-bdd2-447b-b2f9-4c767b1089ed": { // STARE
        tabs: {
            results: STAREResults,
            summary: null,
            analysis: null,
            raw: STARERawData,
        },
        form: STAREForm,
        menu: {
            data: (test: Test) => test.content!.data as STARETestSection[],
            label: (section: any) => (section.activity == '' ? '... inna aktywność ...' : section.activity)
        }
    },
    "419ad650-8d2b-43b8-b342-b6bbb249e846": { // LFW
        tabs: {
            results: null,
            summary: null,
            analysis: null,
            raw: LFWRawData,
        },
        form: LFWForm,
        menu: {
            data: (test: Test) => test.content!.data as LFWTestSection[],
            label: (section: any) => section.question
        }
    },
    "b73fdc6d-81b8-407e-a5d6-6f6c906a1aff": { // KODWKMZ
        tabs: {
            results: null,
            summary: null,
            analysis: null,
            raw: KodwkmzRawData,
        },
        form: KodwkmzForm,
        menu: {
            data: (test: Test) => ['Ocena napięcia mięśniowego', 'Karta rozwoju psychoruchowego'],
            label: (section: any) => section
        }
    },
    "ea6c0941-f6d0-4228-bb76-ce39d613eff7": { // KORP
        tabs: {
            results: null,
            summary: null,
            analysis: null,
            raw: Generic3FieldRawData,
        },
        form: Generic3FieldForm,
        menu: null
    },
    "54717f28-5134-43e3-a090-8a30ca26a06c": { // NOB
        tabs: {
            results: null,
            summary: null,
            analysis: null,
            raw: GenericReflexRawData,
        },
        form: GenericReflexForm,
        menu: null
    },
    "617e1094-e8b2-4b89-a43d-509d71b5ecce": { // WOD
        tabs: {
            results: WodResults,
            summary: ClameisrSummaryData, // TODO: Later on when student summary page is done, rename it to something more generic
            analysis: null,
            raw: WodRawData,
        },
        form: WodForm,
        menu: {
            data: (test: Test) => test.content!.data as WodSection[],
            label: (section: any) => section.section,
        }
    },
    "21cfbd8d-c01f-4110-a85e-cf1f070e0394": { // Leiter3
        tabs: {
            results: null,
            summary: null,
            analysis: null,
            raw: Generic2FieldRawData,
        },
        form: Generic2FieldForm,
        menu: null
    },
    "236bde90-2ecd-4d37-af26-2221555c6a97": { // KOJD
        tabs: {
            results: null,
            summary: null,
            analysis: null,
            raw: Generic2FieldRawData,
        },
        form: Generic2FieldForm,
        menu: null
    },
    "239948a3-4db6-4d49-8cc7-ceb57d84d616": { // KL
        tabs: {
            results: null,
            summary: null,
            analysis: null,
            raw: Generic1FieldRawData,
        },
        form: Generic1FieldForm,
        menu: null
    },
    "317f3a53-27af-404d-9cad-00c23d9f2a4a": { // ASRS 6-18
        tabs: {
            results: null,
            summary: null,
            analysis: null,
            raw: Generic1FieldRawData,
        },
        form: Generic1FieldForm,
        menu: null
    },
    "a7bd9cb9-5b86-417a-90f2-c18c3118f7c0": { // ASRS 2-5
        tabs: {
            results: null,
            summary: null,
            analysis: null,
            raw: Generic1FieldRawData,
        },
        form: Generic1FieldForm,
        menu: null
    },
    "3a177fcf-192c-474a-9739-074b4fb64153": { // KOLD
        tabs: {
            results: null,
            summary: null,
            analysis: null,
            raw: Generic2FieldRawData,
        },
        form: Generic2FieldForm,
        menu: null
    },
    "4dcc3588-7c9a-4ac7-a53f-19ce8c6b10e9": { // KOS
        tabs: {
            results: null,
            summary: null,
            analysis: null,
            raw: Generic2FieldRawData,
        },
        form: Generic2FieldForm,
        menu: null
    },
    "685e392b-a07f-407d-99f5-f97f52ca752a": { // Afaskala
        tabs: {
            results: null,
            summary: null,
            analysis: null,
            raw: Generic1FieldRawData,
        },
        form: Generic1FieldForm,
        menu: null
    },
    "6d131b29-c487-42dc-b99e-cdd3730e8469": { // Binet
        tabs: {
            results: null,
            summary: null,
            analysis: null,
            raw: Generic2FieldRawData,
        },
        form: Generic2FieldForm,
        menu: null
    },
    "6f0f08b9-5627-4044-a8b9-8397188f3ca2": { // IDSP
        tabs: {
            results: null,
            summary: null,
            analysis: null,
            raw: Generic2FieldRawData,
        },
        form: Generic2FieldForm,
        menu: null
    },
    "744968e4-8576-49c9-be38-bfda1749f0d2": { // PEPR
        tabs: {
            results: null,
            summary: null,
            analysis: null,
            raw: Generic3FieldRawData,
        },
        form: Generic3FieldForm,
        menu: null
    },
    "9239afb2-405f-4cf7-9aa8-3944e1796773": { // DSR
        tabs: {
            results: null,
            summary: null,
            analysis: null,
            raw: Generic3FieldRawData,
        },
        form: Generic3FieldForm,
        menu: null
    },
    "c5a2489c-c07d-4c47-bae6-2a6171c05ba7": { // MFDR
        tabs: {
            results: null,
            summary: null,
            analysis: null,
            raw: Generic3FieldRawData,
        },
        form: Generic3FieldForm,
        menu: null
    },
    "cad8a3c7-6c58-480d-9ab4-2718eae98c6d": { // POZP
        tabs: {
            results: null,
            summary: null,
            analysis: null,
            raw: GenericReflexRawData,
        },
        form: GenericReflexForm,
        menu: null
    },
    "de760747-a09d-4875-9338-ebb58c771a53": { // ADOS
        tabs: {
            results: null,
            summary: null,
            analysis: null,
            raw: Generic1FieldRawData,
        },
        form: Generic1FieldForm,
        menu: null
    },
    "3fab0df4-d022-4086-87ed-729c01b20e90": { // KS
        tabs: {
            results: KsResults,
            summary: null,
            analysis: null,
            raw: KsRawData,
        },
        form: KsForm,
        menu: {
            data: (test: Test) => test.content!.data as KsSection[],
            label: (section: any) => section.section,
        }
    },
    "500c2887-e7d6-4fe7-a373-8b6034033eec": { // ODF
        tabs: {
            results: null,
            summary: null,
            analysis: null,
            raw: OdfRawData,
        },
        form: OdfForm,
        menu: {
            data: (test: Test) => test.content!.data as OdfSurveySection[],
            label: (section: any) => section.section,
        }
    },
    "452917b6-8814-4bc5-b8d1-3ad613bc681d": { // ISP
        tabs: {
            results: null,
            summary: null,
            analysis: null,
            raw: ISPRawData,
        },
        form: ISPForm,
        menu: {
            data: (test: Test) => test.content!.data as ISPTestSection[],
            label: (section: any) => section.trial.title,
        }
    },
    "7d821b37-228f-4c43-8e42-e16edb30ffed": { // ISSS
        tabs: {
            results: null,
            summary: null,
            analysis: null,
            raw: ISSSRawData,
        },
        form: ISSSForm,
        menu: {
            data: (test: Test) => test.content!.data as ISSSTestSection[],
            label: (section: any) => section.system,
        }
    },
    "e050a152-16bb-485d-a3dc-2e2557c10978": { // KBMA
        tabs: {
            results: null,
            summary: null,
            analysis: null,
            raw: KbmaRawData,
        },
        form: KbmaForm,
        menu: {
            data: (test: Test) => ['Próby języka', 'Próby warg', 'Wyniki badania'],
            label: (section: any) => section,
        }
    }
}
