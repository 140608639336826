import {Button, Card, CardContent as MuiCardContent, FormControl, Grid} from '@material-ui/core'
import {spacing} from '@material-ui/system'
import {Autocomplete, CircularProgressBar} from 'app/Components'
import {onKeyDown} from 'external-lib/utils/helpers'
import {Formik} from 'formik'
import {isLoading, isProcessing} from 'lib/progress'
import {State} from 'lib/store'
import React from 'react'
import {connect, MapStateToProps} from 'react-redux'
import styled from 'styled-components'
import * as Yup from 'yup'
import {Source} from "lib/select";
import {Test} from "lib/test";

const CardContent = styled(MuiCardContent)(spacing)

type StateToProps = {
    readonly isLoading: boolean
    readonly isProcessing: boolean
}

type FormControlProps = {
    initialValues: Test
    handleSubmit: (test: Test) => void
}

const Schema = Yup.object().shape({
    config: Yup.string()
        .required('Pole jest wymagane'),
})

class FormComponent extends React.Component<FormControlProps & StateToProps> {
    public render = () =>
        <>
            {
                this.props.isLoading
                    ? <CircularProgressBar/>
                    : <Formik
                        initialValues={this.props.initialValues}
                        validationSchema={Schema}
                        onSubmit={values => this.props.handleSubmit(values)}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                              setFieldValue
                          }) => (
                            <form onSubmit={handleSubmit} onKeyDown={onKeyDown}>

                                <Card variant="outlined">
                                    <CardContent>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl margin="dense" fullWidth>
                                                    <Autocomplete
                                                        label={"Test *"}
                                                        field={'config'}
                                                        source={Source.TEST_CONFIG}
                                                        value={values.config}
                                                        error={false}
                                                        setFieldValue={setFieldValue}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleSubmit()}
                                                    disabled={this.props.isProcessing}
                                                >
                                                    Zapisz
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </form>
                        )}
                    </Formik>
            }
        </>
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    isLoading: isLoading(state),
    isProcessing: isProcessing(state),
})

export const Form = connect(mapStateToProps)(FormComponent)