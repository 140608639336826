import {blue, green, grey, red} from '@material-ui/core/colors'

export enum VariantName {
    Light = "Light",
    Dark = "Dark"
}

export type Variant = {
    name: VariantName,
    palette: any,
    header: any,
    sidebar: any,
    body: {
        background: string
    },
}

const lightVariant: Variant = {
    name: VariantName.Light,
    palette: {
        primary: {
            main: blue[800],
            contrastText: "#FFF"
        },
        secondary: {
            main: blue[600],
            contrastText: "#FFF"
        }
    },
    header: {
        color: grey[200],
        background: blue[700],
        search: {
            color: grey[100]
        },
        indicator: {
            background: red[700]
        }
    },
    sidebar: {
        color: grey[900],
        background: "#FFF",
        header: {
            color: "#FFF",
            background: blue[700],
            brand: {
                color: "#FFFFFF"
            }
        },
        footer: {
            color: grey[900],
            background: grey[100],
            online: {
                background: green[500]
            }
        },
        badge: {
            color: "#FFF",
            background: green[600]
        }
    },
    body: {
        background: "#F7F9FC"
    }
}

const darkVariant: Variant = {
    name: VariantName.Dark,
    palette: {
        primary: {
            main: blue[700],
            contrastText: "#FFF"
        },
        secondary: {
            main: blue[500],
            contrastText: "#FFF"
        }
    },
    header: {
        color: grey[500],
        background: "#FFFFFF",
        search: {
            color: grey[800]
        },
        indicator: {
            background: blue[600]
        }
    },
    sidebar: {
        color: grey[200],
        background: "#1B2430",
        header: {
            color: grey[200],
            background: "#232f3e",
            brand: {
                color: blue[500]
            }
        },
        footer: {
            color: grey[200],
            background: "#232f3e",
            online: {
                background: green[500]
            }
        },
        badge: {
            color: "#FFF",
            background: blue[500]
        }
    },
    body: {
        background: "#F7F9FC"
    }
};

export const variants: Variant[] = [
    lightVariant,
    darkVariant
]