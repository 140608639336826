import {CrudActionType, Type} from 'lib/crud'
import {transformSummary} from 'lib/studentSummary'
import {ListOptions} from 'lib/dto'
import {AnyAction} from 'redux'

export type CrudState = {
    rows: any[]
    row: any
    count: number
    listOptions: ListOptions
    filters: { [paramName: string]: string | string[] | number | boolean | undefined }
    redirect: string
}

export const initialCrudState = {
    rows: [],
    row: {},
    count: 0,
    listOptions: {
        page: 0,
        pageSize: 10,
        sortDirection: 'asc' as 'asc' | 'desc',
        sortField: 'id'
    },
    filters: {},
    redirect: ''
}

export function crudReducer(name: string, state: CrudState = initialCrudState, action: AnyAction): CrudState {
    switch (action.type) {
        case CrudActionType.execute(name, Type.RESET):
            return {
                ...state,
                rows: [],
                row: [],
                count: 0
            }
        case CrudActionType.executeResponseSuccess(name, Type.LIST):
            if (action.type === '[CRUD] STUDENT_SUMMARY list success') {
                const rows = transformSummary(action.payload);
                return {
                    ...state,
                    rows: rows,
                    count: rows.length
                }
            }

            return {
                ...state,
                rows: action.payload.items,
                count: action.payload.total
            }
        case CrudActionType.executeResponseSuccess(name, Type.GET):
            return {
                ...state,
                row: action.payload,
            }
        case CrudActionType.execute(name, Type.UPDATE):
            if (action.payload.redirect) {
                return {
                    ...state,
                    redirect: action.payload.redirect,
                }
            }
            return state
        case CrudActionType.filter(name):
            return {
                ...state,
                listOptions: {
                    ...state.listOptions,
                    page: 0,
                },
                filters: action.payload,
            }
        default:
            return state
    }
}
