import React from 'react'
import {Provider} from 'react-redux'
import {store} from 'lib/store'
import {Root} from 'app/Root'

export const App: React.FunctionComponent = () => {
    return (
        <Provider store={store()}>
            <Root />
        </Provider>
    )
}
