import * as React from 'react'
import {ISSSSection, ISSSTestSection} from "./ISSSSection";

type ISPFormProps = {
    data?: any
    preview: boolean
    sectionIndex?: number
}

export class ISSSForm extends React.Component<ISPFormProps> {
    public render = () => {
        return <>
            {this.props.data ? (this.props.data as ISSSTestSection[]).map(
                (section, index) => {

                    return index == this.props.sectionIndex || this.props.sectionIndex == undefined ?
                        <ISSSSection
                            section={section}
                            key={index}
                            index={index}
                            preview={this.props.preview}
                            prev={index == 0 ? null : index - 1}
                            next={index == this.props.data.length - 1 ? null : index + 1}
                        /> : null
                }, this) : ''}
        </>
    }
}