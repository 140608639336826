import {CrudAction, getRow, Type} from 'lib/crud'
import {State} from 'lib/store'
import * as React from 'react'
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux'
import {Form} from './Form'
import {name, Subject} from 'lib/subjects'
import {CrudEditHeader} from 'app/Components'

type StateToProps = {
    readonly subject: Subject
}

type DispatchToProps = {
    readonly handleSubmit: (subject: Subject) => void,
}

type EditProps = StateToProps & DispatchToProps

class EditComponent extends React.Component<EditProps> {
    public render = () => (<>
        <CrudEditHeader actionName={name}/>
        <Form initialValues={this.props.subject} handleSubmit={this.props.handleSubmit}/>
    </>)
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    subject: getRow(state => state.subjects)(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    handleSubmit: (subject: Subject) => {
        const subjectId = subject.id
        delete subject.id
        dispatch(CrudAction.execute(name, Type.UPDATE, {
            id: subjectId,
            data: subject
        }))
    }
})

export const Edit = connect(mapStateToProps, mapDispatchToProps)(EditComponent)