import {Box as MuiBox} from '@material-ui/core'
import 'external-lib/react-select/style.css'
import * as React from "react";
import Dropzone from 'react-dropzone'
import styled from 'styled-components'

const FileBox = styled(MuiBox)`
  border: solid 1px rgba(0, 0, 0, 0.23);
  padding: 10px;
`

const DropZoneBox = styled(MuiBox)`
  border: solid 1px rgba(0, 0, 0, 0.23);
  padding: 30px;
  text-align: center;
  cursor: copy;
  outline: none;
`

type DropzoneProps = {
    field: string
    setFieldValue: (field: string, value: any) => void
}

type SingleDropzoneState = {
    file: File|null
}

export class SingleDropzone extends React.Component<DropzoneProps, SingleDropzoneState> {
    state = {
        file: null
    }
    public onChange = (event: React.FormEvent<HTMLInputElement>) => {
        const {files} = event.target as HTMLInputElement
        if (!files || files.length === 0) {
            return
        }

        this.props.setFieldValue(this.props.field, files[0])
        this.setState(prevState => {
            return {...prevState, file: files[0]}
        })
    }

    public render() {
        return <>
            {this.state.file
                ? <FileBox>Nazwa pliku: {(this.state.file! as File).name}</FileBox>
                : <Dropzone multiple={false}>
                    {({getRootProps, getInputProps}) => (
                        <DropZoneBox {...getRootProps()}>
                            <input {...getInputProps()} onChange={this.onChange} />
                            Przeciągnij plik w to miejsce albo kliknij tutaj aby wybrać go z dysku
                        </DropZoneBox>
                    )}
                </Dropzone>
            }
        </>
    }
}