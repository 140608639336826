import {Link} from '@material-ui/core'
import {CrudList, FilterType, SchoolPreview, UsersPreview} from 'app/Components'
import {Status} from 'app/Components/Status'
import {RouteType} from 'lib/crud'
import {Group, name} from 'lib/groups'
import {generateCrudPath} from 'lib/router'
import {Source} from 'lib/select'
import {State} from 'lib/store'
import * as React from 'react'
import {NavLink} from 'react-router-dom'
import {rules} from 'lib/auth'

const cols = [
    {
        id: "name",
        numeric: false,
        label: "Nazwa",
        sort: false,
        template: (group: Group) => <Link component={NavLink} to={generateCrudPath(name, RouteType.SHOW, {id: group.id})}>{group.name}</Link>
    },
    {
        id: "school",
        numeric: false,
        label: "Placówka",
        sort: false,
        width: '20%',
        template: (group: Group) => <SchoolPreview id={group.school} />
    },
    {
        id: "teachers",
        numeric: false,
        label: "Wychowawcy",
        sort: false,
        width: '20%',
        template: (group: Group) => <UsersPreview ids={group.teachers} />
    },
    {
        id: "active",
        numeric: false,
        label: "Status",
        sort: false,
        width: '100px',
        template: (group: Group) => <Status status={group.active} activeLabel={'Aktywna'} inactiveLabel={'Nieaktywna'}/>,
        rule: rules.group.status
    },
]

export class List extends React.Component {
    public render = () => (
        <CrudList
            actionName={name}
            cols={cols}
            selector={(state: State) => state.groups}
            deleteDialogText="Usunąć grupę?"
            rules={rules.group}
            filter={{
                initialValues: {name: '', school: [], active: '1'},
                fields: [
                    {name: 'name', label: 'Nazwa', type: FilterType.TEXT},
                    {name: 'school', label: 'Placówka', type: FilterType.MULTIPLE_SELECT, source: Source.SCHOOLS},
                    {
                        name: 'active',
                        label: 'Status',
                        type: FilterType.SELECT,
                        source: Source.STATUS,
                        requestOptions: {type: Source.GROUPS},
                        rule: rules.group.status
                    }
                ]
            }}
        />
    )
}