import {CrudActionType, crudReducer, CrudState, initialCrudState} from 'lib/crud'
import {AnyAction} from 'redux'
import {TestActionType} from "../test";
import {comparisonTransformer, evaluationsTransformer} from "../test/transformer";
import {StudentActionType} from "./actions";

export const name = 'STUDENT'

export type StudentState = {
    crud: CrudState,
    evaluations: any,
    comparison: any,
    analyseFilter: any
}

const initialState = {
    crud: initialCrudState,
    evaluations: [],
    comparison: {},
    analyseFilter: {}
}

export function studentReducer(state: StudentState = initialState, action: AnyAction): StudentState {
    switch (action.type) {
        case StudentActionType.evaluateSuccess:
            return {
                ...state,
                evaluations: evaluationsTransformer(action.payload),
            }
        case CrudActionType.filter('Student analysis'):
            return {
                ...state,
                analyseFilter: action.payload,
            }
        case StudentActionType.analyseStep1Success:
            return {
                ...state,
                crud: {
                    ...state.crud,
                    row: action.payload,
                }
            }
        case StudentActionType.analyseStep2Success:
            return {
                ...state,
                comparison: comparisonTransformer(action.payload),
            }
        case TestActionType.analyseReset:
            return {
                ...state,
                evaluations: [],
                comparison: {},
                analyseFilter: {}
            }
        default:
            return {
                ...state,
                crud: crudReducer(name, state.crud, action)
            }
    }
}
