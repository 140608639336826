import {
    CrudAction,
    dispatchAfterFailureEpic,
    dispatchAfterFilterEpic,
    dispatchAfterSuccessEpic,
    executeCrudEpic,
    redirectAfterSuccessEpic,
    RouteType,
    Type
} from 'lib/crud'
import {dao, name} from 'lib/groupClass'
import {NotificationsAction} from 'lib/notifications'
import {generateCrudPath} from 'lib/router'
import {State} from 'lib/store'
import {combineEpics} from 'redux-observable'

export const groupClassesEpics = combineEpics(
    executeCrudEpic(name, Type.LIST)(dao.list),
    executeCrudEpic(name, Type.GET)(dao.get),
    executeCrudEpic(name, Type.CREATE)(dao.create),
    executeCrudEpic(name, Type.UPDATE)(dao.update),
    executeCrudEpic(name, Type.DELETE)(dao.delete),

    redirectAfterSuccessEpic(name, Type.CREATE, (action, state: State) => generateCrudPath(name, RouteType.LIST, {parentId: state.groups.crud.row.id})),
    redirectAfterSuccessEpic(name, Type.UPDATE, (action, state: State) => generateCrudPath(name, RouteType.LIST, {parentId: state.groups.crud.row.id})),

    dispatchAfterSuccessEpic(name, Type.CREATE, (action, state: State) => NotificationsAction.show({
        message: 'Dodano nowe zajęcia',
        options: {variant: "success"}
    })),

    dispatchAfterFailureEpic(name, Type.CREATE, (action, state: State) => NotificationsAction.show({
        message: 'Wystąpił błąd podczas dodawania nowych zajęć',
        options: {variant: "error"}
    })),

    dispatchAfterSuccessEpic(name, Type.UPDATE, (action, state: State) => NotificationsAction.show({
        message: 'Zajęcia zostały zaktualizowane',
        options: {variant: "success"}
    })),

    dispatchAfterFailureEpic(name, Type.UPDATE, (action, state: State) => NotificationsAction.show({
        message: 'Wystąpił błąd podczas aktualizacji zajęć',
        options: {variant: "error"}
    })),

    dispatchAfterSuccessEpic(name, Type.DELETE, (action, state: State) => NotificationsAction.show({
        message: 'Zajęcia zostały usunięte',
        options: {variant: "success"}
    })),

    dispatchAfterFailureEpic(name, Type.DELETE, (action, state: State) => NotificationsAction.show({
        message: 'Wystąpił błąd podczas usuwania zajęć',
        options: {variant: "error"}
    })),

    dispatchAfterSuccessEpic(name, Type.DELETE, (action, state: State) => CrudAction.execute(name, Type.LIST, {
        orderName: state.groupClass.crud.listOptions.sortDirection,
        page: state.groupClass.crud.listOptions.page + 1,
        itemsPerPage: state.groupClass.crud.listOptions.pageSize,
        ...state.groupClass.crud.filters,
    })),

    dispatchAfterFilterEpic(name, (action, state: State) => CrudAction.execute(name, Type.LIST, {
        orderName: state.groupClass.crud.listOptions.sortDirection,
        page: state.groupClass.crud.listOptions.page + 1,
        itemsPerPage: state.groupClass.crud.listOptions.pageSize,
        ...state.groupClass.crud.filters,
    }))
)