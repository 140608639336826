import {Box, TableCell, TableRow, Typography} from "@material-ui/core";
import {getRows, PreviewAction} from 'lib/preview'
import {State} from 'lib/store'
import * as React from 'react'
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux'

type StateToProps = {
    rows: any
}

type DispatchToProps = {
    add: () => void
    remove: () => void
}

type PreviewProps = {
    value: string | string[]
    source: string
    template: (row: any) => any
    tableRow?: boolean
    colSpan?: number
}

type PreviewState = {
    retry: number
    source: string
}

const retries = 3

class PreviewComponent extends React.Component<PreviewProps & StateToProps & DispatchToProps, PreviewState> {
    state: PreviewState = {
        retry: 0,
        source: ''
    }

    componentDidMount() {
        this.setState(prevState => {
            return {...prevState, source: this.props.source}
        })
    }

    componentDidUpdate(prevProps: Readonly<PreviewProps & StateToProps & DispatchToProps>, prevState: Readonly<PreviewState>, snapshot?: any) {
        if (prevProps.value !== this.props.value) {
            this.props.add()
        }
    }

    public render = () => {
        if (this.state.source === '') {
            return <></>
        }
        if (this.state.retry <= 1) {
            this.setState(prevState => {
                return {...prevState, retry: prevState.retry + 1}
            })
            this.props.add()
        }

        if (!this.props.value) {
            return this.noData()
        }

        let isAnyNull = false
        if (Array.isArray(this.props.value)) {
            let rows: JSX.Element[] = []
            this.props.value.forEach((value) => {
                const row = this.getRow(value)
                if (row === null) {
                    isAnyNull = true
                }
                rows.push(row)
            })

            return isAnyNull && this.state.retry < retries
                ? this.loadingData()
                : rows.map((row, index) => this.props.tableRow
                    ? (row ? this.props.template(row) : <></>)
                    : <Box key={index}>{row ? this.props.template(row) : <></>}</Box>)
        }

        const row = this.getRow(this.props.value as string)
        return row ? (row ? this.props.template(row) : <></>) : this.loadingData()
    }

    private getRow = (value: string) => {
        const rows = this.props.rows.filter((row: any) => row.id == value)
        return rows.length === 1 ? rows[0] : null
    }

    private noData = () => {
        return this.props.tableRow
            ? <TableRow><TableCell align={"center"} component="td" colSpan={this.props.colSpan}>Brak</TableCell></TableRow>
            : <Typography style={{color: '#777777'}}>Brak</Typography>
    }

    private loadingData = () => {
        if (this.state.retry < retries) {
            this.setState(prevState => {
                return {...prevState, retry: prevState.retry + 1}
            })
            setTimeout(() => {
                this.props.add()
            }, 2000)
        }

        return this.props.tableRow
            ? 'Ładowanie danych'
            : <Typography style={{color: '#777777'}}>Ładowanie danych</Typography>
    }

}

const mapStateToProps: MapStateToProps<StateToProps, PreviewProps, State> = (state, ownProps) => ({
    rows: getRows(ownProps.source)(state) || []
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, PreviewProps> = (dispatch, props) => ({
    add: () => {
        if (props.value !== null && props.value !== []) {
            dispatch(PreviewAction.add(props.source, Array.isArray(props.value) ? props.value : [props.value]))
        }
    },
    remove: () => {
        if (props.value) {
            dispatch(PreviewAction.remove(props.source, Array.isArray(props.value) ? props.value : [props.value]))
        }
    },
})

export const Preview = connect(mapStateToProps, mapDispatchToProps)(PreviewComponent)