import {CrudAddHeader} from 'app/Components'
import {CrudAction, getRow, Type} from 'lib/crud'
import {Group, name as groupName} from 'lib/groups'
import {name as groupChildrenName} from 'lib/groupChildren'
import {State} from 'lib/store'
import * as React from 'react'
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux'
import {withRouter} from 'react-router'
import {RouteComponentProps} from 'react-router-dom'
import {Tabs} from "../../Tabs";
import {Form} from './Form'

type StateToProps = {
    readonly group: Group
}

type DispatchToProps = {
    readonly load: (actionName: string, id: string) => void,
    readonly handleSubmit: (group: Group) => void,
}

type Params = {
    parentId: string
}

class EditComponent extends React.Component<StateToProps & DispatchToProps & RouteComponentProps<Params>> {
    public componentDidMount(): void {
        if (this.props.match.params.parentId) {
            this.props.load(groupName, this.props.match.params.parentId)
        }
    }

    public render = () => (<>
        <CrudAddHeader actionName={groupChildrenName}/>
        <Tabs id={this.props.match.params.parentId} />
        {this.props.group.id != undefined
            ? <Form initialValues={this.props.group} handleSubmit={this.props.handleSubmit}/>
            : null}
    </>)
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    group: getRow(state => state.groups)(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    load: (actionName: string, id: string) => {
        dispatch(CrudAction.execute(actionName, Type.GET, {id}))
    },
    handleSubmit: (group: Group) => {
        const groupId = group.id
        delete group.id
        dispatch(CrudAction.execute(groupName, Type.UPDATE, {
            id: groupId,
            data: group
        }))
    },
})

export const Edit = withRouter(connect(mapStateToProps, mapDispatchToProps)(EditComponent))