import {AnyAction} from 'redux'
import {combineEpics, Epic} from 'redux-observable'
import {filter, map} from 'rxjs/operators'
import {ProgressAction} from "./actions";

const setLoadingEpic: Epic<AnyAction> = action$ => action$.pipe(
    filter(action =>
        action.type.includes('list start')
        || action.type.includes('get start')
    ),
    map(() => ProgressAction.setLoading(true)),
)

const unsetLoadingEpic: Epic<AnyAction> = action$ => action$.pipe(
    filter(action =>
        action.type.includes('list failure')
        || action.type.includes('list success')
        || action.type.includes('get failure')
        || action.type.includes('get success')
    ),
    map(() => ProgressAction.setLoading(false)),
)

const setProcessingEpic: Epic<AnyAction> = action$ => action$.pipe(
    filter(action =>
        action.type.includes('create start')
        || action.type.includes('update start')
        || action.type.includes('delete start')

        || action.type.includes('Analysis filter')
        || action.type.includes('Student analysis filter')
    ),
    map(() => ProgressAction.setProcessing(true)),
)

const unsetProcessingEpic: Epic<AnyAction> = action$ => action$.pipe(
    filter(action =>
        action.type.includes('create failure')
        || action.type.includes('create success')
        || action.type.includes('update failure')
        || action.type.includes('update success')
        || action.type.includes('delete failure')
        || action.type.includes('delete success')

        || action.type.includes('Compare success')
        || action.type.includes('Student analysis step2 success')
    ),
    map(() => ProgressAction.setProcessing(false)),
)

export const progressEpics = combineEpics(
    setLoadingEpic,
    unsetLoadingEpic,
    setProcessingEpic,
    unsetProcessingEpic,
)