import {
    Box,
    Checkbox,
    FormControlLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField
} from '@material-ui/core'
import * as React from 'react'
import {LFWTestSection} from "../LFWSection";

type Q1Props = {
    section: LFWTestSection
    preview: boolean
}

type Q1State = {
    section: LFWTestSection
}


export class Q1 extends React.Component<Q1Props, Q1State> {
    state: Q1State = {
        section: this.props.section
    }

    public handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.state.section.response[event.target.name] = event.target.checked

        if (event.target.name == 'nie') {
            this.state.section.response['lewe oko'] = false
            this.state.section.response['prawe oko'] = false
            this.state.section.response['tylko w przeszłości'] = false
        }

        if (event.target.name == 'lewe oko' || event.target.name == 'prawe oko') {
            this.state.section.response['nie'] = false
            this.state.section.response['tylko w przeszłości'] = false
        }

        if (event.target.name == 'tylko w przeszłości') {
            this.state.section.response['nie'] = false
            this.state.section.response['lewe oko'] = false
            this.state.section.response['prawe oko'] = false
        }

        this.setState(prevState => {
            return {...prevState, section: this.state.section}
        })
    }

    public handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.state.section.response[event.target.name] = event.target.value;
        this.setState(prevState => {
            return {...prevState, section: this.state.section}
        })
    }

    public render = () => {
        return <TableContainer>
            <Table className={'gray-striped'}>
                <TableBody>
                    <TableRow>
                        <TableCell colSpan={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={'nie'}
                                        checked={this.state.section.response.nie}
                                        onChange={this.handleChange}
                                        disabled={this.props.preview}
                                        color="primary"
                                    />
                                }
                                label={'nie'}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={'lewe oko'}
                                        checked={this.state.section.response['lewe oko']}
                                        onChange={this.handleChange}
                                        disabled={this.props.preview}
                                        color="primary"
                                    />
                                }
                                label={'tak, oko lewe'}
                            />
                        </TableCell>
                        <TableCell>
                            <TextField
                                name="description_left"
                                variant="outlined"
                                inputProps={{
                                    maxLength: 50,
                                }}
                                value={this.state.section.response['description_left']}
                                onChange={this.handleTextChange}
                                disabled={this.props.preview}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={'prawe oko'}
                                        checked={this.state.section.response['prawe oko']}
                                        onChange={this.handleChange}
                                        disabled={this.props.preview}
                                        color="primary"
                                    />
                                }
                                label={'tak, oko prawe'}
                            />
                        </TableCell>
                        <TableCell>
                            <TextField
                                name="description_right"
                                variant="outlined"
                                inputProps={{
                                    maxLength: 50,
                                }}
                                value={this.state.section.response['description_right']}
                                onChange={this.handleTextChange}
                                disabled={this.props.preview}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={'tylko w przeszłości'}
                                        checked={this.state.section.response['tylko w przeszłości']}
                                        onChange={this.handleChange}
                                        disabled={this.props.preview}
                                        color="primary"
                                    />
                                }
                                label={'tylko w przeszłości'}
                            />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    }
}