// tslint:disable
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI, OperationOpts, RawAjaxResponse } from '../runtime';
import {
    InlineResponse20027,
    WorkModelRead,
    WorkModelWrite,
} from '../models';

export interface DeleteWorkModelItemRequest {
    id: string;
}

export interface GetWorkModelCollectionRequest {
    id?: Array<string>;
    name?: string;
    active?: boolean;
    orderName?: GetWorkModelCollectionOrderNameEnum;
    orderActive?: GetWorkModelCollectionOrderActiveEnum;
    page?: number;
    itemsPerPage?: number;
}

export interface GetWorkModelItemRequest {
    id: string;
}

export interface PostWorkModelCollectionRequest {
    workModelWrite?: WorkModelWrite;
}

export interface PutWorkModelItemRequest {
    id: string;
    workModelWrite?: WorkModelWrite;
}

/**
 * no description
 */
export class WorkModelApi extends BaseAPI {

    /**
     * Removes the WorkModel resource.
     */
    deleteWorkModelItem({ id }: DeleteWorkModelItemRequest): Observable<void>
    deleteWorkModelItem({ id }: DeleteWorkModelItemRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    deleteWorkModelItem({ id }: DeleteWorkModelItemRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(id, 'id', 'deleteWorkModelItem');

        return this.request<void>({
            url: '/api/work-models/{id}'.replace('{id}', encodeURI(id)),
            method: 'DELETE',
        }, opts?.responseOpts);
    };

    /**
     * Retrieves the collection of WorkModel resources.
     */
    getWorkModelCollection({ id, name, active, orderName, orderActive, page, itemsPerPage }: GetWorkModelCollectionRequest): Observable<InlineResponse20027>
    getWorkModelCollection({ id, name, active, orderName, orderActive, page, itemsPerPage }: GetWorkModelCollectionRequest, opts?: OperationOpts): Observable<RawAjaxResponse<InlineResponse20027>>
    getWorkModelCollection({ id, name, active, orderName, orderActive, page, itemsPerPage }: GetWorkModelCollectionRequest, opts?: OperationOpts): Observable<InlineResponse20027 | RawAjaxResponse<InlineResponse20027>> {

        const query: HttpQuery = {};

        if (id != null) { query['id[]'] = id; }
        if (name != null) { query['name'] = name; }
        if (active != null) { query['active'] = active; }
        if (orderName != null) { query['order[name]'] = orderName; }
        if (orderActive != null) { query['order[active]'] = orderActive; }
        if (page != null) { query['page'] = page; }
        if (itemsPerPage != null) { query['itemsPerPage'] = itemsPerPage; }

        return this.request<InlineResponse20027>({
            url: '/api/work-models',
            method: 'GET',
            query,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a WorkModel resource.
     */
    getWorkModelItem({ id }: GetWorkModelItemRequest): Observable<WorkModelRead>
    getWorkModelItem({ id }: GetWorkModelItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<WorkModelRead>>
    getWorkModelItem({ id }: GetWorkModelItemRequest, opts?: OperationOpts): Observable<WorkModelRead | RawAjaxResponse<WorkModelRead>> {
        throwIfNullOrUndefined(id, 'id', 'getWorkModelItem');

        return this.request<WorkModelRead>({
            url: '/api/work-models/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
        }, opts?.responseOpts);
    };

    /**
     * Creates a WorkModel resource.
     */
    postWorkModelCollection({ workModelWrite }: PostWorkModelCollectionRequest): Observable<WorkModelRead>
    postWorkModelCollection({ workModelWrite }: PostWorkModelCollectionRequest, opts?: OperationOpts): Observable<RawAjaxResponse<WorkModelRead>>
    postWorkModelCollection({ workModelWrite }: PostWorkModelCollectionRequest, opts?: OperationOpts): Observable<WorkModelRead | RawAjaxResponse<WorkModelRead>> {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<WorkModelRead>({
            url: '/api/work-models',
            method: 'POST',
            headers,
            body: workModelWrite,
        }, opts?.responseOpts);
    };

    /**
     * Replaces the WorkModel resource.
     */
    putWorkModelItem({ id, workModelWrite }: PutWorkModelItemRequest): Observable<WorkModelRead>
    putWorkModelItem({ id, workModelWrite }: PutWorkModelItemRequest, opts?: OperationOpts): Observable<RawAjaxResponse<WorkModelRead>>
    putWorkModelItem({ id, workModelWrite }: PutWorkModelItemRequest, opts?: OperationOpts): Observable<WorkModelRead | RawAjaxResponse<WorkModelRead>> {
        throwIfNullOrUndefined(id, 'id', 'putWorkModelItem');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<WorkModelRead>({
            url: '/api/work-models/{id}'.replace('{id}', encodeURI(id)),
            method: 'PUT',
            headers,
            body: workModelWrite,
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum GetWorkModelCollectionOrderNameEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
 * @export
 * @enum {string}
 */
export enum GetWorkModelCollectionOrderActiveEnum {
    Asc = 'asc',
    Desc = 'desc'
}
