import {configuration} from 'lib/client'
import {HistoryEntryApi} from "client/apis";

const HistoryEntryApiClient = (): HistoryEntryApi => {
    return new HistoryEntryApi(configuration)
}

export const dao = {
    list: (payload: any) => {
        if (payload.occurredAtBefore == '') {
            delete payload.occurredAtBefore
        }
        if (payload.occurredAtAfter == '') {
            delete payload.occurredAtAfter
        }

        return HistoryEntryApiClient().getHistoryEntryCollection(payload)
    },
    get: (payload: any) => {
        return HistoryEntryApiClient().getHistoryEntryItem({id: payload.id})
    },
    create: (payload: any) => {
        return HistoryEntryApiClient().postHistoryEntryCollection({historyEntryWrite: payload})
    },
    update: (payload: any) => {
        return HistoryEntryApiClient().putHistoryEntryItem({id: payload.id, historyEntryWrite: payload.data})
    },
    delete: (payload: any) => {
        return HistoryEntryApiClient().deleteHistoryEntryItem({id: payload.id})
    }
}