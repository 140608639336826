import {ActionsUnion, createAction} from 'external-lib/redux-utils'

export const ProgressActionType = {
    setLoading: (): string => `[UI] Set loading`,
    setProcessing: (): string => `[UI] Set processing`,
}

export const ProgressAction = {
    setLoading: (loading: boolean) => createAction(ProgressActionType.setLoading(), loading),
    setProcessing: (processing: boolean) => createAction(ProgressActionType.setProcessing(), processing),
}

export type ProgressAction = ActionsUnion<typeof ProgressAction>