import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core'
import * as React from 'react'

type PreviewProps = {
    data: any
}

export class STAREResults extends React.Component<PreviewProps> {
    public render = () => <TableContainer>
        <Table className={'gray-striped'}>
            <TableHead>
                <TableRow>
                    <TableCell>Aktywność</TableCell>
                    <TableCell align={'right'}>Wynik</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {Object.entries(this.props.data.ZO).map((section: any, i: number) => <TableRow key={i}>
                    <TableCell>
                        {section[0] == 'general' ? 'Ogólny poziom' : ''}
                        {section[0] == 'adults' ? 'Z dorosłym' : ''}
                        {section[0] == 'peers' ? 'Z rówieśnikami' : ''}
                        {section[0] == 'objects' ? 'Z rzeczami' : ''}
                        {section[0] == 'quality' ? 'Jakość' : ''}
                    </TableCell>
                    <TableCell align={'right'}>{section[1]}</TableCell>
                </TableRow>)}
            </TableBody>
        </Table>
    </TableContainer>
}