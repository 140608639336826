import {StudentCategoryApi} from 'client/apis'
import {configuration} from 'lib/client'

const StudentCategoryApiClient = (): StudentCategoryApi => {
    return new StudentCategoryApi(configuration)
}

export const dao = {
    list: (payload: any) => {
        return StudentCategoryApiClient().getStudentCategoryCollection(payload)
    },
    get: (payload: any) => {
        return StudentCategoryApiClient().getStudentCategoryItem({id: payload.id})
    },
}