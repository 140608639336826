import {BackButton} from 'app/Layout/Content'
import {CrudAction, RouteType, Type} from 'lib/crud'
import {NavigationAction} from 'lib/navigation'
import {generateCrudPath, getCrudPathLabels} from 'lib/router'
import * as React from 'react'
import {connect, MapDispatchToProps} from 'react-redux'
import {RouteComponentProps, withRouter} from 'react-router-dom'

type Params = {
    id: string
    parentId?: string
}

type EditProps = {
    actionName: string
}

type DispatchToProps = {
    readonly load: (actionName: string, id: string) => void,
    readonly setHeader: (actionName: string, type: RouteType) => void,
    readonly setAction: (action: JSX.Element) => void,
}

class CrudEditHeaderComponent extends React.Component<EditProps & DispatchToProps & RouteComponentProps<Params>> {
    public componentDidMount(): void {
        this.props.setHeader(this.props.actionName, RouteType.EDIT)
        this.props.setAction(<BackButton url={generateCrudPath(this.props.actionName, RouteType.LIST, this.getParams())}/>)
        this.props.load(this.props.actionName, this.props.match.params.id)
    }

    private getParams = () => {
        return {parentId: this.props.match.params.parentId}
    }

    public render = () => <></>
}

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    load: (actionName: string, id: string) => {
        dispatch(CrudAction.execute(actionName, Type.GET, {id}))
    },
    setHeader: (actionName, type) => {
        dispatch(NavigationAction.setHeader(getCrudPathLabels(actionName, type)))
    },
    setAction: (action: JSX.Element) => {
        dispatch(NavigationAction.setAction(action))
    }
})

export const CrudEditHeader =  withRouter(connect(null, mapDispatchToProps)(CrudEditHeaderComponent))