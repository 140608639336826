import {UserApi} from 'client/apis'
import {configuration} from 'lib/client'

const UserApiClient = (): UserApi => {
    return new UserApi(configuration)
}

export const dao = {
    list: (payload: any) => {
        return UserApiClient().getUserCollection(payload)
    },
    get: (payload: any) => {
        return UserApiClient().getUserItem({id: payload.id})
    },
    create: (payload: any) => {
        return UserApiClient().postUserCollection({userWrite: payload})
    },
    update: (payload: any) => {
        const id = payload.id
        delete payload.id
        return UserApiClient().putUserItem({id, userWrite: payload})
    },
    delete: (payload: any) => {
        return UserApiClient().deleteUserItem({id: payload.id})
    }
}