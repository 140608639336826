import {Link} from "@material-ui/core";
import {isGranted, me, rules} from 'lib/auth'
import {State} from 'lib/store'
import {StudentFile} from 'lib/studentFiles'
import {User} from 'lib/users'
import * as React from 'react'
import {connect, MapStateToProps} from 'react-redux'

type StateToProps = {
    readonly user: User
}

type StudentFileLabelProps = {
    file: StudentFile
}

class StudentFileLabelComponent extends React.Component<StudentFileLabelProps & StateToProps> {
    public render = () => {
        if (!isGranted(rules.studentFiles.canDownload, this.props.user)) {
            return this.props.file.name
        }

        return <Link href={'/api/student-files/' + this.props.file.id + '/content'} target={'_blank'}>{this.props.file.name}</Link>
    }
}

const mapStateToProps: MapStateToProps<StateToProps, StudentFileLabelProps, State> = (state, ownProps) => ({
    user: me(state)!,
})

export const StudentFileLabel = connect(mapStateToProps)(StudentFileLabelComponent)