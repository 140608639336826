import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core'
import * as React from 'react'

type PreviewProps = {
    data: any
}

export class ClameisrSummaryData extends React.Component<PreviewProps> {
    public render = () => <TableContainer>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell style={{width: '25%'}}>Kategoria</TableCell>
                    <TableCell align={'right'} style={{width: '50px'}}>Poziom</TableCell>
                    <TableCell>Opis</TableCell>
                </TableRow>
            </TableHead>
            <TableBody className={'gray-striped'}>
                {this.props.data.map((section: any, i: number) => <TableRow key={i}>
                    <TableCell>
                        {section[0] == 'F' ? 'Fizyczna (F)' : ''}
                        {section[0] == 'K' ? 'Komunikacja (K)' : ''}
                        {section[0] == 'ES' ? 'Emocjonalno-społeczny (ES)' : ''}
                        {section[0] == 'P' ? 'Poznawcze (P)' : ''}
                        {section[0] == 'U' ? 'Umiejętności szkolne (U)' : ''}
                    </TableCell>
                    {Object.entries(section[1]).map((row: any, j: number) => row[0] == 'score'
                        ? <TableCell key={-1} align={"right"}>{Math.round(row[1] * 100)}%</TableCell>
                        : <TableCell key={j}>
                            {Object.entries(row[1]).map((element: any, k: number) => <Box key={k}>{element[1]}</Box>)}
                        </TableCell>
                    )}
                </TableRow>)}
            </TableBody>
        </Table>
    </TableContainer>
}