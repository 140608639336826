import {AnyAction} from 'redux'
import {crudReducer, CrudState, initialCrudState} from 'lib/crud'

export const testConfigName = 'TEST_CONFIG'

export type TestConfigState = {
    crud: CrudState,
}

const initialState = {
    crud: initialCrudState,
}

export function testConfigReducer(state: TestConfigState = initialState, action: AnyAction): TestConfigState {
    switch (action.type) {
        default:
            return {
                ...state,
                crud: crudReducer(testConfigName, state.crud, action)
            }
    }
}
