import {Box} from '@material-ui/core'
import Link from '@material-ui/core/Link'
import {RouteType} from 'lib/crud'
import {generateCrudPath} from 'lib/router'
import {name, Student} from 'lib/students'
import * as React from 'react'
import {NavLink} from "react-router-dom"

type StudentLabelProps = {
    student: Student
}

export class StudentLabel extends React.Component<StudentLabelProps> {
    public render = () => (<>
        <Link component={NavLink} to={generateCrudPath(name, RouteType.SHOW, {id: this.props.student.id})}>
            {this.props.student.lastName} {this.props.student.firstName}
        </Link>
        <Box>({this.props.student.identifier})</Box>
    </>)
}