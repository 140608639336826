import {Chip, ListItem, ListItemText} from '@material-ui/core'
import {ExpandLess, ExpandMore} from '@material-ui/icons'
import {AccessRule} from 'lib/auth'
import {ProtectedRoutes, Routes} from 'lib/router'
import {darken, rgba} from 'polished'
import * as React from 'react'
import {NavLink} from 'react-router-dom'
import styled from 'styled-components'

type CategoryProps = {
    activeClassName: string
} & { [key: string]: any }

const CategoryStyled = styled(ListItem)<CategoryProps>`
    padding-top: ${props => props.theme.spacing(3)}px;
    padding-bottom: ${props => props.theme.spacing(3)}px;
    padding-left: ${props => props.theme.spacing(6)}px;
    padding-right: ${props => props.theme.spacing(5)}px;
    font-weight: ${props => props.theme.typography.fontWeightRegular};
    
    svg {
        color: ${props => props.theme.sidebar.color};
        font-size: 20px;
        width: 20px;
        height: 20px;
        opacity: 0.5;
    }
    
    &:hover {
      background-color: ${props => darken(0.05, props.theme.sidebar.background)};
    }
    
    &.${props => props.activeClassName} {
        background-color: ${props => darken(0.05, props.theme.sidebar.background)};
        
        span {
          color: ${props => props.theme.sidebar.color};
        }
    }
`

const CategoryText = styled(ListItemText)`
  margin: 0;
  span {
    color: ${props => props.theme.sidebar.color};
    font-size: ${props => props.theme.typography.body1.fontSize}px;
    font-weight: ${props => props.theme.typography.fontWeightRegular};
    padding: 0 ${props => props.theme.spacing(4)}px;
  }
`

const CategoryIconLess = styled(ExpandLess)`
  color: ${props => rgba(props.theme.sidebar.color, 0.5)};
`

const CategoryIconMore = styled(ExpandMore)`
  color: ${props => rgba(props.theme.sidebar.color, 0.5)};
`

const BadgeStyled = styled(Chip)`
  font-size: 11px;
  font-weight: ${props => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 12px;
  top: 12px;
  background: ${props => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${props => props.theme.sidebar.badge.color};
    padding-left: ${props => props.theme.spacing(2)}px;
    padding-right: ${props => props.theme.spacing(2)}px;
  }
`

type SidebarCategoryProps = {
    name: string,
    icon: unknown,
    isCollapsible: boolean,
    to?: Routes | ProtectedRoutes | string,
    isOpen?: boolean,
    badge?: string,
    onClickFn?: () => void
    accessRule?: AccessRule
    isVisible?: boolean
} & { [key: string]: any }

export const Category: React.FunctionComponent<SidebarCategoryProps> = ({name, icon, isOpen, to, isCollapsible, badge, onClickFn, isVisible, ...rest}) => (
    isVisible == undefined || isVisible ? <>
        {isCollapsible ? (
            <CategoryStyled
                button
                onClick={onClickFn}
            >
                {icon}
                <CategoryText>{name}</CategoryText>
                {isOpen ? (
                    <CategoryIconMore/>
                ) : (
                    <CategoryIconLess/>
                )}
            </CategoryStyled>
        ) : (
            <CategoryStyled
                button
                component={NavLink}
                to={to}
                activeClassName="active"
            >
                {icon}
                <CategoryText>{name}</CategoryText>
                {badge ? <BadgeStyled label={badge}/> : null}
            </CategoryStyled>
        )}
    </> : <></>
)