import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from '@material-ui/core'
import * as React from 'react'

type PreviewProps = {
    data: Section[]
}

interface Subsection {
    subsection: string;
    score: number;
    risk: boolean;
}

interface Section {
    section: string;
    subsections: Subsection[];
}

export class KsResults extends React.Component<PreviewProps> {
    public render = () => <TableContainer>
        <Table className={'gray-striped'}>
            <TableHead>
                <TableRow>
                    <TableCell>Skala</TableCell>
                    <TableCell>Wynik</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {this.props.data.map((section: Section) =>
                    <>
                        {section.subsections.map((subsection: Subsection) =>
                            <TableRow>
                                <TableCell>{section.section} {subsection.subsection !== "" ? " - " + subsection.subsection : ""}</TableCell>
                                <TableCell>{subsection.risk ? <Typography style={{color: 'red'}}>Ryzyko ({subsection.score})</Typography> : "Norma (" + subsection.score + ")" } </TableCell>
                            </TableRow>
                        )}
                    </>
                )}
            </TableBody>
        </Table>
    </TableContainer>
}