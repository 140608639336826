import {
    Checkbox,
    FormControlLabel,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField
} from '@material-ui/core'
import * as React from 'react'
import {LFWTestSection} from "../LFWSection";

type Q10Props = {
    section: LFWTestSection
    preview: boolean
}

type Q10State = {
    section: LFWTestSection
}


export class Q10 extends React.Component<Q10Props, Q10State> {
    state: Q10State = {
        section: this.props.section
    }

    public handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (this.state.section.response.hasOwnProperty(event.target.name)) {
            this.state.section.response[event.target.name] = event.target.checked
        }

        if (event.target.name == '< 20cm') {
            this.state.section.response['20-50cm'] = false
        }

        if (event.target.name == '20-50cm') {
            this.state.section.response['< 20cm'] = false
        }

        this.setState(prevState => {
            return {...prevState, section: this.state.section}
        })
    }

    public render = () => {
        return <TableContainer>
            <Table className={'gray-striped'}>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={'< 20cm'}
                                        checked={this.state.section.response['< 20cm']}
                                        onChange={this.handleChange}
                                        disabled={this.props.preview}
                                        color="primary"
                                    />
                                }
                                label={'< 20cm'}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={'20-50cm'}
                                        checked={this.state.section.response['20-50cm']}
                                        onChange={this.handleChange}
                                        disabled={this.props.preview}
                                        color="primary"
                                    />
                                }
                                label={'20-50cm'}
                            />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    }
}