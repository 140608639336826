import {createSelector} from 'reselect'
import {State} from 'lib/store'
import {ThemeState} from './reducer'
import {themes} from './variants'

const selectThemeFeature = (state: State): ThemeState => state.theme

export const selectTheme = createSelector(selectThemeFeature, theme => {
    const found = themes.find(v => v.name === theme.name)
    if (found) {
        return found
    }

    return themes[1]
})
