import * as React from 'react'
import styled from 'styled-components'
import {darken, rgba} from 'polished'
import {Chip, ListItem, ListItemText} from '@material-ui/core'
import {NavLink} from 'react-router-dom'
import {ProtectedRoutes, Routes} from 'lib/router'

type LinkStyledProps = {
    activeClassName: string
    component: unknown
} & { [key: string]: any }

const LinkStyled = styled(ListItem)<LinkStyledProps>`
    padding-left: ${props => props.theme.spacing(14)}px;
    padding-top: ${props => props.theme.spacing(2)}px;
    padding-bottom: ${props => props.theme.spacing(2)}px;
    
    span {
      color: ${props => rgba(props.theme.sidebar.color, 0.7)};
    }
    
    &:hover span {
      color: ${props => rgba(props.theme.sidebar.color, 0.9)};
    }
    
    &.${props => props.activeClassName} {
      background-color: ${props => darken(0.06, props.theme.sidebar.background)};
    
    span {
      color: ${props => props.theme.sidebar.color};
    }
`

const TextStyled = styled(ListItemText)`
  color: ${props => props.theme.sidebar.color};
  span {
    font-size: ${props => props.theme.typography.body1.fontSize}px;
  }
  margin-top: 0;
  margin-bottom: 0;
`

const BadgeStyled = styled(Chip)`
  font-size: 11px;
  font-weight: ${props => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 12px;
  top: 8px;
  background: ${props => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${props => props.theme.sidebar.badge.color};
    padding-left: ${props => props.theme.spacing(2)}px;
    padding-right: ${props => props.theme.spacing(2)}px;
  }
`

type LinkComponentProps = {
    name: string
    to: Routes | ProtectedRoutes | string
    badge?: string
    isVisible?: boolean
}

export const Link: React.FunctionComponent<LinkComponentProps> = ({name, to, badge, isVisible}) => (
    isVisible == undefined || isVisible
        ? <LinkStyled
            button
            dense
            component={NavLink}
            // exact
            to={to}
            activeClassName="active"
        >
            <TextStyled>{name}</TextStyled>
            {badge ? <BadgeStyled label={badge}/> : ""}
        </LinkStyled>
        : <></>
)