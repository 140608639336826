import * as React from 'react'
import {
    Checkbox,
    FormControlLabel,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography
} from '@material-ui/core'

type PreviewProps = {
    data: Data
    preview: boolean
    sectionIndex?: number
}

type SectionState = {
    data: Data
}

interface Item {
    name: string
    value?: any
    groups: string[]
    required: boolean
}

export interface KbmaSection {
    items: Item[]
    section: string
}

interface Data {
    result: string
    sections: KbmaSection[]
}

export class KbmaForm extends React.Component<PreviewProps, SectionState> {
    state: SectionState = {
        data: this.props.data
    }

    public handleItemChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const keys = event.target.name.split('|')
        const sectionKey = parseInt(keys[0]);
        const itemKey = parseInt(keys[1]);
        this.state.data.sections[sectionKey].items[itemKey].value = event.target.checked;
        this.setState(prevState => {
            return {...prevState, data: this.state.data}
        })
    }

    private handleChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.state.data!.result = event.target.value

        this.setState(prevState => {
            return {...prevState, data: this.state.data}
        })
    }

    public render = () => <>
        {this.props.data ?
            <>
                {this.props.sectionIndex == undefined || this.props.sectionIndex < 2 ? <>
                        {this.props.data.sections.map(
                            (section, index) => index == this.props.sectionIndex || this.props.sectionIndex == undefined ?
                                <Table key={index} style={this.props.preview ? {marginBottom: "50px"} : {}}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={2}><Typography variant={"h4"}>{section.section}</Typography></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className={'gray-striped'}>
                                        {section.items.map((item, itemIndex) =>
                                            <TableRow key={index * 10 + itemIndex}>
                                                <TableCell
                                                    style={this.props.preview && item.required && item.value == null ? {color: 'red'} : {}}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                name={index + '|' + itemIndex}
                                                                checked={item.value == true}
                                                                onChange={this.handleItemChange}
                                                                disabled={this.props.preview || false}
                                                                color="primary"
                                                            />
                                                        }
                                                        label={item.name}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                                : ''
                        )}
                    </>
                    : ''}
                {this.props.sectionIndex == 2 || this.props.sectionIndex == undefined ? <>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{whiteSpace: "nowrap"}}><Typography variant={"h4"}>{this.props.preview ? 'Wyniki badania' : 'Wpisz wyniki badania'}</Typography></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className={'gray-striped'}>
                                <TableRow>
                                    <TableCell align={"center"}>
                                        <TextField name={"description"} disabled={this.props.preview || false}
                                                   value={this.state.data?.result} onChange={this.handleChangeDescription} multiline
                                                   variant="outlined" style={{width: '100%'}}/>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </>
                    : ''}
            </>
            : ''}
    </>
}