import {CrudList, FilterType, QualificationsPreview, RolesEnum, SpecialtiesPreview, Status} from "app/Components";
import {State} from "lib/store";
import {TestConfig, testConfigName} from "lib/testConfig";
import * as React from 'react'
import {Source} from "lib/select";
import {Dictionaries} from "lib/dictionaries";
import {rules} from "lib/auth";

const cols = [
    {
        id: "name",
        numeric: false,
        label: "Nazwa",
        sort: false,
        template: (config: TestConfig) => config.name
    },
    {
        id: "roles",
        numeric: false,
        label: "Rola",
        sort: false,
        width: '13%',
        template: (config: TestConfig) => <RolesEnum ids={config.roles} />
    },
    {
        id: "specialties",
        numeric: false,
        label: "Specjalizacje",
        sort: false,
        width: '13%',
        template: (config: TestConfig) => <SpecialtiesPreview ids={config.specialties}/>
    },
    {
        id: "qualifications",
        numeric: false,
        label: "Uprawnienia",
        sort: false,
        width: '13%',
        template: (config: TestConfig) => <QualificationsPreview ids={config.qualifications} />
    },
    {
        id: "starting_package",
        numeric: false,
        label: "Test startowy",
        sort: false,
        width: '150px',
        template: (config: TestConfig) => (<Status status={config.startingPackage} activeLabel={'Tak'} inactiveLabel={'Nie'}/>)
    },
    {
        id: "available_for_parents",
        numeric: false,
        label: "Dostępny dla rodziców",
        sort: false,
        width: '150px',
        template: (config: TestConfig) => (<Status status={config.availableForParents} activeLabel={'Tak'} inactiveLabel={'Nie'}/>)
    },
    {
        id: "active",
        numeric: false,
        label: "Status",
        sort: false,
        width: '50px',
        template: (config: TestConfig) => (<Status status={config.active} activeLabel={'Aktywny'} inactiveLabel={'Nieaktywny'}/>),
        rule: rules.testConfig.status
    },
]

export class List extends React.Component {
    public render = () => (
        <CrudList
            actionName={testConfigName}
            cols={cols}
            selector={(state: State) => state.testConfig}
            deleteDialogText={""}
            rules={rules.testConfig}
            filter={{
                initialValues: {roles: [], specialties: [], qualifications: [], active: '1'},
                fields: [
                    {name: 'specialties', label: 'Specjalizacje', type: FilterType.MULTIPLE_SELECT, source: Source.DICTIONARIES, requestOptions: {type: Dictionaries.Specialty} },
                    {name: 'qualifications', label: 'Uprawnienia', type: FilterType.MULTIPLE_SELECT, source: Source.DICTIONARIES, requestOptions: {type: Dictionaries.Qualification} },
                    {name: 'roles', label: 'Rola', type: FilterType.MULTIPLE_SELECT, source: Source.ROLES},
                    {name: 'active', label: 'Status', type: FilterType.SELECT, source: Source.STATUS, requestOptions: {type: Source.TEST_CONFIG}, rule: rules.testConfig.status}
                ]
            }}
        />
    )
}