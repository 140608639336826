import {ListItemIcon, MenuItem} from '@material-ui/core'
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import * as React from 'react'

type DeleteMenuItemProps = {
    onClickHandler: (event: any) => void
}

export class DeleteMenuItem extends React.Component<DeleteMenuItemProps> {
    public render = () =>
        <MenuItem
            selected={false}
            onClick={this.props.onClickHandler}
        >
            <ListItemIcon>
                <DeleteIcon/>
            </ListItemIcon>
            Usuń
        </MenuItem>
}
