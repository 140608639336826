import {
    AppBar as MuiAppBar,
    Grid as MuiGrid,
    Hidden,
    IconButton as MuiIconButton,
    Toolbar,
    Typography
} from '@material-ui/core'
import {Menu as MenuIcon} from '@material-ui/icons'
import {spacing} from "@material-ui/system";
import {LinearProgressBar} from 'app/Components/ProgressBar'
import {me} from 'lib/auth'
import {AuthAction} from 'lib/auth/actions'
import {getRow, RouteType} from 'lib/crud'
import {getAction, getHeader, getSubheader} from 'lib/navigation'
import {isLoading, isProcessing} from 'lib/progress'
import {generateRouterCrudPath, ProtectedRoutes} from 'lib/router'
import {State} from 'lib/store'
import {Test, testName} from 'lib/test'
import {selectTheme, Theme} from 'lib/theme'
import {User} from 'lib/users'
import * as React from 'react'
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux'
import {Route, Switch} from 'react-router'
import styled from 'styled-components'
import {PreviewHeader} from './PreviewHeader'
import {TestHeader} from './TestHeader'

const AppBar = styled(MuiAppBar)`
  background: ${props => props.theme.header.background};
  color: ${props => props.theme.header.color};
  box-shadow: ${props => props.theme.shadows[1]};
`

const Grid = styled(MuiGrid)(spacing)

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`

type StateToProps = {
    readonly theme: Theme
    readonly me: User | null
    isLoading: boolean
    isProcessing: boolean
    header: string
    subheader: string
    action?: JSX.Element,
    test: Test
}

type DispatchToProps = {
    readonly logout: () => void,
}

type HeaderProps = StateToProps & DispatchToProps & {
    onDrawerToggle: () => void
    onTestMenuToggle?: () => void
}

class HeaderComponent extends React.Component<HeaderProps> {
    public render() {
        return (
            <>
                <AppBar position="sticky" elevation={0}>
                    <LinearProgressBar isVisible={this.props.isLoading || this.props.isProcessing}/>
                    <Toolbar style={{padding: '10px', borderBottom: 'solid 1px rgba(224, 224, 224, 1)'}}>
                        <Grid container alignItems="center" spacing={2}>
                            <Hidden lgUp>
                                <Grid item>
                                    <IconButton
                                        color="inherit"
                                        onClick={this.props.onDrawerToggle}
                                    >
                                        <MenuIcon/>
                                    </IconButton>
                                </Grid>
                            </Hidden>

                            <Switch>
                                <Route path={generateRouterCrudPath(testName, RouteType.EDIT)}>
                                    {this.props.onTestMenuToggle != null ?
                                        <div style={{position: "absolute", right: 0, top: 0, background: '#1B2430'}}>
                                            <IconButton
                                                color="inherit"
                                                onClick={this.props.onTestMenuToggle}
                                            >
                                                <MenuIcon/>
                                            </IconButton>
                                        </div> : null}
                                    <TestHeader/>
                                </Route>
                                <Route path={ProtectedRoutes.TestCompareSchools}>
                                    <Grid item xs={11} sm={11} md={11} lg={12} style={{paddingTop: '10px'}}>
                                        <Typography variant="h4" style={{color: 'rgba(0,0,0,0.87)'}}>
                                            Analiza placówek
                                        </Typography>
                                    </Grid>
                                </Route>
                                <Route path={ProtectedRoutes.TestCompareGroups}>
                                    <Grid item xs={11} sm={11} md={11} lg={12} style={{paddingTop: '10px'}}>
                                        <Typography variant="h4" style={{color: 'rgba(0,0,0,0.87)'}}>
                                            Analiza grup
                                        </Typography>
                                    </Grid>
                                </Route>
                                <Route path={ProtectedRoutes.TestCompareChildren}>
                                    <Grid item xs={11} sm={11} md={11} lg={12} style={{paddingTop: '10px'}}>
                                        <Typography variant="h4" style={{color: 'rgba(0,0,0,0.87)'}}>
                                            Analiza dzieci
                                        </Typography>
                                    </Grid>
                                </Route>
                                <Route path={ProtectedRoutes.TestPreview}>
                                    <PreviewHeader/>
                                </Route>
                                <Route path={'/'}>
                                    <Grid item pl={7}>
                                        <Typography
                                            variant="h4"
                                            gutterBottom
                                            display="inline"
                                            style={{color: 'rgba(0,0,0,0.87)'}}
                                        >
                                            {this.props.header}
                                        </Typography>
                                        <Typography
                                            variant="h6"
                                            gutterBottom
                                            display="inline"
                                            style={{color: 'rgba(0,0,0,0.6)'}}
                                        >
                                            {this.props.subheader != '' ? ': ' + this.props.subheader : ''}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs pl={3}>

                                    </Grid>

                                    <Grid item pr={7}>
                                        {this.props.action ? this.props.action : <></>}
                                    </Grid>
                                </Route>
                            </Switch>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </>
        )
    }

}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    theme: selectTheme(state),
    me: me(state),
    isLoading: isLoading(state),
    isProcessing: isProcessing(state),
    header: getHeader(state),
    subheader: getSubheader(state),
    action: getAction(state),
    test: getRow(state => state.tests)(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    logout: () => {
        dispatch(AuthAction.logout())
    },
})

export const Header = connect(mapStateToProps, mapDispatchToProps)(HeaderComponent)