import {
    Button,
    Card,
    CardContent as MuiCardContent,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    TextField
} from '@material-ui/core'
import {spacing} from '@material-ui/system'
import {Autocomplete, CircularProgressBar, LinearProgressBar} from 'app/Components'
import {onKeyDown} from 'external-lib/utils/helpers'
import {Formik} from 'formik'
import {isLoading, isProcessing} from 'lib/progress'
import {Source} from 'lib/select'
import {State} from 'lib/store'
import {Subject} from 'lib/subjects'
import React from 'react'
import {connect, MapStateToProps} from 'react-redux'
import styled from 'styled-components'
import * as Yup from 'yup'
import {User} from "lib/users";
import {Student} from "lib/students";

const CardContent = styled(MuiCardContent)(spacing)

type StateToProps = {
    readonly isLoading: boolean
    readonly isProcessing: boolean
}

type FormControlProps = {
    initialValues: Student
    handleSubmit: (student: Student) => void
}

const Schema = Yup.object().shape({
    // parents: Yup.array()
    //     .required('Pole jest wymagane'),
})

class FormComponent extends React.Component<FormControlProps & StateToProps> {
    public render = () =>
        <>
            {
                this.props.isLoading
                    ? <CircularProgressBar/>
                    : <Formik
                        initialValues={this.props.initialValues}
                        validationSchema={Schema}
                        validateOnChange={false}
                        onSubmit={values => this.props.handleSubmit(values)}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                              setFieldValue
                          }) => (
                            <form onSubmit={handleSubmit} onKeyDown={onKeyDown}>

                                <Card variant="outlined">
                                    <CardContent>

                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl margin="dense" fullWidth>
                                                    <Autocomplete
                                                        label={"Nauczyciele indywidualni *"}
                                                        field={'individualTeachers'}
                                                        source={Source.TEACHERS}
                                                        value={values.individualTeachers}
                                                        error={Boolean(errors.individualTeachers && touched.individualTeachers)}
                                                        setFieldValue={setFieldValue}
                                                        multiple
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleSubmit()}
                                                    disabled={this.props.isProcessing}
                                                >
                                                    Zapisz
                                                </Button>
                                            </Grid>
                                        </Grid>

                                    </CardContent>
                                </Card>
                            </form>
                        )}
                    </Formik>
            }
        </>
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    isLoading: isLoading(state),
    isProcessing: isProcessing(state),
})

export const Form = connect(mapStateToProps)(FormComponent)