import {Button} from '@material-ui/core'
import BackIcon from '@material-ui/icons/ArrowBack'
import * as React from 'react'
import {NavLink} from 'react-router-dom'

type BackButtonProps = {
    label?: string
    url: string
}

export class BackButton extends React.Component<BackButtonProps> {
    public render = () => (
        <Button
            variant="contained"
            color="primary"
            component={NavLink} to={this.props.url}
            startIcon={<BackIcon/>}
        >
            {this.props.label || 'Anuluj'}
        </Button>)
}