import {Grid, Typography} from '@material-ui/core'
import {TestConfigPreview} from 'app/Components'
import {getRow} from 'lib/crud'
import {isLoading} from 'lib/progress'
import {State} from 'lib/store'
import {Test} from 'lib/test'
import * as React from 'react'
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux'
import {withRouter} from 'react-router'
import {RouteComponentProps} from 'react-router-dom'

type StateToProps = {
    readonly test: Test
    readonly isLoading: boolean
}

type DispatchToProps = {}

type Params = {
    parentId: string
    id: string
}

class PreviewHeaderComponent extends React.Component<StateToProps & DispatchToProps & RouteComponentProps<Params>> {
    public render = () => <Grid item xs={11} sm={11} md={11} lg={12} style={{paddingTop: '10px'}}>
        <Typography
            variant="h4"
            style={{color: 'rgba(0,0,0,0.87)'}}
        >
            Podgląd testu: <TestConfigPreview id={this.props.test.config}/>
        </Typography>
    </Grid>
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    test: getRow(state => state.tests)(state),
    isLoading: isLoading(state)
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({})

export const PreviewHeader = withRouter(connect(mapStateToProps, mapDispatchToProps)(PreviewHeaderComponent))