import {createSelector} from 'reselect'
import {State} from 'lib/store'
import {TestState} from 'lib/test'

const selectTestsFeature = (state: State): TestState => state.tests

export const getTestEvaluation = createSelector(selectTestsFeature, tests => tests.evaluation)
export const getTestSummary = createSelector(selectTestsFeature, tests => tests.summary)
export const isTestCorrect = createSelector(selectTestsFeature, tests => tests.isCorrect)
export const getFirstInvalidSection = createSelector(selectTestsFeature, tests => tests.firstInvalidSection)
export const getComparison = createSelector(selectTestsFeature, tests => tests.comparison)
export const getComparisonFilter = createSelector(selectTestsFeature, tests => tests.comparisonFilter)