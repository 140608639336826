export type Dictionary = {
    id: string
    type: string
    name: string
    active: boolean
}

export enum Dictionaries {
    Specialty = 'specialization',
    DisabilityType = 'disability',
    Event = 'event',
    Qualification = 'qualifications'
}