import {Metadata} from 'lib/dto'

export type StudentFile = {
    id: string
    name: string,
    mimeType: string,
    size: number,
    needsConfirmation: boolean,
    student: string,
    type: string,
    createdAt: string,
    createdBy: string,
    confirmedAt: string,
    confirmedBy: string,
    metadata?: Metadata
}

export type UploadFile = {
    student: string
    type: string
    file: File|null
}

export const newUploadFile = (student: string): UploadFile => ({
    student: student,
    type: '',
    file: null
})