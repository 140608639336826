import {AnyAction} from 'redux'
import {crudReducer, CrudState, initialCrudState} from 'lib/crud'
import {DictionaryActionType} from 'lib/dictionaries'

export const name = 'DICTIONARY'

export type DictionariesState = {
    crud: CrudState,
    type: string
}

const initialState = {
    crud: initialCrudState,
    type: ''
}

export function dictionariesReducer(state: DictionariesState = initialState, action: AnyAction): DictionariesState {
    switch (action.type) {
        case DictionaryActionType.setType():
            return {
                ...state,
                type: action.payload
            }
        default:
            return {
                ...state,
                crud: crudReducer(name, state.crud, action)
            }
    }
}
