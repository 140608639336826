import {CircularProgressBar, CrudEditHeader} from 'app/Components'
import {CrudAction, getRow, Type} from 'lib/crud'
import {isLoading} from 'lib/progress'
import {State} from 'lib/store'
import {name as studentsName, Student} from 'lib/students'
import {Test, testName} from 'lib/test'
import * as React from 'react'
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux'
import {withRouter} from 'react-router'
import {RouteComponentProps} from 'react-router-dom'
import {selectConfig} from 'lib/testConfig'
import {Formik} from "formik";
import {Button, Card, CardContent} from "@material-ui/core";

type StateToProps = {
    readonly student: Student
    readonly test: Test
    readonly isLoading: boolean
}

type DispatchToProps = {
    readonly load: (actionName: string, id: string) => void,
    readonly clear: (actionName: string) => void,
    readonly handleSubmit: (test: Test) => void,
}

type Params = {
    parentId: string
    id: string
}

class PreviewComponent extends React.Component<StateToProps & DispatchToProps & RouteComponentProps<Params>> {
    public componentDidMount(): void {
        if (this.props.match.params.parentId) {
            this.props.load(studentsName, this.props.match.params.parentId)
        }
        if (this.props.match.params.id) {
            this.props.load(testName, this.props.match.params.id)
        }
    }

    public componentWillUnmount() {
        this.props.clear(testName)
    }

    public render = () => {
        const testConfig = selectConfig(this.props.test.config)

        if (testConfig === undefined) {
            return <></>
        }

        return <>
            <CrudEditHeader actionName={testName}/>
            {this.props.isLoading
                ? <CircularProgressBar/>
                :
                this.props.test && this.props.test.content ? <Formik
                    initialValues={this.props.test}
                    onSubmit={values => this.props.handleSubmit(values)}
                    >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue
                      }) => {
                        return (<form id={"test-form"} onSubmit={handleSubmit}>
                            <Card variant={'outlined'} style={{marginTop: '10px'}}>
                                <CardContent>
                                    {values.content && testConfig.form !== null?
                                        React.createElement(testConfig.form, {data: values.content.data, preview: true})
                                        : ''
                                    }
                                </CardContent>
                            </Card>
                        </form>)
                    }}
                    </Formik> : <CircularProgressBar/>
            }
        </>
    }
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    student: getRow(state => state.students)(state),
    test: getRow(state => state.tests)(state),
    isLoading: isLoading(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    load: (actionName: string, id: string) => {
        dispatch(CrudAction.execute(actionName, Type.GET, {id}))
    },
    clear: (actionName: string) => {
        dispatch(CrudAction.executeResponseSuccess(actionName, Type.GET, {}))
    },
    handleSubmit: (test: Test) => {
        let t = {...test, published: true}
        const testId = t.id
        delete t.id
        dispatch(CrudAction.execute(testName, Type.UPDATE, {
            id: testId,
            data: t
        }))
    }
})

export const Preview = withRouter(connect(mapStateToProps, mapDispatchToProps)(PreviewComponent))