import {Box, Table, TableBody, TableCell, TableHead, TableRow, TextField} from "@material-ui/core";
import * as React from 'react'
import {OdfTestSurveySection} from ".";

type SectionProps = {
    index: number
    section: OdfTestSurveySection
    preview?: boolean
    prev: null|number
    next: null|number
}

type SectionState = {
    section: OdfTestSurveySection
}

export class OdfDescriptionSection extends React.Component<SectionProps, SectionState> {
    state: SectionState = {
        section: this.props.section
    }

    public handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.state.section.items[parseInt(event.target.name)].description = event.target.value;
        this.setState(prevState => {
             return {...prevState, section: this.state.section}
         })
    }

    public render = () => <Box style={this.props.preview ? {margin: '0px 0px 50px 0px'} : {}}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell colSpan={2} style={{whiteSpace: "nowrap", width: '20%'}}>Czyność ruchowa</TableCell>
                    <TableCell style={{width: '55%'}}>Opis</TableCell>
                    <TableCell style={{width: '20%'}}>Wskazówki</TableCell>
                    <TableCell style={{width: '5%'}}>Wiek/Uwagi</TableCell>
                </TableRow>
            </TableHead>
            <TableBody className={'gray-striped'}>
                {this.props.section.items.map(
                    (item, index) =>
                        <TableRow key={index}>
                            <TableCell align={'right'}
                                       style={{width: '50px'}}>{index + 1}.</TableCell>
                            <TableCell>
                                {item.activity}
                            </TableCell>
                            <TableCell>
                                <TextField name={index.toString()} disabled={this.props.preview || false}
                                           value={item.description} onChange={this.handleChange} multiline
                                           variant="outlined" style={{width: '100%'}}/>
                            </TableCell>
                            <TableCell>
                                {item.hints}
                            </TableCell>
                            <TableCell>
                                {item.age}
                            </TableCell>
                        </TableRow>
                )}
            </TableBody>
        </Table>
    </Box>
}