import {createSelector} from 'reselect'
import {State} from 'lib/store'
import {SelectState} from 'lib/select'

const selectSelectFeature = (state: State): SelectState => state.select

export const getOptions = (selectName: string) => createSelector(selectSelectFeature, state => {
    return state[selectName] !== undefined && 'options' in state[selectName] ? state[selectName].options : []
})

export const getSelected = (selectName: string) => createSelector(selectSelectFeature, state => {
    return state[selectName] !== undefined && 'selected' in state[selectName] ? state[selectName].selected : []
})

export const isLoading = (selectName: string) => createSelector(selectSelectFeature, state => {
    return state[selectName] !== undefined && 'loading' in state[selectName] ? state[selectName].loading > 0 : false
})
