import {ActionsUnion, createAction} from 'external-lib/redux-utils'
import {
    LoginRequest,
    PasswordResetChangeRequest,
    PasswordResetInitiateRequest,
    PasswordResetValidateRequest
} from "client/apis";
import {Me} from "client/models";
import {User} from "../users";

export enum AuthActionType {
    Initialize = 'INITIALIZE',
    InitializeFailure = 'INITIALIZE_FAILURE',
    InitializeSuccess = 'INITIALIZE_SUCCESS',

    Login = 'LOGIN',
    LoginFailure = 'LOGIN_FAILURE',
    LoginSuccess = 'LOGIN_SUCCESS',

    Me = 'ME',
    MeFailure = 'ME_FAILURE',
    MeSuccess = 'ME_SUCCESS',

    UserInitializeFailure = 'USER_INITIALIZE_FAILURE',
    UserInitializeSuccess = 'USER_INITIALIZE_SUCCESS',

    RefreshToken = 'REFRESH_TOKEN',
    RefreshTokenFailure = 'REFRESH_TOKEN_FAILURE',
    RefreshTokenSuccess = 'REFRESH_TOKEN_SUCCESS',

    PasswordResetInitialize = 'PASSWORD_RESET_INITIALIZE',
    PasswordResetInitializeFailure = 'PASSWORD_RESET_INITIALIZE_FAILURE',
    PasswordResetInitializeSuccess = 'PASSWORD_RESET_INITIALIZE_SUCCESS',

    PasswordTokenValidation = 'PASSWORD_TOKEN_VALIDATION',
    PasswordTokenValidationFailure = 'PASSWORD_TOKEN-VALIDATION_FAILURE',
    PasswordTokenValidationSuccess = 'PASSWORD_TOKEN_VALIDATION_SUCCESS',

    PasswordChange = 'PASSWORD_CHANGE',
    PasswordChangeFailure = 'PASSWORD_CHANGE_FAILURE',
    PasswordChangeSuccess = 'PASSWORD_CHANGE_SUCCESS',

    Logout = 'LOGOUT',
    LogoutSuccess = 'LOGOUT_SUCCESS',

    SetRedirectAfterLogin = `[DATA] Set redirect after login`,
}

export const AuthAction = {
    initialize: () => createAction(AuthActionType.Initialize),
    initializeFailure: (error: any) => createAction(AuthActionType.InitializeFailure, error),
    initializeSuccess: (response: any) => createAction(AuthActionType.InitializeSuccess, response),

    login: (loginRequest: LoginRequest) => createAction(AuthActionType.Login, loginRequest),
    loginFailure: (error: any) => createAction(AuthActionType.LoginFailure, error),
    loginSuccess: (response: Me) => createAction(AuthActionType.LoginSuccess, response),

    me: () => createAction(AuthActionType.Me),
    meFailure: (error: any) => createAction(AuthActionType.MeFailure, error),
    meSuccess: (response: Me) => createAction(AuthActionType.MeSuccess, response),

    userInitializeFailure: (error: string) => createAction(AuthActionType.UserInitializeFailure, error),
    userInitializeSuccess: (user: User) => createAction(AuthActionType.UserInitializeSuccess, user),

    refreshToken: () => createAction(AuthActionType.RefreshToken),
    refreshTokenFailure: (error: string) => createAction(AuthActionType.RefreshTokenFailure, error),
    refreshTokenSuccess: (response: void) => createAction(AuthActionType.RefreshTokenSuccess, response),

    passwordResetInitialization: (passwordResetRequest: PasswordResetInitiateRequest) => createAction(AuthActionType.PasswordResetInitialize, passwordResetRequest),
    passwordResetInitializationFailure: (error: string) => createAction(AuthActionType.PasswordResetInitializeFailure, error),
    passwordResetInitializationSuccess: (response: void) => createAction(AuthActionType.PasswordResetInitializeSuccess, response),

    passwordResetValidation: (passwordResetValidationRequest: PasswordResetValidateRequest) => createAction(AuthActionType.PasswordTokenValidation, passwordResetValidationRequest),
    passwordResetValidationFailure: (error: string) => createAction(AuthActionType.PasswordTokenValidationFailure, error),
    passwordResetValidationSuccess: (response: void) => createAction(AuthActionType.PasswordTokenValidationSuccess, response),

    passwordResetChange: (passwordResetChangeRequest: PasswordResetChangeRequest) => createAction(AuthActionType.PasswordChange, passwordResetChangeRequest),
    passwordResetChangeFailure: (error: string) => createAction(AuthActionType.PasswordChangeFailure, error),
    passwordResetChangeSuccess: (response: void) => createAction(AuthActionType.PasswordChangeSuccess, response),

    logout: () => createAction(AuthActionType.Logout),
    logoutSuccess: () => createAction(AuthActionType.LogoutSuccess),

    setRedirectAfterLogin: (path: string) => createAction(AuthActionType.SetRedirectAfterLogin, path),
}

export type AuthAction = ActionsUnion<typeof AuthAction>
