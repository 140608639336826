import {configuration} from 'lib/client'
import {TestApi} from 'client/apis';

const TestApiClient = (): TestApi => {
    return new TestApi(configuration)
}

export const dao = {
    list: (payload: any) => {
        return TestApiClient().getTestCollection(payload)
    },
    get: (payload: any) => {
        return TestApiClient().getTestItem({id: payload.id})
    },
    evaluate: (payload: any) => {
        return TestApiClient().evaluateTestItem({id: payload.id})
    },
    summary: (payload: any) => {
        return TestApiClient().summaryTestItem({id: payload.id})
    },
    update: (payload: any) => {
        return TestApiClient().putTestItem({id: payload.id, testItemputWrite: payload.data})
    },
    delete: (payload: any) => {
        return TestApiClient().deleteTestItem({id: payload.id})
    },
    create: (payload: any) => {
        return TestApiClient().postTestCollection({testCollectionpostWrite: payload})
    },
    compare: (payload: any) => {
        return TestApiClient().compareTestCollection(payload)
    },
}