import {Button} from '@material-ui/core'
import * as React from 'react'
import {NavLink} from 'react-router-dom'
import styled from 'styled-components'
import {AccessRule} from "lib/auth";

type Bookmark = {
    label: string
    icon: JSX.Element
    to: string
    exact?: boolean
    rule?: AccessRule
}

type TabsProps = {
    bookmarks: Bookmark[]
}

type ButtonStyledProps = { [key: string]: any }

const ButtonStyled = styled(Button)<ButtonStyledProps>`
    -webkit-border-radius: 3px;
    -webkit-border-bottom-right-radius: 0px;
    -webkit-border-bottom-left-radius: 0px;
    -moz-border-radius: 3px;
    -moz-border-radius-bottomright: 0px;
    -moz-border-radius-bottomleft: 0px;
    border-radius: 3px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    
    -webkit-box-shadow: inset 0px 1px 1px 1px rgba(0,0,0,0.1); 
    box-shadow: inset 0px 1px 1px 1px rgba(0,0,0,0.1);
    
    padding-left: 10px;
    padding-right: 10px;
    
    background: #FFFFFF;

    &.${props => props.activeClassName} {
        background-color: #1976d2;
        
        span {
          color: #FFFFFF;
        }
    }
`

export class Bookmarks extends React.Component<TabsProps> {
    public render = () => {
        return <>
            {this.props.bookmarks.map(
                (tab, index) => <ButtonStyled
                    key={index}
                    startIcon={tab.icon}
                    component={NavLink}
                    to={tab.to}
                    exact={tab.exact || false}
                    activeClassName="active"
                >{tab.label}</ButtonStyled>, this)}
        </>
    }
}