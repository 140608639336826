import {Metadata} from 'lib/dto'

export type Subject = {
    id: string
    name: string
    category: string
    active: boolean
    metadata?: Metadata
}

export const newSubject = (): Subject => ({
    id: '',
    name: '',
    category: '',
    active: false
})
