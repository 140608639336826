import {
    Checkbox,
    FormControlLabel,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField
} from '@material-ui/core'
import * as React from 'react'
import {LFWTestSection} from "../LFWSection";

type Q11Props = {
    section: LFWTestSection
    preview: boolean
}

type Q11State = {
    section: LFWTestSection
}


export class Q11 extends React.Component<Q11Props, Q11State> {
    state: Q11State = {
        section: this.props.section
    }

    public handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (this.state.section.response.hasOwnProperty(event.target.name)) {
            this.state.section.response[event.target.name] = event.target.checked
        }

        if (event.target.name == 'normalna') {
            this.state.section.response['podniesiona'] = false
            this.state.section.response['opuszczona'] = false
            this.state.section.response['skierowana na lewo/prawo'] = false
        }

        if (event.target.name != 'normalna') {
            this.state.section.response['normalna'] = false
        }

        this.setState(prevState => {
            return {...prevState, section: this.state.section}
        })
    }

    public handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.state.section.response[event.target.name] = event.target.value;
        this.setState(prevState => {
            return {...prevState, section: this.state.section}
        })
    }

    public render = () => {
        return <TableContainer>
            <Table className={'gray-striped'}>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={'normalna'}
                                        checked={this.state.section.response['normalna']}
                                        onChange={this.handleChange}
                                        disabled={this.props.preview}
                                        color="primary"
                                    />
                                }
                                label={'normalna'}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={'podniesiona'}
                                        checked={this.state.section.response['podniesiona']}
                                        onChange={this.handleChange}
                                        disabled={this.props.preview}
                                        color="primary"
                                    />
                                }
                                label={'głowa do góry (podniesiona)'}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={'opuszczona'}
                                        checked={this.state.section.response['opuszczona']}
                                        onChange={this.handleChange}
                                        disabled={this.props.preview}
                                        color="primary"
                                    />
                                }
                                label={'głowa w dół (opuszczona)'}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={'skierowana na lewo/prawo'}
                                        checked={this.state.section.response['skierowana na lewo/prawo']}
                                        onChange={this.handleChange}
                                        disabled={this.props.preview}
                                        color="primary"
                                    />
                                }
                                label={'głowa skierowana na lewo/prawo'}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Grid container direction="row"
                                  justify="flex-start"
                                  alignItems="center" spacing={3}>
                                <Grid item>
                                    inna
                                </Grid>
                                <Grid item sm>
                                    <TextField
                                        name="inna"
                                        variant="outlined"
                                        inputProps={{
                                            maxLength: 250,
                                        }}
                                        value={this.state.section.response['inna']}
                                        onChange={this.handleTextChange}
                                        multiline
                                        fullWidth
                                        disabled={this.props.preview}
                                    />
                                </Grid>
                            </Grid>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    }
}