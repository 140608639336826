import {Button} from '@material-ui/core'
import EditIcon from '@material-ui/icons/EditOutlined'
import * as React from 'react'
import {NavLink} from 'react-router-dom'

type EditButtonProps = {
    to: string
}

export class EditButton extends React.Component<EditButtonProps> {
    public render = () => <Button size="small"
                                  component={NavLink}
                                  color={'primary'}
                                  to={this.props.to}
                                  startIcon={<EditIcon/>}>Edytuj</Button>
}
