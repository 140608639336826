import {CrudAddHeader} from 'app/Components'
import {CrudAction, Type} from 'lib/crud'
import {name, newSchool, School} from 'lib/schools'
import * as React from 'react'
import {connect, MapDispatchToProps} from 'react-redux'
import {Form} from './Form'

type DispatchToProps = {
    readonly handleSubmit: (school: School) => void,
}

type AddProps = DispatchToProps

class AddComponent extends React.Component<AddProps> {
    public render = () => (<>
        <CrudAddHeader actionName={name}/>
        <Form initialValues={newSchool()} handleSubmit={this.props.handleSubmit}/>
    </>)
}

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    handleSubmit: (school: School) => {
        delete school.id

        dispatch(CrudAction.execute(name, Type.CREATE, school))
    },
})

export const Add = connect(null, mapDispatchToProps)(AddComponent)