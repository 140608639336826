import {push} from 'connected-react-router'
import {IActionWithPayload} from 'external-lib/redux-utils'
import {CrudAction, CrudActionType, Type} from 'lib/crud/actions'
import {AnyAction} from 'redux'
import {Epic, ofType} from 'redux-observable'
import {Observable, of} from 'rxjs'
import {catchError, debounceTime, map, switchMap, withLatestFrom} from 'rxjs/operators'
import {State} from 'lib/store'

type CrudEpic<R, P, I, O> = (apiCall: (payload: P) => Observable<R>) => Epic<AnyAction, IActionWithPayload<string, O>>

export const executeCrudEpic: <R, P, I, O>(name: string, type: Type) => CrudEpic<R, P, I, O> = (name: string, type: Type) => apiCall => action$ => action$.pipe(
    ofType(CrudActionType.execute(name, type)),
    switchMap(({payload}) => apiCall(payload).pipe(
        map(response => CrudAction.executeResponseSuccess(name, type, response)),
        catchError(error => of(CrudAction.executeResponseFailure(name, type, error))),
    ))
)

export const redirectAfterSuccessEpic: (name: string, type: Type, getTo: (action: any, state: any) => string) => Epic<AnyAction, any, State> = (name: string, type: Type, getTo: (action: any, state: any) => string) => (action$, state$) => action$.pipe(
    ofType(CrudActionType.executeResponseSuccess(name, type)),
    withLatestFrom(state$),
    map(([action, state]) => push(getTo(action, state))),
)

export const redirectAfterFailureEpic: (name: string, type: Type, getTo: (action: any, state: any) => string) => Epic<AnyAction, any, State> = (name: string, type: Type, getTo: (action: any, state: any) => string) => (action$, state$) => action$.pipe(
    ofType(CrudActionType.executeResponseFailure(name, type)),
    withLatestFrom(state$),
    map(([action, state]) => push(getTo(action, state))),
)

export const dispatchAfterSuccessEpic: (name: string, type: Type, getAction: (action: any, state: any) => IActionWithPayload<any, any>) => Epic<AnyAction, any, State> = (name: string, type: Type, getAction: (action: any, state: any) => IActionWithPayload<any, any>) => (action$, state$) => action$.pipe(
    ofType(CrudActionType.executeResponseSuccess(name, type)),
    withLatestFrom(state$),
    map(([action, state]) => getAction(action, state)),
)

export const dispatchAfterFailureEpic: (name: string, type: Type, getAction: (action: any, state: any) => IActionWithPayload<any, any>) => Epic<AnyAction, any, State> = (name: string, type: Type, getAction: (action: any, state: any) => IActionWithPayload<any, any>) => (action$, state$) => action$.pipe(
    ofType(CrudActionType.executeResponseFailure(name, type)),
    withLatestFrom(state$),
    map(([action, state]) => getAction(action, state)),
)

export const dispatchAfterFilterEpic: (name: string, getAction: (action: any, state: any) => IActionWithPayload<any, any>) => Epic<AnyAction, any, State> = (name: string, getAction: (action: any, state: any) => IActionWithPayload<any, any>) => (action$, state$) => action$.pipe(
    ofType(CrudActionType.filter(name)),
    debounceTime(50),
    withLatestFrom(state$),
    map(([action, state]) => getAction(action, state)),
)