import {
    Button,
    Card,
    CardContent as MuiCardContent,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    TextField
} from '@material-ui/core'
import {KeyboardDatePicker} from '@material-ui/pickers'
import {spacing} from '@material-ui/system'
import {Autocomplete, CircularProgressBar} from 'app/Components'
import {onKeyDown} from 'external-lib/utils/helpers'
import {Formik} from 'formik'
import {Dictionaries} from 'lib/dictionaries'
import {isLoading, isProcessing} from 'lib/progress'
import {Source} from "lib/select";
import {State} from 'lib/store'
import {Student} from "lib/students";
import React from 'react'
import {connect, MapStateToProps} from 'react-redux'
import styled from 'styled-components'
import * as Yup from 'yup'
import {format} from "date-fns";

const CardContent = styled(MuiCardContent)(spacing)

type StateToProps = {
    readonly isLoading: boolean
    readonly isProcessing: boolean
}

type FormControlProps = {
    initialValues: Student
    handleSubmit: (student: Student) => void
}

const Schema = Yup.object().shape({
    firstName: Yup.string()
        .required('Pole jest wymagane')
        .max(50, 'Maksymalna długość to 50 znaków'),
    lastName: Yup.string()
        .required('Pole jest wymagane')
        .max(100, 'Maksymalna długość to 100 znaków'),
    birthDate: Yup.date()
        .required('Pole jest wymagane'),
    educationStartDate: Yup.date()
        .required('Pole jest wymagane'),
    school: Yup.string()
        .required('Pole jest wymagane'),
    category: Yup.string()
        .required('Pole jest wymagane'),
    disabilities: Yup.string()
        .required('Pole jest wymagane'),
    groups: Yup.string()
        .required('Pole jest wymagane'),
})

class FormComponent extends React.Component<FormControlProps & StateToProps> {
    public render = () =>
        <>
            {
                this.props.isLoading
                    ? <CircularProgressBar/>
                    : <Formik
                        initialValues={this.props.initialValues}
                        validationSchema={Schema}
                        validateOnChange={false}
                        onSubmit={values => this.props.handleSubmit(values)}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                              setFieldValue
                          }) => (
                            <form onSubmit={handleSubmit} onKeyDown={onKeyDown}>

                                <Card variant="outlined">
                                    <CardContent>

                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl margin="dense" fullWidth>
                                                    <TextField
                                                        error={Boolean(errors.firstName && touched.firstName)}
                                                        label="Imię *"
                                                        id="firstName"
                                                        name="firstName"
                                                        variant="outlined"
                                                        inputProps={{
                                                            maxLength: 50,
                                                        }}
                                                        value={values.firstName}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        helperText={errors.firstName}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl margin="dense" fullWidth>
                                                    <TextField
                                                        error={Boolean(errors.lastName && touched.lastName)}
                                                        label="Nazwisko *"
                                                        id="lastName"
                                                        name="lastName"
                                                        variant="outlined"
                                                        inputProps={{
                                                            maxLength: 100,
                                                        }}
                                                        value={values.lastName}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        helperText={errors.lastName}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl margin="dense" fullWidth>
                                                    <KeyboardDatePicker
                                                        error={Boolean(errors.birthDate && touched.birthDate)}
                                                        format="dd-MM-yyyy"
                                                        maxDate={format(new Date(), "dd-MM-yyyy")}
                                                        helperText={errors.birthDate}
                                                        id="birthDate"
                                                        label="Data urodzenia (dd-mm-rrrr)*"
                                                        value={values.birthDate || null}
                                                        onChange={val => {
                                                            setFieldValue("birthDate", val);
                                                        }}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'zmień datę',
                                                        }}
                                                        autoOk
                                                        cancelLabel="Anuluj"
                                                        inputVariant="outlined"
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl margin="dense" fullWidth>
                                                    <KeyboardDatePicker
                                                        error={Boolean(errors.educationStartDate && touched.educationStartDate)}
                                                        format="dd-MM-yyyy"
                                                        helperText={errors.educationStartDate}
                                                        id="educationStartDate"
                                                        label="Data rozpoczęcia edukacji przedszkolnej (dd-mm-rrrr)*"
                                                        value={values.educationStartDate || null}
                                                        onChange={val => {
                                                            setFieldValue("educationStartDate", val);
                                                        }}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'zmień datę',
                                                        }}
                                                        autoOk
                                                        cancelLabel="Anuluj"
                                                        inputVariant="outlined"
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl margin="dense" fullWidth>
                                                    <Autocomplete
                                                        label={"Placówka *"}
                                                        field={'school'}
                                                        source={Source.SCHOOLS}
                                                        value={values.school}
                                                        error={false}
                                                        setFieldValue={setFieldValue}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl margin="dense" fullWidth>
                                                    <Autocomplete
                                                        label={"Kategoria *"}
                                                        field={'category'}
                                                        source={Source.STUDENTS_CATEGORY}
                                                        value={values.category}
                                                        error={false}
                                                        setFieldValue={setFieldValue}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl margin="dense" fullWidth>
                                                    <Autocomplete
                                                        label={"Typ niepełnosprawności"}
                                                        field={"disabilities"}
                                                        requestOptions={{"type": Dictionaries.DisabilityType}}
                                                        source={Source.DICTIONARIES}
                                                        value={values.disabilities}
                                                        error={false}
                                                        setFieldValue={setFieldValue}
                                                        multiple
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl margin="dense" fullWidth>
                                                    <TextField
                                                        error={Boolean(errors.comorbidities && touched.comorbidities)}
                                                        label="Choroby współistniejące"
                                                        id="comorbidities"
                                                        name="comorbidities"
                                                        variant="outlined"
                                                        inputProps={{
                                                            maxLength: 255,
                                                        }}
                                                        multiline
                                                        value={values.comorbidities || ''}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        helperText={errors.comorbidities}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl margin="dense" fullWidth>
                                                    <Autocomplete
                                                        label={"Grupa"}
                                                        field={'groups'}
                                                        source={Source.GROUPS}
                                                        value={values.groups}
                                                        error={false}
                                                        setFieldValue={setFieldValue}
                                                        multiple
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl margin="dense" fullWidth>
                                                    <Autocomplete
                                                        label={"Status *"}
                                                        field={'status'}
                                                        source={Source.EDUCATION_STATUS}
                                                        value={values.status}
                                                        error={false}
                                                        setFieldValue={setFieldValue}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControlLabel
                                                    control={<Checkbox id="hasPharmacotherapy" checked={values.hasPharmacotherapy}
                                                                       onChange={handleChange}
                                                                       color="primary"/>}
                                                    label="Stosuje farmakoterapię"
                                                />
                                            </Grid>

                                            {values.hasPharmacotherapy ?
                                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                                    <FormControl margin="dense" fullWidth>
                                                        <TextField
                                                            error={Boolean(errors.pharmacotherapyDescription && touched.pharmacotherapyDescription)}
                                                            label="Opis farmakoterapi"
                                                            id="pharmacotherapyDescription"
                                                            name="pharmacotherapyDescription"
                                                            variant="outlined"
                                                            inputProps={{
                                                                maxLength: 255,
                                                            }}
                                                            multiline
                                                            value={values.pharmacotherapyDescription || ''}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            helperText={errors.pharmacotherapyDescription}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                : ''
                                            }

                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControlLabel
                                                    control={<Checkbox id="hasDiet" checked={values.hasDiet}
                                                                       onChange={handleChange}
                                                                       color="primary"/>}
                                                    label="Stosuje specjalną dietę"
                                                />
                                            </Grid>

                                            {values.hasDiet ?
                                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                                    <FormControl margin="dense" fullWidth>
                                                        <TextField
                                                            error={Boolean(errors.dietDescription && touched.dietDescription)}
                                                            label="Opis diety"
                                                            id="dietDescription"
                                                            name="dietDescription"
                                                            variant="outlined"
                                                            inputProps={{
                                                                maxLength: 255,
                                                            }}
                                                            multiline
                                                            value={values.dietDescription || ''}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            helperText={errors.dietDescription}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                : ''
                                            }

                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControlLabel
                                                    control={<Checkbox id="hasSiblings" checked={values.hasSiblings}
                                                                       onChange={handleChange}
                                                                       color="primary"/>}
                                                    label="Posiada rodzeństwo"
                                                />
                                            </Grid>

                                            {values.hasSiblings ?
                                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                                    <FormControl margin="dense" fullWidth>
                                                        <Autocomplete
                                                            label={"Typ niepełnosprawności rodzenństwa"}
                                                            field={"siblingsDisabilities"}
                                                            requestOptions={{"type": Dictionaries.DisabilityType}}
                                                            source={Source.DICTIONARIES}
                                                            value={values.siblingsDisabilities}
                                                            error={false}
                                                            setFieldValue={setFieldValue}
                                                            multiple
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                : ''
                                            }

                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleSubmit()}
                                                    disabled={this.props.isProcessing}
                                                >
                                                    Zapisz
                                                </Button>
                                            </Grid>
                                        </Grid>

                                    </CardContent>
                                </Card>
                            </form>
                        )}
                    </Formik>
            }
        </>
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    isLoading: isLoading(state),
    isProcessing: isProcessing(state),
})

export const Form = connect(mapStateToProps)(FormComponent)