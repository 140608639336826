import {crudReducer, CrudState, initialCrudState} from 'lib/crud'
import {AnyAction} from 'redux'

export const studentSummaryName = 'STUDENT_SUMMARY'

export type StudentSummaryState = {
    crud: CrudState,
}

const initialState = {
    crud: initialCrudState,
}

export function studentSummaryReducer(state: StudentSummaryState = initialState, action: AnyAction): StudentSummaryState {
    switch (action.type) {
        default:
            return {
                ...state,
                crud: crudReducer(studentSummaryName, state.crud, action)
            }
    }
}
