import {ActionsUnion, createAction} from 'external-lib/redux-utils'

export const StudentActionType = {
    evaluate: '[STUDENT] Evaluation start',
    evaluateFailure: '[STUDENT] Evaluation failure',
    evaluateSuccess: '[STUDENT] Evaluation success',

    analyseStep1: '[CRUD] Student analysis filter',
    analyseStep1Failure: '[CRUD] Student analysis step1 failure',
    analyseStep1Success: '[CRUD] Student analysis step1 success',

    analyseStep2: '[CRUD] Student analysis step2',
    analyseStep2Failure: '[CRUD] Student analysis step2 failure',
    analyseStep2Success: '[CRUD] Student analysis step2 success',
}

export const StudentAction = {
    evaluate: <R>(request: R) => createAction(StudentActionType.evaluate, request),
    evaluateFailure: (error: any) => createAction(StudentActionType.evaluateFailure, error),
    evaluateSuccess: (response: any) => createAction(StudentActionType.evaluateSuccess, response),

    analyseStep1: <R>(request: R) => createAction(StudentActionType.analyseStep1, request),
    analyseStep1Failure: (error: any) => createAction(StudentActionType.analyseStep1Failure, error),
    analyseStep1Success: (response: any) => createAction(StudentActionType.analyseStep1Success, response),

    analyseStep2: <R>(request: R) => createAction(StudentActionType.analyseStep2, request),
    analyseStep2Failure: (error: any) => createAction(StudentActionType.analyseStep2Failure, error),
    analyseStep2Success: (response: any) => createAction(StudentActionType.analyseStep2Success, response),
}

export type StudentAction = ActionsUnion<typeof StudentAction>