import {CrudList, FilterType, FormattedDate, Status, UserPreview} from 'app/Components'
import {Tabs} from 'app/Views/Students/Tabs/Tabs'
import {CrudAction, getRow, Type} from 'lib/crud'
import {State} from 'lib/store'
import {name as studentsName, Student} from 'lib/students'
import * as React from 'react'
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux'
import {withRouter} from 'react-router'
import {RouteComponentProps} from 'react-router-dom'
import {Test, testName} from 'lib/test'
import {TestConfigPreview} from "app/Components/Preview/TestConfigPreview";
import {Source} from "lib/select";

type StateToProps = {
    readonly student: Student
}

type DispatchToProps = {
    readonly load: (actionName: string, id: string) => void,
}

type Params = {
    parentId: string
}

const cols = [
    {
        id: "test",
        numeric: false,
        label: "Test",
        sort: false,
        template: (test: Test) => <TestConfigPreview id={test.config} />
    },
    {
        id: "publishedAt",
        numeric: false,
        label: "Data wykonania",
        sort: false,
        width: '20%',
        template: (test: Test) => <FormattedDate date={test.publishedAt} />
    },
    {
        id: "teacher",
        numeric: false,
        label: "Specjalista/Psycholog",
        sort: false,
        width: '20%',
        template: (test: Test) => <UserPreview id={test.teacher} />
    },
    {
        id: "published",
        numeric: false,
        label: "Ukończony",
        sort: false,
        width: '100px',
        template: (test: Test) => <Status status={test.published} activeLabel={'Tak'} inactiveLabel={'Nie'}/>
    },
]

class TestsComponent extends React.Component<StateToProps & DispatchToProps & RouteComponentProps<Params>> {
    public componentDidMount(): void {
        if (this.props.match.params.parentId) {
            this.props.load(studentsName, this.props.match.params.parentId)
        }
    }

    public render = () => <>
        {this.props.student.id !== undefined ? <CrudList
            actionName={testName}
            cols={cols}
            deleteDialogText={"Usunąć test?"}
            selector={(state: State) => state.tests}
            filter={{
                initialValues: {
                    student: this.props.match.params.parentId,
                },
                fields: [
                    {name: 'publishedAtAfter', label: 'Od (dd-mm-rrrr)', type: FilterType.DATE},
                    {name: 'publishedAtBefore', label: 'Do (dd-mm-rrrr)', type: FilterType.DATE},
                    {name: 'config', label: 'Test', type: FilterType.MULTIPLE_SELECT, source: Source.TEST_CONFIG},
                    {name: 'published', label: 'Ukończony', type: FilterType.SELECT, source: Source.STATUS, requestOptions: {type: Source.TEST_PUBLISHED}}
                ]
            }}
            tabs={<Tabs id={this.props.student.id || ' '} />}
        /> : null}
    </>
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    student: getRow(state => state.students)(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    load: (actionName: string, id: string) => {
        dispatch(CrudAction.execute(actionName, Type.GET, {id}))
    },
})

export const List = withRouter(connect(mapStateToProps, mapDispatchToProps)(TestsComponent))