import {CrudList, Enum, SubjectPreview, UserPreview} from 'app/Components'
import {Tabs} from 'app/Views/Groups'
import {rules} from 'lib/auth'
import {name as classDurationName} from 'lib/classDuration'
import {CrudAction, emptyFilterId, getRow, Type} from 'lib/crud'
import {GroupClass, name as groupClassesName} from 'lib/groupClass'
import {Group, name as groupsName} from 'lib/groups'
import {State} from 'lib/store'
import * as React from 'react'
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux'
import {RouteComponentProps, withRouter} from 'react-router-dom'

const cols = [
    {
        id: "subject",
        numeric: false,
        label: "Zajęcia",
        sort: false,
        template: (groupClass: GroupClass) => <SubjectPreview id={groupClass.subject}/>
    },
    {
        id: "teacher",
        numeric: false,
        label: "Specjalista",
        sort: false,
        width: '20%',
        template: (groupClass: GroupClass) => <UserPreview id={groupClass.teacher}/>
    },
    {
        id: "duration",
        numeric: true,
        label: "Czas trwania",
        sort: false,
        width: '20%',
        template: (groupClass: GroupClass) => <Enum name={classDurationName} id={groupClass.duration}/>
    },
    {
        id: "weeklySessions",
        numeric: true,
        label: "Ilość w tygodniu",
        sort: false,
        width: '20%',
        template: (groupClass: GroupClass) => groupClass.weeklySessions
    }
]

type StateToProps = {
    readonly group: Group
}

type DispatchToProps = {
    readonly load: (actionName: string, id: string) => void,
}

type Params = {
    parentId: string
}

export class ListComponent extends React.Component<StateToProps & DispatchToProps & RouteComponentProps<Params>> {
    public componentDidMount(): void {
        if (this.props.match.params.parentId) {
            this.props.load(groupsName, this.props.match.params.parentId)
        }
    }

    public render = () => <>
        {this.props.group.id !== undefined ? <CrudList
            actionName={groupClassesName}
            parentActionName={groupsName}
            cols={cols}
            selector={(state: State) => state.groupClass}
            deleteDialogText="Usunąć zajęcia?"
            rules={rules.groupClasses}
            filter={{
                initialValues: {id: this.props.group.id !== undefined && this.props.group.classes.length > 0
                        ? this.props.group.classes
                        : [emptyFilterId]},
                fields: []
            }}
            tabs={<Tabs id={this.props.match.params.parentId} />}
        /> : null}
    </>
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    group: getRow(state => state.groups)(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    load: (actionName: string, id: string) => {
        dispatch(CrudAction.execute(actionName, Type.GET, {id}))
    },
})

export const List = withRouter(connect(mapStateToProps, mapDispatchToProps)(ListComponent))