import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import * as React from 'react'
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux'
import {State} from 'lib/store'
import {DialogAction, getId} from 'lib/dialog'

type StateToProps = {
    openedId: string
}

type DispatchToProps = {
    close: () => void
}

type SimpleDialogProps = {
    id: string,
    title: string,
    text: string,
    handleConfirm: () => void,
    confirmLabel?: string
} & StateToProps & DispatchToProps

class SimpleDialogComponent extends React.Component<SimpleDialogProps> {
    public render = () => <Dialog open={this.props.id === this.props.openedId} onClose={this.props.close}>
            <DialogTitle>{this.props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{this.props.text}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.props.close} color="primary" variant="outlined">
                    Anuluj
                </Button>
                <Button onClick={() => {
                    this.props.handleConfirm()
                    this.props.close()
                }} color="primary" variant="contained" autoFocus>
                    {this.props.confirmLabel || 'Potwierdzam'}
                </Button>
            </DialogActions>
        </Dialog>
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    openedId: getId(state)
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    close: () => {
        dispatch(DialogAction.close())
    }
})

export const SimpleDialog = connect(mapStateToProps, mapDispatchToProps)(SimpleDialogComponent)