import {Box, Card, CardContent, Table, TableBody, TableCell, TableContainer, TableRow} from '@material-ui/core'
import {CircularProgressBar, CrudShowHeader, Status, UsersPreview} from 'app/Components'
import {isGranted, me, rules} from 'lib/auth'
import {getRow} from 'lib/crud'
import {isLoading} from 'lib/progress'
import {name, School} from 'lib/schools'
import {State} from 'lib/store'
import {User} from 'lib/users'
import * as React from 'react'
import {connect, MapStateToProps} from 'react-redux'

type StateToProps = {
    readonly isLoading: boolean
    readonly school: School
    readonly me: User
}

type ShowProps = StateToProps

class ShowComponent extends React.Component<ShowProps> {
    public render = () => (<>
        <CrudShowHeader actionName={name}/>

        {this.props.isLoading
            ? <CircularProgressBar/>
            : <Card variant="outlined">
                <CardContent>
                    <TableContainer>
                        <Table>
                            <TableBody className={'gray-striped'}>
                                <TableRow>
                                    <TableCell component="td" style={{width: '20%'}}>Identyfikator</TableCell>
                                    <TableCell component="td">
                                        {this.props.school.identifier}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="td" style={{width: '20%'}}>Nazwa</TableCell>
                                    <TableCell component="td">
                                        {this.props.school.name}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="td" style={{width: '20%'}}>Dyrektorzy</TableCell>
                                    <TableCell component="td">
                                        <UsersPreview ids={this.props.school.principals} />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="td">Adres</TableCell>
                                    <TableCell component="td">
                                        <Box>{this.props.school.street} {this.props.school.houseNumber}{this.props.school.flatNumber !== '' ? '/' + this.props.school.flatNumber : ''}</Box>
                                        <Box>{this.props.school.zipCode} {this.props.school.city}</Box>
                                    </TableCell>
                                </TableRow>
                                {this.props.school.phone !== '' ? <TableRow>
                                    <TableCell component="td">Telefon</TableCell>
                                    <TableCell component="td">
                                        {this.props.school.phone}
                                    </TableCell>
                                </TableRow> : ''}
                                {isGranted(rules.school.status, this.props.me) ? <TableRow>
                                    <TableCell component="td">Status</TableCell>
                                    <TableCell component="td">
                                        <Status status={this.props.school.active} activeLabel={'Aktywna'} inactiveLabel={'Nieaktywna'}/>
                                    </TableCell>
                                </TableRow> : null}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
        }
    </>)
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    isLoading: isLoading(state),
    school: getRow(state => state.schools)(state),
    me: me(state)!
})

export const Show = connect(mapStateToProps)(ShowComponent)