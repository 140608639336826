import {Box, Card, CardContent, Table, TableBody, TableCell, TableContainer, TableRow} from '@material-ui/core'
import {
    CircularProgressBar,
    CrudShowHeader, FormattedDate,
    QualificationsPreview,
    RolesEnum,
    SchoolPreview,
    SpecialtiesPreview,
    Status
} from 'app/Components'
import {isGranted, me, rules} from 'lib/auth'
import {getRow} from 'lib/crud'
import {isLoading} from 'lib/progress'
import {State} from 'lib/store'
import {name, User} from 'lib/users'
import * as React from 'react'
import {connect, MapStateToProps} from 'react-redux'

type StateToProps = {
    readonly isLoading: boolean
    readonly user: User
    readonly me: User
}

type ShowProps = StateToProps

class ShowComponent extends React.Component<ShowProps> {
    public render = () => (<>
        <CrudShowHeader actionName={name}/>

        {this.props.isLoading
            ? <CircularProgressBar/>
            : <Card variant="outlined">
                <CardContent>
                    <TableContainer>
                        <Table>
                            <TableBody className={'gray-striped'}>
                                <TableRow>
                                    <TableCell component="td" style={{width: '20%'}}>Identyfikator</TableCell>
                                    <TableCell component="td">
                                        {this.props.user.identifier}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="td" style={{width: '20%'}}>Nazwisko i imię</TableCell>
                                    <TableCell component="td">
                                        {this.props.user.lastName} {this.props.user.firstName}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="td" style={{width: '20%'}}>Kontakt</TableCell>
                                    <TableCell component="td">
                                        <Box>e-mail: {this.props.user.email}</Box>
                                        {this.props.user.phone ? <Box>tel: {this.props.user.phone}</Box> : null}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="td" style={{width: '20%'}}>Data rozpoczęcia</TableCell>
                                    <TableCell component="td">
                                        {new Date(this.props.user.worksSince).toLocaleDateString()}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="td">Placówka</TableCell>
                                    <TableCell component="td">
                                        <SchoolPreview id={this.props.user.school} />
                                    </TableCell>
                                </TableRow>
                                {(this.props.user.specialties || []).length > 0 ? <TableRow>
                                    <TableCell component="td">Specjalizacje</TableCell>
                                    <TableCell component="td">
                                        <SpecialtiesPreview ids={this.props.user.specialties}/>
                                    </TableCell>
                                </TableRow> : null}

                                {(this.props.user.qualifications || []).length > 0 ? <TableRow>
                                    <TableCell component="td">Uprawnienia</TableCell>
                                    <TableCell component="td">
                                        <QualificationsPreview ids={this.props.user.qualifications} />
                                    </TableCell>
                                </TableRow> : null}

                                <TableRow>
                                    <TableCell component="td">Rola</TableCell>
                                    <TableCell component="td">
                                        <RolesEnum ids={this.props.user.roles || []} />
                                    </TableCell>
                                </TableRow>

                                {isGranted(rules.user.lastSeenAt, this.props.me) ? <TableRow>
                                    <TableCell component="td" style={{width: '20%'}}>Data ostatniego logowania</TableCell>
                                    <TableCell component="td">
                                        {this.props.user.lastSeenAt !== null ? <FormattedDate date={this.props.user.lastSeenAt} /> : 'Brak ostatniego logowania'}
                                    </TableCell>
                                </TableRow> : null}

                                {isGranted(rules.user.status, this.props.me) ? <TableRow>
                                    <TableCell component="td">Status</TableCell>
                                    <TableCell component="td">
                                        <Status status={this.props.user.active} activeLabel={'Aktywny'} inactiveLabel={'Nieaktywny'}/>
                                    </TableCell>
                                </TableRow> : null}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
        }
    </>)
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    isLoading: isLoading(state),
    user: getRow(state => state.users)(state),
    me: me(state)!
})

export const Show = connect(mapStateToProps)(ShowComponent)