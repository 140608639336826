import {
    Box,
    Checkbox,
    FormControlLabel, Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField
} from '@material-ui/core'
import {Label} from "@material-ui/icons";
import * as React from 'react'
import {LFWTestSection} from "../LFWSection";

type Q2Props = {
    section: LFWTestSection
    preview: boolean
}

type Q2State = {
    section: LFWTestSection
}


export class Q2 extends React.Component<Q2Props, Q2State> {
    state: Q2State = {
        section: this.props.section
    }

    public handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.state.section.response[event.target.name] = event.target.checked

        if (event.target.name == 'nie') {
            this.state.section.response['lewe oko'] = false
            this.state.section.response['prawe oko'] = false
        }

        if (event.target.name == 'lewe oko') {
            this.state.section.response['nie'] = false
            this.state.section.response['prawe oko'] = false
        }

        if (event.target.name == 'prawe oko') {
            this.state.section.response['nie'] = false
            this.state.section.response['lewe oko'] = false
        }

        this.setState(prevState => {
            return {...prevState, section: this.state.section}
        })
    }

    public handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.state.section.response[event.target.name] = event.target.value;
        this.setState(prevState => {
            return {...prevState, section: this.state.section}
        })
    }

    public render = () => {
        return <TableContainer>
            <Table className={'gray-striped'}>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={'nie'}
                                        checked={this.state.section.response.nie}
                                        onChange={this.handleChange}
                                        disabled={this.props.preview}
                                        color="primary"
                                    />
                                }
                                label={'nie'}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={'lewe oko'}
                                        checked={this.state.section.response['lewe oko']}
                                        onChange={this.handleChange}
                                        disabled={this.props.preview}
                                        color="primary"
                                    />
                                }
                                label={'tak, oko lewe'}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={'prawe oko'}
                                        checked={this.state.section.response['prawe oko']}
                                        onChange={this.handleChange}
                                        disabled={this.props.preview}
                                        color="primary"
                                    />
                                }
                                label={'tak, oko prawe'}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Grid container direction="row"
                                  justify="flex-start"
                                  alignItems="center" spacing={3}>
                                <Grid item>
                                    przez
                                </Grid>
                                <Grid item>
                                    <TextField
                                        name="ile godzin w ciągu dnia?"
                                        variant="outlined"
                                        type="number"
                                        inputProps={{
                                            maxLength: 50,
                                            min: 0,
                                            max: 24
                                        }}
                                        value={this.state.section.response['ile godzin w ciągu dnia?']}
                                        onChange={this.handleTextChange}
                                        disabled={this.props.preview}
                                    />
                                </Grid>
                                <Grid item>
                                    godzin w ciągu dnia
                                </Grid>
                            </Grid>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    }
}