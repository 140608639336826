import {crudReducer, CrudState, initialCrudState} from 'lib/crud'
import {AnyAction} from 'redux'

export const name = 'CHILDREN'

export type GroupChildrenState = {
    crud: CrudState,
}

const initialState = {
    crud: initialCrudState,
}

export function groupChildrenReducer(state: GroupChildrenState = initialState, action: AnyAction): GroupChildrenState {
    switch (action.type) {
        default:
            return {
                ...state,
                crud: crudReducer(name, state.crud, action)
            }
    }
}
