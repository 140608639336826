import {Box, Card as MuiCard, CardContent as MuiCardContent, Typography} from '@material-ui/core'
import {spacing} from '@material-ui/system'
import {CircularProgressBar, FilterType} from 'app/Components'
import {isLoading} from 'lib/progress'
import {getOptions, SelectAction, Source} from 'lib/select'
import {State} from 'lib/store'
import * as React from 'react'
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux'
import {withRouter} from 'react-router'
import {RouteComponentProps} from 'react-router-dom'
import styled from 'styled-components'
import {getAnalyseFilter, getStudentEvaluations, getStudentToGroupComparison} from 'lib/students/selector'
import {TestAction} from 'lib/test'
import {studentAndGroupTable, studentChart, studentTable} from './ClaMEISR'
import {Filter} from './Filter'

type StateToProps = {
    readonly isLoading: boolean
    readonly evaluations: any,
    readonly comparison: any,
    readonly getAnalyseFilter: any,
    readonly students: any
    readonly groups: any
}

type DispatchToProps = {
    readonly resetAnalysis: () => void,
    readonly loadGroups: () => void,
}

type Params = {
    id?: string
    config?: string
    from?: string
    to?: string
}

const Card = styled(MuiCard)(spacing)

const CardContent = styled(MuiCardContent)(spacing)

class StudentsComponent extends React.Component<StateToProps & DispatchToProps & RouteComponentProps<Params>> {
    public componentDidMount = () => {
        this.props.resetAnalysis()
        this.props.loadGroups()
    }

    public render = () => <>
        {this.props.isLoading
            ? <CircularProgressBar/>
            : <Card mt={3} variant="outlined">
                <CardContent>
                    <Filter
                        actionName={'Student analysis'}
                        selector={(state: State) => state.students}
                        initialValues={{
                            // id: 'aca7d4d0-bf4b-42fb-92eb-1711a82749d9',
                            // config: '425e5e83-bdd2-447b-b2f9-4c767b1089ed',
                            // from: '2021-01-01T00:00:00.000Z',
                            // to: '2021-04-16T00:00:00.000Z'
                        }}
                        fields={[
                            {name: 'id', label: 'Dziecko', type: FilterType.SELECT, source: Source.STUDENTS},
                            {name: 'config', label: 'Test', type: FilterType.SELECT, source: Source.TEST_FOR_ANALYSIS},
                            {name: 'from', label: 'Od (dd-mm-rrrr)', type: FilterType.DATE},
                            {name: 'to', label: 'Do (dd-mm-rrrr)', type: FilterType.DATE}
                        ]}
                    />
                    {this.props.evaluations.length > 0 && this.props.students && this.props.comparison && this.props.comparison.students && this.props.comparison.students.length > 0
                        ? <>
                            <Typography variant={'h5'}>1. Analiza dziecka w czasie</Typography>
                            <Box mt={6} mb={6}>
                                {studentChart(this.props.evaluations)}
                                {studentTable(this.props.evaluations, this.props.getAnalyseFilter.config == '425e5e83-bdd2-447b-b2f9-4c767b1089ed')}
                            </Box>

                            <Typography variant={'h5'}>2. Porównanie dziecka na tle grupy</Typography>
                            <Box mt={6} mb={6}>
                                {studentAndGroupTable(this.props.comparison.students, this.props.comparison.groups, this.props.groups, this.props.getAnalyseFilter.config == '425e5e83-bdd2-447b-b2f9-4c767b1089ed')}
                            </Box>
                        </>
                        : <Box mt={10} mb={6}><Typography variant={'h5'} align={'center'}>Wybierz parametry do
                            analizy</Typography></Box>
                    }
                </CardContent>
            </Card>}
    </>
}

const mapStateToProps: MapStateToProps<StateToProps, {}, State> = state => ({
    isLoading: isLoading(state),
    evaluations: getStudentEvaluations(state),
    comparison: getStudentToGroupComparison(state),
    students: getOptions('student')(state),
    groups: getOptions('group')(state),
    getAnalyseFilter: getAnalyseFilter(state)
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, {}> = dispatch => ({
    resetAnalysis: () => {
        dispatch(TestAction.analyseReset({}))
    },
    loadGroups: () => {
        dispatch(SelectAction.add('group')),
        dispatch(SelectAction.loadOptions('group', Source.GROUPS, ''))
    },
})

export const Students = withRouter(connect(mapStateToProps, mapDispatchToProps)(StudentsComponent))