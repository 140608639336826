import * as React from 'react'
import {connect} from 'react-redux'
import {Source} from "lib/select";
import {Event} from "lib/events";
import {Preview} from "app/Components/Preview/Preview";

type EventPreviewProps = {
    id: string,
}

class EventPreviewComponent extends React.Component<EventPreviewProps> {
    public render = () =>
        <Preview
            value={this.props.id}
            source={Source.EVENTS}
            template={(event: Event) => (
                <>{event.name}</>
            )}
        />
}

export const EventPreview = connect()(EventPreviewComponent)