import {configuration} from 'lib/client'
import {GroupClassApi, IndividualClassApi} from 'client/apis'

const IndividualClassApiClient = (): IndividualClassApi => {
    return new IndividualClassApi(configuration)
}

export const dao = {
    list: (payload: any) => {
        return IndividualClassApiClient().getIndividualClassCollection(payload)
    },
    get: (payload: any) => {
        return IndividualClassApiClient().getIndividualClassItem({id: payload.id})
    },
    create: (payload: any) => {
        return IndividualClassApiClient().postIndividualClassCollection({individualClassWrite: payload})
    },
    update: (payload: any) => {
        return IndividualClassApiClient().putIndividualClassItem({id: payload.id, individualClassWrite: payload.data})
    },
    delete: (payload: any) => {
        return IndividualClassApiClient().deleteIndividualClassItem({id: payload.id})
    }
}