import Link from '@material-ui/core/Link'
import {RouteType} from 'lib/crud'
import {generateCrudPath} from 'lib/router'
import {name, User} from 'lib/users'
import * as React from 'react'
import {NavLink} from 'react-router-dom'
import {isGranted, Role} from "lib/auth";

type ParentLabelProps = {
    user: User
}

function displayName(props: ParentLabelProps) {
    return <>{props.user.lastName} {props.user.firstName}</>;
}

export class ParentLabel extends React.Component<ParentLabelProps> {
    public render = () => (<>
        {isGranted([Role.ROLE_PARENT], this.props.user)
            ? displayName(this.props)
            : <Link component={NavLink} to={generateCrudPath(name, RouteType.SHOW, {id: this.props.user.id})}>{displayName(this.props)}</Link>
        }
    </>)
}