import {ActionsUnion, createAction} from 'external-lib/redux-utils'

export const DialogActionType = {
    open: (): string => `[DATA] Set dialog open`,
    close: (): string => `[DATA] Set dialog close`,
}

export const DialogAction = {
    open: (id: string) => createAction(DialogActionType.open(), id),
    close: () => createAction(DialogActionType.close()),
}

export type DialogAction = ActionsUnion<typeof DialogAction>