import {
    CrudAction, dispatchAfterFailureEpic, dispatchAfterFilterEpic,
    dispatchAfterSuccessEpic,
    executeCrudEpic,
    redirectAfterSuccessEpic,
    RouteType,
    Type
} from 'lib/crud'
import {NotificationsAction} from 'lib/notifications'
import {generateCrudPath} from 'lib/router'
import {State} from 'lib/store'
import {dao, name} from 'lib/groups'
import {combineEpics} from 'redux-observable'
import {NavigationAction} from "../navigation";

export const groupsEpics = combineEpics(
    executeCrudEpic(name, Type.LIST)(dao.list),
    executeCrudEpic(name, Type.GET)(dao.get),
    executeCrudEpic(name, Type.CREATE)(dao.create),
    executeCrudEpic(name, Type.UPDATE)(dao.update),
    executeCrudEpic(name, Type.DELETE)(dao.delete),

    redirectAfterSuccessEpic(name, Type.CREATE, (action, state: State) => generateCrudPath(name, RouteType.LIST)),
    redirectAfterSuccessEpic(name, Type.UPDATE, (action, state: State) => generateCrudPath(name, RouteType.LIST)),

    dispatchAfterSuccessEpic(name, Type.GET, (action, state: State) => NavigationAction.setSubheader(state.groups.crud.row.name)),

    dispatchAfterSuccessEpic(name, Type.CREATE, (action, state: State) => NotificationsAction.show({
        message: 'Dodano nową grupę',
        options: {variant: "success"}
    })),

    dispatchAfterFailureEpic(name, Type.CREATE, (action, state: State) => NotificationsAction.show({
        message: 'Wystąpił błąd podczas dodawania nowej grupy',
        options: {variant: "error"}
    })),

    dispatchAfterSuccessEpic(name, Type.UPDATE, (action, state: State) => NotificationsAction.show({
        message: 'Grupa została zaktualizowana',
        options: {variant: "success"}
    })),

    dispatchAfterFailureEpic(name, Type.UPDATE, (action, state: State) => NotificationsAction.show({
        message: 'Wystąpił błąd podczas aktualizowania grupy',
        options: {variant: "error"}
    })),

    dispatchAfterSuccessEpic(name, Type.DELETE, (action, state: State) => NotificationsAction.show({
        message: 'Grupa została usunięte',
        options: {variant: "success"}
    })),

    dispatchAfterFailureEpic(name, Type.DELETE, (action, state: State) => NotificationsAction.show({
        message: 'Wystąpił błąd podczas usuwania grupy',
        options: {variant: "error"}
    })),

    dispatchAfterSuccessEpic(name, Type.DELETE, (action, state: State) => CrudAction.execute(name, Type.LIST, {
        orderName: state.groups.crud.listOptions.sortDirection,
        page: state.groups.crud.listOptions.page + 1,
        itemsPerPage: state.groups.crud.listOptions.pageSize,
        ...state.groups.crud.filters,
    })),

    dispatchAfterFilterEpic(name, (action, state: State) => CrudAction.execute(name, Type.LIST, {
        orderName: state.groups.crud.listOptions.sortDirection,
        page: state.groups.crud.listOptions.page + 1,
        itemsPerPage: state.groups.crud.listOptions.pageSize,
        ...state.groups.crud.filters,
    }))
)