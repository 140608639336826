import {AnyAction} from 'redux'
import {NotificationsActionType, Message} from 'lib/notifications'

export type NotificationsState = {
    open: boolean
    message: Message
}

const initialState = {
    open: false,
    message: {message: ''}
}

export function notificationsReducer(state: NotificationsState = initialState, action: AnyAction): NotificationsState {
    switch (action.type) {
        case NotificationsActionType.Show:
            return {
                ...state,
                open: true,
                message: action.payload
            }
        case NotificationsActionType.Hide:
            return {
                ...state,
                open: false,
                message: {message: ''}
            }
        default:
            return state
    }
}
