import {
    Checkbox,
    FormControlLabel,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField
} from '@material-ui/core'
import * as React from 'react'
import {LFWTestSection} from "../LFWSection";

type QYesNoExampleHardToDefineProps = {
    section: LFWTestSection
    preview: boolean
}

type QYesNoExampleHardToDefineState = {
    section: LFWTestSection
}


export class QYesNoExampleHardToDefine extends React.Component<QYesNoExampleHardToDefineProps, QYesNoExampleHardToDefineState> {
    state: QYesNoExampleHardToDefineState = {
        section: this.props.section
    }

    public handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (this.state.section.response.hasOwnProperty(event.target.name)) {
            this.state.section.response[event.target.name] = event.target.checked
        }

        if (event.target.name == 'nie') {
            this.state.section.response['tak'] = false
            this.state.section.response['trudno określić'] = false
        }

        if (event.target.name == 'tak') {
            this.state.section.response['nie'] = false
            this.state.section.response['trudno określić'] = false
        }

        if (event.target.name == 'trudno określić') {
            this.state.section.response['nie'] = false
            this.state.section.response['tak'] = false
        }

        this.setState(prevState => {
            return {...prevState, section: this.state.section}
        })
    }

    public handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.state.section.response[event.target.name] = event.target.value;
        this.setState(prevState => {
            return {...prevState, section: this.state.section}
        })
    }

    public render = () => {
        return <TableContainer>
            <Table className={'gray-striped'}>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={'tak'}
                                        checked={this.state.section.response['tak']}
                                        onChange={this.handleChange}
                                        disabled={this.props.preview}
                                        color="primary"
                                    />
                                }
                                label={'tak'}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Grid container direction="row"
                                  justify="flex-start"
                                  alignItems="center" spacing={3}>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name={'nie'}
                                                checked={this.state.section.response['nie']}
                                                onChange={this.handleChange}
                                                disabled={this.props.preview}
                                                color="primary"
                                            />
                                        }
                                        label={'nie'}
                                    />
                                </Grid>
                                <Grid item sm>
                                    <TextField
                                        name="description"
                                        variant="outlined"
                                        inputProps={{
                                            maxLength: 250,
                                        }}
                                        value={this.state.section.response['description']}
                                        onChange={this.handleTextChange}
                                        multiline
                                        fullWidth
                                        disabled={this.props.preview}
                                        placeholder={'Np.'}
                                    />
                                </Grid>
                            </Grid>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={'trudno określić'}
                                        checked={this.state.section.response['trudno określić']}
                                        onChange={this.handleChange}
                                        disabled={this.props.preview}
                                        color="primary"
                                    />
                                }
                                label={'trudno określić'}
                            />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    }
}