import {push} from 'connected-react-router'
import {
    AuthAction,
    AuthActionType,
    AuthApiClient,
    PasswordResetClient,
    refreshTokenInterval,
    UserApiClient
} from 'lib/auth'
import {NotificationsAction} from 'lib/notifications'
import {ProtectedRoutes, Routes} from 'lib/router'
import {AnyAction} from 'redux'
import {combineEpics, Epic, ofType} from 'redux-observable'
import {interval, of} from 'rxjs'
import {catchError, map, switchMap, takeUntil, tap, withLatestFrom} from 'rxjs/operators'
import {User} from 'lib/users'

const initializeAppEpic: Epic<AuthAction> = action$ => action$.pipe(
    ofType(AuthActionType.Initialize),
    switchMap(() => AuthApiClient().refreshTokens().pipe(
        map(response => AuthAction.initializeSuccess(response)),
        catchError(error => of(AuthAction.initializeFailure(error))),
    ))
)

const loginEpic: Epic<AnyAction> = action$ => action$.pipe(
    ofType(AuthActionType.Login),
    switchMap(({payload}) => AuthApiClient().login(payload).pipe(
        map(response => AuthAction.loginSuccess(response)),
        catchError(error => of(AuthAction.loginFailure(error))),
    ))
)

const redirectAfterLoginEpic: Epic<AnyAction> = (action$, state$) => action$.pipe(
    ofType(AuthActionType.LoginSuccess),
    withLatestFrom(state$),
    map(([action, state]) => {
        return push(state.auth.redirectPath)
    }),
)

const logoutEpic: Epic<AuthAction> = action$ => action$.pipe(
    ofType(AuthActionType.Logout),
    switchMap(() => AuthApiClient().logout().pipe(
        map(() => AuthAction.logoutSuccess()),
        catchError(error => of(AuthAction.loginFailure(error))),
    ))
)

const startRefreshTokenAfterLoginEpic: Epic<AnyAction> = action$ => action$.pipe(
    ofType(AuthActionType.LoginSuccess),
    map(AuthAction.initialize),
)

const startRefreshTokenAfterInitEpic: Epic<AnyAction> = action$ => action$.pipe(
    ofType(AuthActionType.InitializeSuccess),
    map(AuthAction.refreshToken),
)

const redirectAfterLogoutEpic: Epic<AnyAction> = action$ => action$.pipe(
    ofType(AuthActionType.LogoutSuccess),
    map(() => push(Routes.AuthSignIn)),
)

const refreshTokenEpic: Epic<AuthAction> = action$ => action$.pipe(
    ofType(AuthActionType.RefreshToken),
    switchMap(() => AuthApiClient().refreshTokens().pipe(
        map((response) => AuthAction.refreshTokenSuccess(response)),
        catchError(error => of(AuthAction.refreshTokenFailure(error))),
    ))
)

const periodicallyRefreshTokenEpic: Epic<AuthAction> = action$ => action$.pipe(
    ofType(AuthActionType.RefreshTokenSuccess),
    switchMap(() => interval(refreshTokenInterval).pipe(
        map(AuthAction.refreshToken),
        takeUntil(
            action$.pipe(
                ofType(AuthActionType.Logout, AuthActionType.RefreshTokenFailure),
            )),
    )),
)

const passwordResetInitializationEpic: Epic<AnyAction> = action$ => action$.pipe(
    ofType(AuthActionType.PasswordResetInitialize),
    switchMap(({payload}) => PasswordResetClient().passwordResetInitiate(payload).pipe(
        map(response => AuthAction.passwordResetInitializationSuccess(response)),
        catchError(error => of(AuthAction.passwordResetInitializationFailure(error))),
    ))
)

const redirectAfterPasswordResetInitializeSuccessEpic: Epic<AnyAction> = action$ => action$.pipe(
    ofType(AuthActionType.PasswordResetInitializeSuccess),
    map( () => push(Routes.AuthSignIn)),
)

const dispatchMessageAfterPasswordResetInitializeSuccessEpic: Epic<AnyAction> = action$ => action$.pipe(
    ofType(AuthActionType.PasswordResetInitializeSuccess),
    map( () => NotificationsAction.show({
        message: 'Pomyślnie wysłano wiadomość email z linkiem do przypomienia hasła.',
        options: {variant: "success"},
    })),
)

const dispatchMessageAfterPasswordResetInitializeFailureEpic: Epic<AnyAction> = action$ => action$.pipe(
    ofType(AuthActionType.PasswordResetInitializeFailure),
    map( () => NotificationsAction.show({
        message: 'Wystąpił błąd. Spróbuj ponownie później.',
        options: {variant: "error"},
    })),
)

const passwordTokenValidationEpic: Epic<AnyAction> = action$ => action$.pipe(
    ofType(AuthActionType.PasswordTokenValidation),
    switchMap(({payload}) => PasswordResetClient().passwordResetValidate(payload).pipe(
        map(response => AuthAction.passwordResetValidationSuccess(response)),
        catchError(error => of(AuthAction.passwordResetValidationFailure(error))),
    ))
)

const redirectAfterPasswordTokenValidationFailureEpic: Epic<AnyAction> = action$ => action$.pipe(
    ofType(AuthActionType.PasswordTokenValidationFailure),
    map( () => push(Routes.AuthSignIn)),
)

const passwordResetChangeEpic: Epic<AnyAction> = action$ => action$.pipe(
    ofType(AuthActionType.PasswordChange),
    switchMap(({payload}) => PasswordResetClient().passwordResetChange(payload).pipe(
        map(response => AuthAction.passwordResetChangeSuccess(response)),
        catchError(error => of(AuthAction.passwordResetChangeFailure(error))),
    ))
)

const dispatchMessageAfterPasswordChangeFailureEpic: Epic<AnyAction> = action$ => action$.pipe(
    ofType(AuthActionType.PasswordChangeFailure),
    map( () => NotificationsAction.show({
        message: 'Wystąpił błąd. Spróbuj ponownie później.',
        options: {variant: "error"},
    })),
)

const redirectAfterPasswordChangeSuccessEpic: Epic<AnyAction> = action$ => action$.pipe(
    ofType(AuthActionType.PasswordChangeSuccess),
    map( () => push(Routes.AuthSignIn)),
)

const dispatchMessageAfterPasswordChangeSuccessEpic: Epic<AnyAction> = action$ => action$.pipe(
    ofType(AuthActionType.PasswordChangeSuccess),
    map( () => NotificationsAction.show({
        message: 'Hasło zostało zmienione. Zaloguj się z użyciem nowego hasła.',
        options: {variant: "success"},
    })),
)

const getMeAfterInitAppEpic: Epic<AnyAction> = action$ => action$.pipe(
    ofType(AuthActionType.InitializeSuccess),
    switchMap(({payload}) => AuthApiClient().me().pipe(
        map(response => AuthAction.meSuccess(response)),
        catchError(error => of(AuthAction.meFailure(error))),
    ))
)

const getUserAfterInitAppEpic: Epic<AnyAction> = action$ => action$.pipe(
    ofType(AuthActionType.MeSuccess),
    switchMap(({payload}) => UserApiClient().getUserItem(payload).pipe(
        map(response => AuthAction.userInitializeSuccess(response as User)),
        catchError(error => of(AuthAction.userInitializeFailure(error))),
    ))
)

export const authEpics = combineEpics(
    initializeAppEpic,
    loginEpic,
    redirectAfterLoginEpic,
    logoutEpic,
    redirectAfterLogoutEpic,
    startRefreshTokenAfterLoginEpic,
    startRefreshTokenAfterInitEpic,
    refreshTokenEpic,
    periodicallyRefreshTokenEpic,
    passwordResetInitializationEpic,
    passwordTokenValidationEpic,
    passwordResetChangeEpic,
    redirectAfterPasswordResetInitializeSuccessEpic,
    dispatchMessageAfterPasswordResetInitializeSuccessEpic,
    dispatchMessageAfterPasswordResetInitializeFailureEpic,
    redirectAfterPasswordTokenValidationFailureEpic,
    dispatchMessageAfterPasswordChangeFailureEpic,
    redirectAfterPasswordChangeSuccessEpic,
    dispatchMessageAfterPasswordChangeSuccessEpic,
    getMeAfterInitAppEpic,
    getUserAfterInitAppEpic
)