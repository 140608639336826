import {Role} from 'lib/auth'
import {crudReducer, CrudState, initialCrudState} from 'lib/crud'
import {AnyAction} from 'redux'

export const name = 'SPECIALISTS'

export type SpecialistsState = {
    crud: CrudState,
}

const initialState = {
    crud: {
        ...initialCrudState,
        filters: {
            roles: [Role.ROLE_SPECIALIST],
        }
    },
}

export function specialistsReducer(state: SpecialistsState = initialState, action: AnyAction): SpecialistsState {
    switch (action.type) {
        default:
            return {
                ...state,
                crud: crudReducer(name, state.crud, action)
            }
    }
}
