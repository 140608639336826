import {Box, Checkbox, Table, TableBody, TableCell, TableHead, TableRow, TextField} from "@material-ui/core";
import * as React from 'react'

type SectionProps = {
    index: number
    section: ISPTestSection
    preview?: boolean
    prev: null | number
    next: null | number
}

type SectionState = {
    section: ISPTestSection
}

export type ISPTestSection = {
    age: string,
    comments: string,
    required: boolean,
    result: number,
    trial: {
        list?: string[],
        title: string
    }
}

export class ISPSection extends React.Component<SectionProps, SectionState> {
    state: SectionState = {
        section: this.props.section
    }

    public handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.state.section.result = event.target.checked ? parseInt(event.target.name) : 0;
        this.setState(prevState => {
            return {...prevState, section: this.state.section}
        })
    }

    public handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.state.section.comments = event.target.value
        this.setState(prevState => {
            return {...prevState, section: this.state.section}
        })
    }

    public render = () => <Box style={this.props.preview ? {margin: '0px 0px 50px 0px'} : {}}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell/>
                    <TableCell align={"center"} style={{width: '20%'}}>Nie wykonuje</TableCell>
                    <TableCell align={"center"} style={{width: '20%'}}>Wykonuje z pomocą</TableCell>
                    <TableCell align={"center"} style={{width: '20%'}}>Wykonuje samodzielnie</TableCell>
                    <TableCell style={{width: '90px'}}>Wiek</TableCell>
                </TableRow>
            </TableHead>
            <TableBody className={'gray-striped'}>
                <TableRow>
                    <TableCell>
                        {(this.props.index + 1) + '. ' + this.state.section.trial.title}
                        <ul>
                        {this.state.section.trial.list
                            ? (this.state.section.trial.list.map((option, index) => <li key={index}>{option}</li>))
                            : null
                        }
                        </ul>
                    </TableCell>
                    <TableCell align={"center"}>
                        <Checkbox
                            name={'1'}
                            checked={this.state.section.result == 1}
                            onChange={this.handleChange}
                            disabled={this.props.preview || false}
                            color="primary"
                        />
                    </TableCell>
                    <TableCell align={"center"}>
                        <Checkbox
                            name={'2'}
                            checked={this.state.section.result == 2}
                            onChange={this.handleChange}
                            disabled={this.props.preview || false}
                            color="primary"
                        />
                    </TableCell>
                    <TableCell align={"center"}>
                        <Checkbox
                            name={'3'}
                            checked={this.state.section.result == 3}
                            onChange={this.handleChange}
                            disabled={this.props.preview || false}
                            color="primary"
                        />
                    </TableCell>
                    <TableCell>
                        {this.state.section.age}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={5}>
                        <TextField label={'Uwagi'} name={"comments"} disabled={this.props.preview || false}
                                   value={this.state.section.comments} onChange={this.handleTextChange} multiline
                                   variant="outlined" style={{width: '100%'}}/>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    </Box>
}