import {CrudAction, dispatchAfterFilterEpic, executeCrudEpic, Type} from 'lib/crud'
import {name} from 'lib/studentSubject'
import {State} from 'lib/store'
import {dao} from 'lib/users'
import {combineEpics} from 'redux-observable'

export const studentsubjectsEpics = combineEpics(
    executeCrudEpic(name, Type.LIST)(dao.list),

    dispatchAfterFilterEpic(name, (action, state: State) => CrudAction.execute(name, Type.LIST, {
        orderName: state.studentSubjects.crud.listOptions.sortDirection,
        page: state.studentSubjects.crud.listOptions.page + 1,
        itemsPerPage: state.studentSubjects.crud.listOptions.pageSize,
        ...state.studentSubjects.crud.filters
    }))
)