import {ActionsUnion, createAction} from 'external-lib/redux-utils'
import {Message} from 'lib/notifications'

export enum NotificationsActionType {
    Show = '[UI] Show notification',
    Hide = '[UI] Hide notification',
}

export const NotificationsAction = {
    show: (message: Message) => createAction(NotificationsActionType.Show, message),
    hide: () => createAction(NotificationsActionType.Hide, null),
}

export type NotificationsAction = ActionsUnion<typeof NotificationsAction>
