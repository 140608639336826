import {SpecialtyApi} from 'client/apis'
import {configuration} from 'lib/client'

const SpecialtyApiClient = (): SpecialtyApi => {
    return new SpecialtyApi(configuration)
}

export const dao = {
    list: (payload: any) => SpecialtyApiClient().getSpecialtyCollection(payload),
    get: (payload: any) => SpecialtyApiClient().getSpecialtyItem({id: payload.id}),
    create: (payload: any) => SpecialtyApiClient().postSpecialtyCollection({specialtyWrite: payload}),
    update: (payload: any) => SpecialtyApiClient().putSpecialtyItem({id: payload.data.id, specialtyWrite: payload.data}),
    delete: (payload: any) => SpecialtyApiClient().deleteSpecialtyItem({id: payload.id})
}